import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import * as api from "../../../services/ApiService";
import * as Yup from "yup";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as toast from "../../../utils/toastUtils";
import Modal from "react-bootstrap/Modal";
import { Tooltip } from "react-tooltip";
import useConfirm from "../../../hooks/useConfirm";

function AddCandidateStep5() {
  let navigate = useNavigate();

  // Get data from token util
  let user = tokenUtils.getTokenData();
  let userID = localStorage.getItem("employee_id");

  const [skills, setSkills] = useState([]);
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-skills");
        setSkills(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);

    if (!modalOpen) {
      setformData({
        id: null,
        user_id: userID,
        token: user.token,
        skill_id: null,
        skill: null,
        experience: null,
        experience_type: null,
      });

      // Reset validation errors
      setErrors({});
    }
  };

  const [formData, setformData] = useState({
    id: null,
    user_id: userID,
    token: user.token,
    skill_id: null,
    skill: null,
    experience: null,
    experience_type: null,
  });

  const [errors, setErrors] = useState({});

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setformData({
      user_id: userID,
      token: user.token,
      id: record.id,
      skill_id: record.skill_id,
      skill: record.skill,
      experience: record.experience,
      experience_type: record.experience_type,
    });

    setModalOpen(true);
  };

  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      record.user_id = userID;
      record.token = user.token;

      var result = await api.postMethod(record, "user/delete-skill");
      if (result) {
        fetchSkillData();
      }
    }
  };

  const validationSchema = Yup.object({
    skill_id: Yup.string().required("Skill is Required"),
    experience: Yup.string().required("Experience is Required"),
    experience_type: Yup.string().required("Experience Type is Required"),
  });

  const [skillInfo, setSkill] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchSkillData = async () => {
    try {
      const param = {
        user_id: userID,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "user/get-skills");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);

      /*
      const apiDataObj = apiData.reduce((acc, currentValue, index) => {
        acc[index] = currentValue;
        return acc;
      }, {});
      */

      setSkill(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSkillData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });
      const result = await api.postMethod(formData, "user/update-skills");
      if (result) {
        setModalOpen(false);
        fetchSkillData();
      }
    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const calculateProgress = (experienceType, experience) => {
    let progress = 0;
    switch (experienceType) {
      case "year(s)":
        progress = experience / 0.5; // Assuming 1 year = 10 units of progress
        break;
      case "month(s)":
        progress = experience / 0.75; // Assuming 1 month = 1/12 units of progress
        break;
      case "hour(s)":
        progress = experience / 10; // Assuming 1 hour = 1/100 units of progress
        break;
      default:
        progress = 0;
    }
    return progress;
  };

  const isSkillDisabled = (skillId) => {
    // Ensure skillInfo is an array before calling some
    if (!Array.isArray(skillInfo)) {
      return false;
    }
    // Check if any object in skillInfo has a matching skill_id
    return skillInfo.some((skill) => skill.skill_id === skillId);
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/client/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item">
                          <Link to="/client/employees">Employees</Link>
                        </li>
                        <li className="breadcrumb-item active">Add Employee</li>
                      </ol>
                    </div>
                    <h4 className="page-title">Add Employee</h4>
                  </div>
                </div>
              </div>
              {/*Job progress bar start*/}
              <div className="row">
                <div className="col-12">
                  <div className="stepper-wrapper">
                    <div className="stepper-item completed">
                      <div className="step-counter">1</div>
                      <div className="step-name">Personal Information</div>
                    </div>
                    <div className="stepper-item completed">
                      <div className="step-counter">2</div>
                      <div className="step-name">Job Preferences</div>
                    </div>
                    {/* 
                    <div className="stepper-item">
                      <div className="step-counter">3</div>
                      <div className="step-name">Professional Details</div>
                    </div>
                    */}
                    <div className="stepper-item completed">
                      <div className="step-counter">3</div>
                      <div className="step-name">Work History</div>
                    </div>
                    <div className="stepper-item completed">
                      <div className="step-counter">4</div>
                      <div className="step-name">Educational Informations</div>
                    </div>

                    <div className="stepper-item active">
                      <div className="step-counter">5</div>
                      <div className="step-name">Skills</div>
                    </div>
                  </div>
                </div>
              </div>
              {/*Job progress bar end*/}
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <div className="mb-2 row">
                        <div className="col-md-11">
                          <h5>
                            Top Skills
                            <span className="float-end">Experience</span>
                          </h5>
                        </div>
                      </div>

                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <>
                          {noRecordsFound ? (
                            <p>No records found</p>
                          ) : (
                            <>
                              {Object.entries(skillInfo).map(([key, value]) => (
                                <div className="row" key={value.id}>
                                  <div className="col-md-11">
                                    <p>
                                      {value.skill_name !== null
                                        ? value.skill_name
                                        : value.user_skill}
                                      <span className="float-end">
                                        {value.experience} {value.experience_type}
                                      </span>
                                    </p>
                                    <div className="progress mb-2 progress-sm">
                                      <div
                                        className="progress-bar bg-warning"
                                        role="progressbar"
                                        style={{
                                          width: `${calculateProgress(
                                            value.experience_type,
                                            value.experience
                                          )}%`,
                                        }}
                                        aria-valuenow={calculateProgress(
                                          value.experience_type,
                                          value.experience
                                        )}
                                        aria-valuemin={0}
                                        aria-valuemax={100}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-1">
                                    <span
                                      className="action-icon font-20 p-1 text-muted"
                                      onClick={() => handleEdit(value)}
                                    >
                                      <i className="mdi mdi-pencil" />
                                    </span>
                                    <span
                                      className="action-icon font-20 p-1 text-muted"
                                      onClick={() => handleDelete(value)}
                                    >
                                      <i className="mdi mdi-delete" />
                                    </span>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </>
                      )}

                      <div className="">
                        <Link
                          to={`/client/add-employee-step4`}
                          className="btn btn-secondary mt-2 me-1"
                          type="submit"
                        >
                          <i className="fas fa-angle-left" /> Go Back
                        </Link>

                        <button
                          type="button"
                          className="btn btn-info waves-effect waves-light mt-2"
                          onClick={toggleModal}
                        >
                          <i className="fa fa-plus" /> Add Skill
                        </button>

                        <Link to="/client/employees" className="btn btn-primary mt-2 float-end">
                          Submit <i className="fas fa-angle-right" />
                        </Link>
                      </div>

                      <Modal
                        show={modalOpen}
                        onHide={toggleModal}
                        centered
                        size="lg"
                        backdrop="static"
                      >
                        <form onSubmit={handleSubmit}>
                          <Modal.Header closeButton>
                            <Modal.Title className="my-0">Edit Skills</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <div className="row" key="">
                              <div className="col-md-6 ">
                                <label htmlFor="skill_id" className="form-label">
                                  Skill
                                </label>
                                <select
                                  className="form-control"
                                  id="skill_id"
                                  name="skill_id"
                                  value={formData.skill_id}
                                  onChange={handleChange}
                                >
                                  <option value="" key="">
                                    Please Select
                                  </option>

                                  {/* Render the skills */}
                                  {skills.map((skill) => (
                                    <option
                                      key={skill.id}
                                      value={skill.id}
                                      disabled={isSkillDisabled(skill.id)}
                                    >
                                      {skill.skill}
                                    </option>
                                  ))}
                                </select>
                                {errors.skill_id && (
                                  <span className="error">{errors.skill_id}</span>
                                )}
                              </div>
                              <div className="col-md-3 ">
                                <label htmlFor="experience" className="form-label">
                                  Experience
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  id="experience"
                                  name="experience"
                                  defaultValue={formData.experience}
                                  onChange={handleChange}
                                />
                                {errors.experience && (
                                  <span className="error">{errors.experience}</span>
                                )}
                              </div>
                              <div className="col-md-3 ">
                                <label htmlFor="experience_type" className="form-label">
                                  Experience Type
                                </label>
                                <select
                                  className="form-control"
                                  name="experience_type"
                                  id="experience_type"
                                  value={formData.experience_type}
                                  onChange={handleChange}
                                >
                                  <option value="" key="">
                                    Please Select
                                  </option>
                                  <option value="year(s)" key="year(s)">
                                    Year(s)
                                  </option>
                                  <option value="month(s)" key="month(s)">
                                    Month(s)
                                  </option>
                                  <option value="hour(s)" key="hour(s)">
                                    Hour(s)
                                  </option>
                                </select>
                                {errors.experience_type && (
                                  <span className="error">{errors.experience_type}</span>
                                )}
                              </div>
                            </div>
                          </Modal.Body>
                          <Modal.Footer>
                            <button
                              type="button"
                              className="btn btn-secondary"
                              onClick={toggleModal}
                            >
                              Close
                            </button>
                            <button type="submit" className="btn btn-primary">
                              Save
                            </button>
                          </Modal.Footer>
                        </form>
                      </Modal>
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ThemeSettings />
    </>
  );
}

export default AddCandidateStep5;
