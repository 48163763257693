import React from 'react';
import { DateRange } from 'react-date-range/';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

const DateRangePickerComponent = ({ startDate, endDate, onDateChange }) => {
    const selectionRange = {
        startDate: startDate,
        endDate: endDate,
        key: 'selection',
    };

    const handleSelect = (ranges) => {
        console.log(ranges)
        onDateChange(ranges.selection);
    };

    const handleDateInputFocus = (inputType) => {
        console.log(inputType)
        if (inputType === 'endDate') {
            alert()
        }
    };

    return (
        <div>
            <DateRange
                ranges={[selectionRange]}
                onChange={handleSelect}
                editableDateInputs={true}
                moveRangeOnFirstSelection={true}
                onInputFocus={(input) => handleDateInputFocus(input)}
            />
        </div>
    );
};

export default DateRangePickerComponent;
