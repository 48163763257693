import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import * as auth from "../../../services/AuthService";
import PasswordToggle from "../../../hooks/PasswordToggle";
import Carousel from "react-bootstrap/Carousel";

import Logo from "./Logo";

function Login() {
  let navigate = useNavigate();

  // Form button state
  const [formBtn, setformBtn] = useState({
    label: "Login",
    disabled: false,
  });

  // Use useState hook to initialize FormData variables with empty values
  const [formData, setformData] = useState({
    email: "",
    password: "",
  });

  // Password toggle state
  const [showPassword, togglePasswordVisibility] = PasswordToggle(formData, setformData);

  // Define Error state
  const [errors, setErrors] = useState({});

  // Define the validation schema
  let validationSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  // Form submit function
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // without abortEarly, it will return back on 1st field's validation occured, won't check other fields
      await validationSchema.validate(formData, { abortEarly: false });

      // Disable the btn
      setformBtn({
        label: "Please wait...",
        disabled: true,
      });

      // Call function with formData and API endpoint
      let res = await auth.login(formData, "user/login");

      // Reset the btn
      setformBtn({
        label: "Login",
        disabled: false,
      });

      if (res === 1 || res === 2) {
        let redirectTo = res === 1 ? "dashboard" : "email-verification";

        setTimeout(() => {
          navigate("/" + redirectTo);
        }, 500);
      }
    } catch (error) {
      const newErrors = {};

      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }

      setErrors(newErrors);
    }
  };

  // OnChange event on each form field, because form fields are disabled
  const handleChange = (e) => {
    //name attritube & value from the field
    const { name, value } = e.target;

    // Update the formData state
    setformData({
      ...formData,
      [name]: value,
    });

    // Validate the changed field and update errors state
    validateField(name, value);
  };

  // Function to validate form validate
  const validateField = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });
      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      // Set the error message for the current field if validation fails
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  document.body.classList.add("auth-fluid-pages");

  return (
    <div className="auth-fluid">
      <div className="container">
        <div className="row justify-content-center w-100 align-items-center h-100">
          <div className="bg-white col-md-8 col-lg-6 col-xl-4 py-3 rounded shadow-lg">
            <div className="auth-fluid-form-box position-relative h-100 p-0">
              <div className="align-items-center d-flex h-100">
                <div className="p-3">
                  {/* Logo */}
                  <Logo />

                  {/* title*/}
                  <h4 className="mt-0">Sign In</h4>
                  <p className="mb-4">Enter your email address and password to access account.</p>

                  <form onSubmit={handleSubmit}>
                    <div className="mb-3">
                      <label htmlFor="emailaddress" className="form-label">
                        Email address
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        id="emailaddress"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {/* Validation message */}
                      {errors.email && <span className="error">{errors.email}</span>}
                    </div>
                    <div className="mb-3">
                      <Link to="/forgot-password" className="float-end">
                        <small>Forgot your password?</small>
                      </Link>
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <div className="input-group input-group-merge">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          className="form-control"
                          placeholder="Enter your password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                        <div
                          className={
                            showPassword ? "show-password input-group-append" : "input-group-append"
                          }
                          data-password="false"
                        >
                          <div className="input-group-text">
                            <span className="password-eye" onClick={togglePasswordVisibility} />
                          </div>
                        </div>
                      </div>
                      {/* Validation message */}
                      {errors.password && <span className="error">{errors.password}</span>}
                    </div>

                    <div className="text-center d-grid">
                      <button
                        className="btn btn-info btn-block"
                        type="submit"
                        disabled={formBtn.disabled}
                      >
                        {formBtn.label}
                      </button>

                      <p className="mt-2">
                        Login as&nbsp;
                        <Link className="ml-1" to="/client/login">
                          <b>Agency/Facility</b>
                        </Link>
                      </p>
                    </div>
                  </form>
                  {/* end form*/}
                  {/* Footer*/}
                  <footer className="footer footer-alt position-relative">
                    <p className="mb-0">
                      Don't have an account?&nbsp;
                      <Link className="ml-1" to="/register">
                        <b>Sign Up</b>
                      </Link>
                    </p>
                  </footer>
                </div>
                {/* end .card-body */}
              </div>
              {/* end .align-items-center.d-flex.h-100*/}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
