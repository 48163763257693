import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Layout from "../../Layout/Index";
import PageTitle from "../../Layout/PageTitle";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import { formatDate } from "../../../utils/dateFormat";
import { Tooltip } from "react-tooltip";
import { toast } from "react-toastify";
import Spinner from "../../Loader/Spinner";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const CreateTaskModal = ({ uploadAddOpen, toggleAddModal, editData }) => {
  let user = tokenUtils.getTokenData();
  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    title: null,
    due_date: null,
    assignedUserIDs: [],
    description: "",
    attatchmentIds: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [attachments, setAttachments] = useState({});
  const [attachmentLoading, setAttachmentLoading] = useState(false);
  const [noRecordsFoundAttachment, setNoRecordsFoundAttachment] = useState(false);
  const [attatchmentData, setAttatchmentData] = useState({
    id: null,
    user_id: user.id,
    token: user.token,
    file_name: null,
  });

  useEffect(() => {
    if (uploadAddOpen) {
      if (editData) {
        fetchSchedulingAttachments(editData.id);
        // Populate formData with the values of the selected record
        setformData(editData);
        setAttatchmentData({ ...attatchmentData, id: editData.id });
      }
      fetchAssignedUsers();
    }
  }, [uploadAddOpen]);

  useEffect(() => {
    return () => {
      // Cleanup editor instance when component is unmounted
      if (ClassicEditor.instances) {
        ClassicEditor.instances.forEach((instance) => instance.destroy());
      }
    };
  }, []);
  const handleFileChange = (e) => {
    setAttatchmentData((prevState) => ({
      ...prevState,
      file_name: e.target.files[0], // Update image field with the selected file
    }));
    uploadAttatchment();
  };

  const fetchSchedulingAttachments = async (curTaskID) => {
    setAttachmentLoading(true);
    try {
      const param = {
        user_id: user.id,
        taskId: curTaskID,
      };
      const result = await api.postMethod(param, "admin/get-task-attatchments");
      setAttachmentLoading(false);
      setNoRecordsFoundAttachment(true);
      if (result.data.length) {
        setNoRecordsFoundAttachment(false);
        setAttachments(result.data);
      } else {
        setAttachments({});
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setAttachmentLoading(false);
    }
  };

  const uploadAttatchment = async () => {
    try {
      const result = await api.postFileMethod(attatchmentData, "admin/upload-task-attatchment");
      if (result.status) {
        toast.success(result.message);
        setformData((prevState) => ({
          ...prevState,
          attatchmentIds: formData.attatchmentIds
            ? [...formData.attatchmentIds, result.attachmentId]
            : [result.attachmentId],
        }));
        document.getElementById("file_name").value = "";
        if (formData.id != null) {
          fetchSchedulingAttachments(formData.id);
        }
      }
    } catch (error) {
      console.error("Unexpected error structure:", error);
    }
  };

  const [assignedUsers, setAssignedUsers] = useState([]);
  const fetchAssignedUsers = async () => {
    try {
      const apiData = await api.getMethod("get-users/6");
      setAssignedUsers(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  // Define the validation schema
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is Required"),
    due_date: Yup.string().required("Due Date is Required"),
  });

  const [errors, setErrors] = useState({});

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });
      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setIsLoading(true);
      const result = await api.postFileMethod(formData, "admin/update-task");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        document.getElementById("file_name").value = "";
        closeModal();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setformData((prevState) => ({
      ...prevState,
      description: data,
    }));
    validateFields("description", data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
    validateFields(name, value);
  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    setformData((prevState) => {
      const assignedUserIDs = prevState.assignedUserIDs ? [...prevState.assignedUserIDs] : [];
      if (checked) {
        if (!assignedUserIDs.includes(Number(value))) {
          assignedUserIDs.push(Number(value));
        }
      } else {
        const index = assignedUserIDs.indexOf(Number(value));
        if (index > -1) {
          assignedUserIDs.splice(index, 1);
        }
      }
      return { ...prevState, assignedUserIDs };
    });
  };

  const closeModal = () => {
    setformData({
      id: null,
      user_id: user.id,
      title: null,
      due_date: null,
      assignedUserIDs: [],
      description: "",
      attatchmentIds: [],
    });
    setErrors({});
    setAttachments({});
    toggleAddModal();
  };

  return (
    <Modal show={uploadAddOpen} onHide={closeModal} centered size="lg" backdrop="static">
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Create Task</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-8">
              <div className="row">
                <div className="col-md-12">
                  <div className="mb-3">
                    <label className="form-label" htmlFor="title">
                      Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="title"
                      name="title"
                      onChange={handleChange}
                      defaultValue={formData.title}
                    />
                    {errors.title && <span className="error">{errors.title}</span>}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mb-2">
                    <label className="form-label" htmlFor="description">
                      Description
                    </label>
                    <CKEditor
                      editor={ClassicEditor}
                      onChange={handleEditorChange}
                      name="description"
                      id="description"
                      data={formData.description}
                    />
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="mb-2">
                    <label className="form-label" htmlFor="due_date">
                      Due Date
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      name="due_date"
                      id="due_date"
                      onChange={handleChange}
                      value={formData.due_date !== null ? formData.due_date : ""}
                    />
                    {errors.due_date && <span className="error">{errors.due_date}</span>}
                  </div>
                </div>

                <div className="col-md-12">
                  <label className="form-label" htmlFor="description">
                    Attachments
                  </label>
                  <div
                    className="dropzone dz-clickable p-0 custom-file-upload-container position-relative"
                    id="resume"
                  >
                    <div className="dz-message needsclick">
                      <i className="h3 text-muted dripicons-cloud-upload" />
                      <h4>Drop or Click to Upload New Resume</h4>
                    </div>

                    <input
                      type="file"
                      name="file_name"
                      id="file_name"
                      onChange={handleFileChange}
                      accept="*"
                    />
                  </div>
                  {/* Preview */}
                  <div className="dropzone-previews mt-3" id="file-previews" />

                  {attachmentLoading ? (
                    <p>Loading...</p>
                  ) : (
                    <>
                      {noRecordsFoundAttachment ? (
                        <p>No attatchment uploaded yet</p>
                      ) : (
                        <>
                          {Object.entries(attachments).map(([key, value]) => (
                            <div className="card mb-0 mt-2 shadow-none border" key={value.id}>
                              <div className="p-2">
                                <div className="row align-items-center">
                                  <div className="col-auto">
                                    <div className="avatar-sm">
                                      <span className="avatar-title badge-soft-primary text-primary rounded">
                                        {value.file_type}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="col ps-0">
                                    <span className="text-muted fw-bold">{value.title}</span>
                                    <p className="mb-0 font-12">
                                      {value.file_size} |{" "}
                                      {formatDate(value.created_at, "MMM DD, YYYY")}
                                      {", "}
                                      {formatDate(value.created_at, "hh:mm A")}
                                    </p>
                                  </div>
                                  <div className="col-auto">
                                    <a
                                      href={value.dir_path}
                                      download
                                      target="_blank"
                                      rel="noreferrer"
                                      className="btn btn-link font-16 text-muted"
                                      data-tooltip-id="mt-4"
                                      data-tooltip-content="Download Attatchment"
                                    >
                                      <i className="dripicons-download" />
                                    </a>
                                    <Tooltip id="mt-4"></Tooltip>
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-4">
              <div className="mb-2">
                <label className="form-label" htmlFor="assignedUserIDs">
                  Assign to
                </label>

                {assignedUsers.map((user) => (
                  <div className="form-check" key={user.id}>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      id={user.id}
                      onChange={handleCheckboxChange}
                      name="assignedUserIDs"
                      value={user.id}
                      checked={formData.assignedUserIDs.includes(user.id) ? true : false}
                    />
                    <label className="form-check-label" htmlFor={user.id}>
                      {user.name}
                    </label>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={closeModal}>
            Close
          </button>
          <button disabled={isLoading} type="submit" className="btn btn-primary">
            Submit
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default CreateTaskModal;
