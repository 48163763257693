import React, { useState, useEffect } from "react";
import * as api from "../../../../../services/ApiService";
import * as tokenUtils from "../../../../../utils/tokenUtils";
import { Modal } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import { formatDate } from "../../../../../utils/dateFormat";

function Messages({ profileInfo }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [messages, setMessages] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchApplicants = async () => {
    try {
      const param = { user_id: user.id, to_id: profileInfo.id, type: "email" };
      const apiData = await api.postMethod(param, "admin/get-messages");

      setMessages(apiData.data);
      setLoading(false);
      setNoRecordsFound(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchApplicants();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [selectedMessage, setSelectedMessage] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (message) => {
    setSelectedMessage(message);
    setShow(true);
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table table-centered table-nowrap mb-0">
          <thead className="table-light">
            <tr>
              <th>Sender</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {loading ? (
              <tr>
                <td colSpan="3">Loading...</td>
              </tr>
            ) : (
              <>
                {noRecordsFound ? (
                  <tr>
                    <td colSpan="3">No records found</td>
                  </tr>
                ) : (
                  <>
                    {Object.entries(messages).map(([key, value]) => (
                      <tr key={value.id}>
                        <td>{value.from_name ? value.from_name : "N/A"}</td>
                        <td className="form-checks">
                          {value.created_at && formatDate(value.created_at, "DD MMM YYYY")}{" "}
                          <small className="text-muted">
                            {value.created_at && formatDate(value.created_at, "hh:mm A")}
                          </small>
                        </td>
                        <td>
                          <button
                            type="button"
                            className="btn btn-default action-icon"
                            data-tooltip-id="mt-1"
                            data-tooltip-content="View Message"
                            onClick={() => handleShow(value)}
                          >
                            <i className="mdi mdi-eye" />
                          </button>
                          <Tooltip id="mt-1"></Tooltip>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>

      {selectedMessage && (
        <Modal show={show} onHide={handleClose} centered backdrop="static">
          <Modal.Header closeButton>
            <Modal.Title className="my-0 modal-title h4">Message Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6">
                <div className="mb-3">
                  <p className="mb-0">
                    <b>Sender</b>
                  </p>
                  <p className="text-muted">{selectedMessage.from_name}</p>
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-3">
                  <p className="mb-0">
                    <b>Date</b>
                  </p>
                  <p className="text-muted">
                    {formatDate(selectedMessage.created_at, "DD MMM YYYY")}{" "}
                    <small className="text-muted">
                      {formatDate(selectedMessage.created_at, "hh:mm A")}
                    </small>
                  </p>
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-0">
                  <p className="mb-0">
                    <b>Message</b>
                  </p>
                  <div
                    className="text-muted"
                    dangerouslySetInnerHTML={{ __html: selectedMessage.message }}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary waves-effect"
              onClick={() => setShow(false)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
}

export default Messages;
