import React from "react";
import Layout from "../../Layout/Index";
import PageTitle from "../../Layout/PageTitle";
const breadcrumbItems = [
  { text: "Home", link: "./dashboard.html" },
  { text: "Company Profile", link: null },
];

const CompanyProfile = () => {
  return (
    <Layout>
      <PageTitle title="Company Profile" breadcrumbItems={breadcrumbItems} />

      {/* end page title */}
      <div className="row">
        <div className="col-lg-12">
          <div className="card custom-box-shadow">
            <div className="card-body">
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex align-items-start mb-3">
                    <img
                      className="d-flex me-3 rounded-circle avatar-lg"
                      src="../assets/images/companies/airbnb.png"
                      alt="Generic placeholder image"
                    />
                    <div className="w-100">
                      <h4 className="mt-0 mb-1">
                        JNK Hospitals
                        <div className="text-warning mb-0 font-13 d-inline">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                      </h4>
                      <p className="text-muted">Registered Since: 21 Aug 2021</p>
                      <h4>
                        <label className="badge badge-outline-success badge-pill">Active</label>
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
              <ul className="nav nav-pills nav-fill navtab-bg" role="tablist">
                <li className="nav-item">
                  <a
                    href="#home"
                    data-bs-toggle="tab"
                    aria-expanded="false"
                    className="nav-link active font-16"
                  >
                    Company Basic Info
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#contDetails"
                    data-bs-toggle="tab"
                    aria-expanded="false"
                    className="nav-link font-16"
                  >
                    Contact Details &amp; Social Media
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="#reviews"
                    data-bs-toggle="tab"
                    aria-expanded="false"
                    className="nav-link font-16"
                  >
                    Reviews
                  </a>
                </li>
              </ul>
              <div className="tab-content">
                <div className="tab-pane show active" id="home">
                  <div className="row">
                    <div className="col-md-12">
                      <h4 className="font-14 text-dark">About JNK Hospitals</h4>
                      <p className="mb-3">
                        Lorem ipsum dolor sit amet consectetur adipisicing elit. Libero, quos
                        aliquid iure exercitationem ab est nihil vero facilis obcaecati, fugit hic
                        quod. Eos blanditiis iure debitis tempore, qui suscipit odio. Lorem ipsum
                        dolor sit amet consectetur adipisicing elit. Libero, quos aliquid iure
                        exercitationem ab est nihil vero facilis obcaecati, fugit hic quod. Eos
                        blanditiis iure debitis tempore, qui suscipit odio.
                      </p>
                    </div>
                    <div className="col-md-4">
                      <h4 className="font-14 text-dark mb-1">Primary Industry</h4>
                      <p className="mb-3">Healthcare</p>
                    </div>
                    <div className="col-md-4">
                      <h4 className="font-14 text-dark mb-1">Founded In</h4>
                      <p className="mb-3">2018</p>
                    </div>
                    <div className="col-md-4">
                      <h4 className="font-14 text-dark mb-1">Company Size</h4>
                      <p className="mb-0">50 - 500</p>
                    </div>
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mt-2"
                        data-bs-toggle="modal"
                        data-bs-target="#info-modal"
                      >
                        <i className="fas fa-edit" /> Edit Info
                      </button>
                      {/* Add Candidate Modal */}
                      <div
                        className="modal fade"
                        id="info-modal"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="myLargeModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h4 className="modal-title" id="myCenterModalLabel">
                                Basic Info
                              </h4>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="mb-3">
                                    <label htmlFor="field-7" className="control-label">
                                      About the Company
                                    </label>
                                    <textarea
                                      className="form-control"
                                      rows={7}
                                      id="field-7"
                                      placeholder="Write a summary of the company"
                                      defaultValue={""}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="mb-3">
                                    <label className="control-label">Primary Industry</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="field-2"
                                      placeholder
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="mb-3">
                                    <label className="control-label">Team Size</label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      id="field-1"
                                      placeholder="50-500"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="mb-0">
                                    <label className="control-label">Founding Year</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="field-2"
                                      placeholder
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary waves-effect"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn btn-success waves-effect waves-light"
                              >
                                Save <i className="fa fa-check" />
                              </button>
                            </div>
                          </div>
                          {/* /.modal-content */}
                        </div>
                        {/* /.modal-dialog */}
                      </div>
                      {/* /.modal */}
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="contDetails">
                  <div className="row">
                    <div className="col-md-4">
                      <h4 className="font-14 text-dark mb-1">Location</h4>
                      <p className="mb-3">New York, USA</p>
                    </div>
                    <div className="col-md-4">
                      <h4 className="font-14 text-dark mb-1">Email</h4>
                      <p className="mb-3">jnk_hospitals@gmail.com</p>
                    </div>
                    <div className="col-md-4">
                      <h4 className="font-14 text-dark mb-1">Phone Number</h4>
                      <p className="mb-0">613 983 0384</p>
                    </div>
                    <div className="col-md-4">
                      <h4 className="font-14 text-dark mb-1">Facebook</h4>
                      <p className="mb-0">
                        <a href="https://www.facebook.com/GoogleIndia" target="_blank">
                          https://www.facebook.com/GoogleIndia
                        </a>
                      </p>
                    </div>
                    <div className="col-md-4">
                      <h4 className="font-14 text-dark mb-1">Twitter</h4>
                      <p className="mb-0">
                        <a href="https://twitter.com/google" target="_blank">
                          https://twitter.com/google
                        </a>
                      </p>
                    </div>
                    <div className="col-md-4">
                      <h4 className="font-14 text-dark mb-1">Instagram</h4>
                      <p className="mb-0">
                        <a href="https://www.instagram.com/google" target="_blank">
                          https://www.instagram.com/google
                        </a>
                      </p>
                    </div>
                    <div className="col-md-12">
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light mt-4"
                        data-bs-toggle="modal"
                        data-bs-target="#contact-modal"
                      >
                        <i className="fas fa-edit" /> Edit Details
                      </button>
                      {/* Add Candidate Modal */}
                      <div
                        className="modal fade"
                        id="contact-modal"
                        tabIndex={-1}
                        role="dialog"
                        aria-labelledby="myLargeModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-centered">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h4 className="modal-title" id="myCenterModalLabel">
                                Contact Details
                              </h4>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-hidden="true"
                              />
                            </div>
                            <div className="modal-body">
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="mb-2">
                                    <label htmlFor="field-7" className="control-label">
                                      Address
                                    </label>
                                    <textarea
                                      className="form-control"
                                      rows={2}
                                      id="field-7"
                                      placeholder="Write full address"
                                      defaultValue={""}
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="mb-2">
                                    <label className="control-label">Email</label>
                                    <input
                                      type="email"
                                      className="form-control"
                                      id="field-1"
                                      placeholder
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="mb-2">
                                    <label className="control-label">Phone Number</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="field-2"
                                      placeholder
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="mb-2">
                                    <label className="control-label">Facebook Page Link</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="field-2"
                                      placeholder
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="mb-2">
                                    <label className="control-label">Twitter Profile Link</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="field-2"
                                      placeholder
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12">
                                  <div className="mb-0">
                                    <label className="control-label">Instagram Profile Link</label>
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="field-2"
                                      placeholder
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                className="btn btn-secondary waves-effect"
                                data-bs-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn btn-success waves-effect waves-light"
                              >
                                Save <i className="fa fa-check" />
                              </button>
                            </div>
                          </div>
                          {/* /.modal-content */}
                        </div>
                        {/* /.modal-dialog */}
                      </div>
                      {/* /.modal */}
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="reviews">
                  <div className="d-flex align-items-start mt-3 p-1">
                    <img
                      src="../assets/images/users/user-9.jpg"
                      className="me-2 rounded-circle"
                      height={36}
                      alt="Arya Stark"
                    />
                    <div className="w-100">
                      <h5 className="mt-0 mb-0 font-size-14">
                        <span className="float-end text-muted font-12">4:30am</span>
                        Arya Stark
                        <div className="text-warning mb-0 font-13 d-inline">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                      </h5>
                      <p className="mt-1 mb-0 text-muted">
                        Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque
                        ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus
                        viverra turpis. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed
                        augue semper porta. Mauris massa.
                      </p>
                      <a
                        href="javascript: void(0);"
                        className="text-muted font-13 d-inline-block mt-2"
                        data-bs-toggle="modal"
                        data-bs-target=".reply-modal"
                      >
                        <i className="mdi mdi-reply" /> Reply
                      </a>
                      <div className="d-flex align-items-start mt-3">
                        <a className="pe-2" href="#">
                          <img
                            src="../assets/images/users/user-4.jpg"
                            className="rounded-circle"
                            alt="Generic placeholder image"
                            height={32}
                          />
                        </a>
                        <div className="w-100">
                          <h5 className="mt-0">
                            Thelma Fridley
                            <small className="text-muted float-end">3 hours ago</small>
                          </h5>
                          Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque
                          ante sollicitudin.
                          <br />
                          <a
                            href="javascript: void(0);"
                            className="text-muted font-13 d-inline-block mt-2"
                            data-bs-toggle="modal"
                            data-bs-target=".edit-reply-modal"
                          >
                            <i className="mdi mdi-pencil" /> Edit
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex align-items-start mt-2 p-1">
                    <img
                      src="../assets/images/users/user-5.jpg"
                      className="me-2 rounded-circle"
                      height={36}
                      alt="Dominc B"
                    />
                    <div className="w-100">
                      <h5 className="mt-0 mb-0 font-size-14">
                        <span className="float-end text-muted font-12">3:30am</span>
                        Gary Somya
                        <div className="text-warning mb-0 font-13 d-inline">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                      </h5>
                      <p className="mt-1 mb-0 text-muted">
                        Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque
                        ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus
                        viverra turpis. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed
                        augue semper porta. Mauris massa.
                      </p>
                      <a
                        href="javascript: void(0);"
                        className="text-muted font-13 d-inline-block mt-2"
                        data-bs-toggle="modal"
                        data-bs-target=".reply-modal"
                      >
                        <i className="mdi mdi-reply" /> Reply
                      </a>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex align-items-start mt-3 p-1">
                    <img
                      src="../assets/images/users/user-6.jpg"
                      className="me-2 rounded-circle"
                      height={36}
                      alt="Arya Stark"
                    />
                    <div className="w-100">
                      <h5 className="mt-0 mb-0 font-size-14">
                        <span className="float-end text-muted font-12">4:30am</span>
                        Thelma Fridley
                        <div className="text-warning mb-0 font-13 d-inline">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                      </h5>
                      <p className="mt-1 mb-0 text-muted">
                        Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque
                        ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus
                        viverra turpis. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed
                        augue semper porta. Mauris massa.
                      </p>
                      <a
                        href="javascript: void(0);"
                        className="text-muted font-13 d-inline-block mt-2"
                        data-bs-toggle="modal"
                        data-bs-target=".reply-modal"
                      >
                        <i className="mdi mdi-reply" /> Reply
                      </a>
                    </div>
                  </div>
                  <hr />
                  <div className="d-flex align-items-start mt-2 p-1">
                    <img
                      src="../assets/images/users/user-2.jpg"
                      className="me-2 rounded-circle"
                      height={36}
                      alt="Dominc B"
                    />
                    <div className="w-100">
                      <h5 className="mt-0 mb-0 font-size-14">
                        <span className="float-end text-muted font-12">3:30am</span>
                        Jeremy Tomlinson
                        <div className="text-warning mb-0 font-13 d-inline">
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                          <i className="fa fa-star" />
                        </div>
                      </h5>
                      <p className="mt-1 mb-0 text-muted">
                        Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque
                        ante sollicitudin. Cras purus odio, vestibulum in vulputate at, tempus
                        viverra turpis. Duis sagittis ipsum. Praesent mauris. Fusce nec tellus sed
                        augue semper porta. Mauris massa.
                      </p>
                      <a
                        href="javascript: void(0);"
                        className="text-muted font-13 d-inline-block mt-2"
                        data-bs-toggle="modal"
                        data-bs-target=".reply-modal"
                      >
                        <i className="mdi mdi-reply" /> Reply
                      </a>
                      <div className="d-flex align-items-start mt-3">
                        <a className="pe-2" href="#">
                          <img
                            src="../assets/images/users/user-4.jpg"
                            className="rounded-circle"
                            alt="Generic placeholder image"
                            height={32}
                          />
                        </a>
                        <div className="w-100">
                          <h5 className="mt-0">
                            Thelma Fridley
                            <small className="text-muted float-end">3 hours ago</small>
                          </h5>
                          Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque
                          ante sollicitudin.
                          <br />
                          <a
                            href="javascript: void(0);"
                            className="text-muted font-13 d-inline-block mt-2"
                            data-bs-toggle="modal"
                            data-bs-target=".edit-reply-modal"
                          >
                            <i className="mdi mdi-pencil" /> Edit
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Reply Modal */}
              <div
                className="modal fade reply-modal"
                id="reply-modal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title" id="myCenterModalLabel">
                        Reply
                      </h4>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="modal-body">
                      <textarea
                        rows={5}
                        className="form-control resize-none mb-0"
                        placeholder="Your comment..."
                        defaultValue={""}
                      />
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary waves-effect"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="btn btn-success waves-effect waves-light">
                        Submit <i className="fa fa-check" />
                      </button>
                    </div>
                  </div>
                  {/* /.modal-content */}
                </div>
                {/* /.modal-dialog */}
              </div>
              {/* /.modal */}
              {/* Reply Modal */}
              <div
                className="modal fade edit-reply-modal"
                id="edit-reply-modal"
                tabIndex={-1}
                role="dialog"
                aria-labelledby="myLargeModalLabel"
                aria-hidden="true"
              >
                <div className="modal-dialog modal-dialog-centered">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h4 className="modal-title" id="myCenterModalLabel">
                        Edit Reply
                      </h4>
                      <button
                        type="button"
                        className="btn-close"
                        data-bs-dismiss="modal"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="modal-body">
                      <textarea
                        rows={5}
                        className="form-control resize-none mb-0"
                        placeholder="Your comment..."
                        defaultValue={
                          "Cras sit amet nibh libero, in gravida nulla. Nulla vel metus scelerisque ante sollicitudin."
                        }
                      />
                    </div>
                    <div className="modal-footer">
                      <button
                        type="button"
                        className="btn btn-secondary waves-effect"
                        data-bs-dismiss="modal"
                      >
                        Close
                      </button>
                      <button type="button" className="btn btn-success waves-effect waves-light">
                        Update <i className="fa fa-check" />
                      </button>
                    </div>
                  </div>
                  {/* /.modal-content */}
                </div>
                {/* /.modal-dialog */}
              </div>
              {/* /.modal */}
            </div>
            {/* end card-box*/}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default CompanyProfile;
