import { React, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import * as Yup from "yup";
import * as auth from "../../../services/AuthService";
import PasswordToggle from "../../../hooks/PasswordToggle";
import Logo from "./Logo";

function Login() {
  const location = useLocation();

  const isStaff = location.pathname.includes("/staff");

  let navigate = useNavigate();
  // Form button state
  const [formBtn, setformBtn] = useState({
    label: "Login",
    disabled: false,
  });

  // Use useState hook to initialize FormData variables with empty values
  const [formData, setformData] = useState({
    role_id: isStaff ? 6 : 3,
    email: "",
    password: "",
  });

  // Password toggle state
  const [showPassword, togglePasswordVisibility] = PasswordToggle(formData, setformData);

  // Define Error state
  const [errors, setErrors] = useState({});

  // Define the validation schema
  let validationSchema = Yup.object({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string().required("Password is required"),
  });

  // Form submit function
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // without abortEarly, it will return back on 1st field's validation occured, won't check other fields
      await validationSchema.validate(formData, { abortEarly: false });

      // Disable the btn
      setformBtn({
        label: "Please wait...",
        disabled: true,
      });

      // Call function with formData and API endpoint
      let res = await auth.login(formData, "client/login");

      // Reset the btn
      setformBtn({
        label: "Login",
        disabled: false,
      });

      if (res === 1 || res === 2) {
        let redirectTo = res === 1 ? "client/dashboard" : "client/email-verification";
        setTimeout(() => {
          navigate("/" + redirectTo);
        }, 500);
      }
    } catch (error) {
      const newErrors = {};

      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }

      setErrors(newErrors);
    }
  };

  // OnChange event on each form field, because form fields are disabled
  const handleChange = (e) => {
    //name attritube & value from the field
    const { name, value } = e.target;

    // Update the formData state
    setformData({
      ...formData,
      [name]: value,
    });

    // Validate the changed field and update errors state
    validateField(name, value);
  };

  // Function to validate form validate
  const validateField = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });
      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      // Set the error message for the current field if validation fails
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };
  document.body.classList.add("auth-fluid-pages");
  return (
    <div className="auth-fluid">
      <div className="container">
        <div className="row justify-content-center w-100 align-items-center h-100">
          <div className="bg-white col-md-8 col-lg-6 col-xl-4 py-3 rounded shadow-lg">
            <div className="auth-fluid-form-box position-relative h-100 p-0">
              <div className="align-items-center d-flex h-100">
                <div className="p-3">
                  {/* Logo */}
                  <Logo />

                  {/* title*/}
                  <h4 className="mt-0">Login to Your Account</h4>
                  <p className="mb-4">Enter your email address and password to access account.</p>

                  <form onSubmit={handleSubmit}>
                    {!isStaff && (
                      <div className="mb-3">
                        <label htmlFor="login_as" className="form-label">
                          Login as
                        </label>
                        <br />
                        <div
                          className="btn-group w-100"
                          role="group"
                          aria-label="Basic radio toggle button group"
                        >
                          <input
                            type="radio"
                            className="btn-check"
                            name="role_id"
                            id="login_as_agency"
                            value="3"
                            onChange={handleChange}
                            defaultChecked={formData.role_id === 3 ? true : false}
                          />
                          <label className="btn btn-outline-primary" htmlFor="login_as_agency">
                            Agency
                          </label>

                          <input
                            type="radio"
                            className="btn-check"
                            name="role_id"
                            id="login_as_employer"
                            value="2"
                            onChange={handleChange}
                            defaultChecked={formData.role_id === 2 ? true : false}
                          />
                          <label className="btn btn-outline-primary" htmlFor="login_as_employer">
                            Facility
                          </label>
                        </div>
                      </div>
                    )}

                    <div className="mb-3">
                      <label htmlFor="emailaddress" className="form-label">
                        Email address
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        name="email"
                        id="emailaddress"
                        placeholder="Enter your email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                      {/* Validation message */}
                      {errors.email && <span className="error">{errors.email}</span>}
                    </div>
                    <div className="mb-3">
                      {!isStaff && (
                        <Link to="/client/forgot-password" className="float-end">
                          <small>Forgot your password?</small>
                        </Link>
                      )}
                      <label htmlFor="password" className="form-label">
                        Password
                      </label>
                      <div className="input-group input-group-merge">
                        <input
                          type={showPassword ? "text" : "password"}
                          id="password"
                          name="password"
                          className="form-control"
                          placeholder="Enter your password"
                          value={formData.password}
                          onChange={handleChange}
                        />
                        <div
                          className={
                            showPassword ? "input-group-append show-password" : "input-group-append"
                          }
                          data-password="false"
                        >
                          <div className="input-group-text">
                            <span className="password-eye" onClick={togglePasswordVisibility} />
                          </div>
                        </div>
                      </div>
                      {/* Validation message */}
                      {errors.password && <span className="error">{errors.password}</span>}
                    </div>

                    <div className="text-center d-grid">
                      <button
                        className="btn btn-info btn-block"
                        type="submit"
                        disabled={formBtn.disabled}
                      >
                        {formBtn.label}
                      </button>

                      {!isStaff && (
                        <p className="mt-2">
                          Login as&nbsp;
                          <Link className="ml-1" to="/">
                            <b>Job Seeker</b>
                          </Link>
                        </p>
                      )}
                    </div>
                  </form>
                  {/* end form*/}
                  {/* Footer*/}
                  {/* 
            <footer className="footer footer-alt">
              <p>
                Don't have an account?&nbsp;
                <Link className="ml-1" to="/client/register">
                  <b>Sign Up</b>
                </Link>
              </p>
            </footer>
            */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
