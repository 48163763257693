import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import * as auth from "../../../services/AuthService";
import Logo from "./Logo";

function EmailVerification() {
  let navigate = useNavigate();

  // Form button state
  const [formBtn, setformBtn] = useState({
    label: "Verify",
    disabled: false,
  });

  // Use useState hook to initialize FormData variables with empty values
  const [formData, setformData] = useState({
    user_id: localStorage.getItem("userId"),
    code: "",
  });

  // Define Error state
  const [errors, setErrors] = useState({});

  // Define the validation schema
  let validationSchema = Yup.object({
    code: Yup.string().required("Verification code is required"),
  });

  // Form submit function
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // without abortEarly, it will return back on 1st field's validation occured, won't check other fields
      await validationSchema.validate(formData, { abortEarly: false });

      // Disable the btn
      setformBtn({
        label: "Please wait...",
        disabled: true,
      });

      // Call function with formData and API endpoint
      let res = await auth.emailVerification(formData, "user/verify-email");

      // Reset the btn
      setformBtn({
        label: "Verify",
        disabled: false,
      });

      if (res) {
        setTimeout(() => {
          navigate("/dashboard", { replace: true });
        }, 500);
      }
    } catch (error) {
      const newErrors = {};

      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }

      setErrors(newErrors);
    }
  };

  // OnChange event on each form field, because form fields are disabled
  const handleChange = (e) => {
    //name attritube & value from the field
    const { name, value } = e.target;

    // Update the formData state
    setformData({
      ...formData,
      [name]: value,
    });

    // Validate the changed field and update errors state
    validateField(name, value);
  };

  // Function to validate form validate
  const validateField = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });
      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      // Set the error message for the current field if validation fails
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  // Function to resend verification
  const handleResendCode = async (e) => {
    e.preventDefault();

    try {
      const param = {
        user_id: localStorage.getItem("userId"),
      };
      // Call function with formData and API endpoint
      await auth.resendVerificationCode(param, "user/resend-verify-email");
    } catch (error) {
      const newErrors = {};

      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }

      setErrors(newErrors);
    }
  };
  document.body.classList.add("auth-fluid-pages");
  return (
    <div
      className="auth-fluid"
      style={{ backgroundPosition: "center", backgroundSize: "cover", background: "none" }}
    >
      <div className="container">
        <div className="row justify-content-center w-100 align-items-center h-100">
          <div className="bg-white col-md-8 col-lg-6 col-xl-4 pt-3 rounded shadow-lg">
            <div className="p-3">
              {/* Logo */}
              <Logo />

              <h4 className="mt-0">Verify your email</h4>
              <p className="mb-4">
                We have sent you a verification code to your email. Please check your inbox and
                complete the verification.
              </p>

              <form onSubmit={handleSubmit}>
                <div className="mb-2">
                  <label htmlFor="name" className="form-label">
                    Verification Code <span className="required">*</span>
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="code"
                    name="code"
                    placeholder="Enter your verfication code"
                    value={formData.code}
                    onChange={handleChange}
                  />
                  {/* Validation message */}
                  {errors.code && <span className="error">{errors.code}</span>}
                </div>

                <div className="text-center d-grid">
                  <button
                    className="btn btn-info btn-block"
                    type="submit"
                    disabled={formBtn.disabled}
                  >
                    {formBtn.label}
                  </button>

                  <p className="mt-3">
                    Didn't receive the code?&nbsp;
                    <Link
                      to="#"
                      onClick={(e) => {
                        handleResendCode(e);
                      }}
                    >
                      Resend Now
                    </Link>
                  </p>

                  <p className="text-center mt-3">
                    Already have an account?&nbsp;
                    <Link to="/admin/login" className="ml-1">
                      <b>Login</b>
                    </Link>
                  </p>
                </div>
              </form>
            </div>
          </div>
        </div>
        {/* end .align-items-center.d-flex.h-100*/}
      </div>
      {/* end auth-fluid-form-box*/}
    </div>
  );
}

export default EmailVerification;
