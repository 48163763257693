import { toast } from "react-toastify";

export async function apiRequest(endpoint, method = "GET", data = null) {
    const token = localStorage.getItem("token");
    let url = process.env.REACT_APP_API_URL + endpoint;

    try {
        // Handle query parameters for GET requests
        if (method.toUpperCase() === "GET" && data) {
            const queryString = new URLSearchParams(data).toString();
            url += `?${queryString}`;
        }

        const options = {
            method: method.toUpperCase(),
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
        };

        // Add the body only for methods that support it
        if (data && ["POST", "PUT", "PATCH"].includes(method.toUpperCase())) {
            options.body = JSON.stringify(data);
        }

        const response = await fetch(url, options);

        // Check for network-level errors
        if (!response.ok) {
            const errorMessage = `Error ${response.status}: ${response.statusText}`;
            //toast.error(errorMessage);
            return {
                success: false,
                status: response.status,
                message: errorMessage,
                data: null,
            };
        }

        const res = await response.json();

        // Validate application-level response structure
        if (res.status || res.success) {
            return {
                success: true,
                status: response.status,
                message: res.message || "Request successful",
                data: res.data || null,
            };
        } else {
            toast.error(res.message || "An error occurred");
            return {
                success: false,
                status: response.status,
                message: res.message || "An error occurred",
                data: null,
            };
        }
    } catch (error) {
        // Handle unexpected errors
        console.error("Unexpected Error:", error);
        toast.error("Unexpected error occurred. Please try again later.");
        return {
            success: false,
            status: null,
            message: "Unexpected error occurred. Please try again later.",
            data: null,
        };
    }
}
