import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import * as api from "../../../services/ApiService";
import * as Yup from "yup";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as toast from "../../../utils/toastUtils";
import { Tooltip } from "react-tooltip";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";

function AddCandidateStep2() {
  let navigate = useNavigate();
  const location = useLocation();
  const isStaff = location.pathname.includes("/staff");
  const urlSlug = isStaff ? "staff" : "client";
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [formData, setformData] = useState({
    user_id: user.id,
    token: user.token,
    userID: localStorage.getItem("candidate_id"),
    available_start_date: null,
    profession_id: null,
    specialty_id: null,
    employement_types: [],
    shifts: [],
    desired_state_ids: [],
  });

  const [states, setStates] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [jobShifts, setShifts] = useState([]);
  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-employment-types");
        setEmploymentTypes(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-shifts");
        setShifts(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [professions, setProfessions] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-professions");
        setProfessions(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [specialties, setspecialties] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchSpecialtyData = async () => {
      try {
        const apiData = await api.getMethod("get-specialties/" + formData.profession_id);

        setspecialties(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchSpecialtyData();
  }, [formData.profession_id]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [selectAll, setSelectAll] = useState(false);

  // Handle Select All Checkbox
  const handleSelectAllChange = (e) => {
    const checked = e.target.checked;

    if (checked) {
      // Select all state ids
      const allStateIds = states.map((state) => state.id);
      //setformData({ ...formData, desired_state_ids: allStateIds });
      setformData({ ...formData, desired_state_ids: [...allStateIds, "all"] });
      setSelectAll(true);
    } else {
      setSelectAll(false);
      // Deselect all
      setformData({ ...formData, desired_state_ids: [] });
    }
  };

  const handleEtCheckboxChange = (event) => {
    const { name, value, checked } = event.target;

    setformData((prevValues) => {
      const newValue = parseInt(value);
      const updatedTypes = checked
        ? [...prevValues.employement_types, newValue]
        : prevValues.employement_types.filter((item) => item !== newValue);

      validateFields(name, updatedTypes);

      return {
        ...prevValues,
        employement_types: updatedTypes,
      };
    });
  };

  const handleShiftCheckboxChange = (event) => {
    const { name, value, checked } = event.target;
    const newValue = parseInt(value);
    setformData((prevValues) => {
      const updatedShifts = checked
        ? [...prevValues.shifts, newValue]
        : prevValues.shifts.filter((item) => item !== newValue);

      validateFields(name, updatedShifts);

      return {
        ...prevValues,
        shifts: updatedShifts,
      };
    });
  };

  // Handle individual select change
  const handleSelectChange = (e) => {
    const { name, value, checked } = e.target;

    const options = Array.from(e.target.selectedOptions, (option) => option.value);

    // Remove "all" if user deselects states individually
    const filteredOptions = options.filter((item) => item !== "all");

    setformData({ ...formData, desired_state_ids: filteredOptions });

    // Uncheck Select All if not all states are selected
    if (filteredOptions.length == states.length) {
      setSelectAll(true);
      setformData({ ...formData, desired_state_ids: [...filteredOptions, "all"] });
    } else setSelectAll(false);
    validateFields(name, filteredOptions);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  //Define the validation schema
  const validationSchema = Yup.object().shape({
    employement_types: Yup.array().min(1, "Select at least one employment type"),
    shifts: Yup.array().min(1, "Select at least one shift"),
    desired_state_ids: Yup.array().min(1, "Select at least one state"),
    profession_id: Yup.string().required("Profession is Required"),
    specialty_id: Yup.string().required("Specialty is Required"),
    // available_start_date: Yup.string().required("Available Start Date is Required"),
  });

  const [errors, setErrors] = useState({});

  const fetchJobPreferenceData = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        userID: localStorage.getItem("candidate_id"),
      };
      const apiData = await api.postMethod(param, "client/get-candidate-job-preference");

      setformData((prevValues) => ({
        ...prevValues,
        employement_types: apiData.data.employement_types || [],
        shifts: apiData.data.shifts || [],
        desired_state_ids: apiData.data.desired_state_ids || [],

        profession_id: apiData.data.profession_id || null,
        specialty_id: apiData.data.specialty_id || null,
        available_start_date: apiData.data.available_start_date || null,
      }));

      /*
      //setEtCheckboxValues(apiData);
      setformData({ ...formData, employement_types: apiData.data.employement_types });

      //validateFields("employement_types", apiData.employement_types);

      //setShiftCheckboxValues(apiData);
      setformData({ ...formData, shifts: apiData.data.shifts });
      //validateFields("shifts", apiData.shifts);

      //setSelectedOptions(apiData);
      setformData({ ...formData, desired_state_ids: apiData.data.desired_state_ids });
      //validateFields("desired_state_ids", apiData.desired_state_ids);

      setformData({ ...formData, profession_id: apiData.data.profession_id });
      setformData({ ...formData, specialty_id: apiData.data.specialty_id });
      setformData({ ...formData, available_start_date: apiData.data.available_start_date });
      */

      /*
      setEtCheckboxValues({
        ...etCheckboxValues,
        employement_types: apiData.employmenet_type_data,
      });
      */
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchJobPreferenceData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  useEffect(() => {
    setSelectAll(false);
    if (states.length > 0 && formData.desired_state_ids.length >= states.length) {
      setSelectAll(true); // If all states are selected
    }
  }, [states, formData.desired_state_ids]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const handleSubmit = async (e, navigateTo) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      const result = await api.postMethod(formData, "client/update-candidate-job-preference");

      if (result.status) {
        /*navigate("/client/add-candidate-step3");*/
        navigate(navigateTo);
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/" + urlSlug + "/dashboard" },
    { text: "Candidates", link: "/" + urlSlug + "/candidates" },
    { text: "Add Candidate", link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              <PageTitle title="Add Candidate" breadcrumbItems={breadcrumbItems} />

              {/*Job progress bar start*/}
              <div className="row">
                <div className="col-12">
                  <div className="stepper-wrapper">
                    <div className="stepper-item completed">
                      <div className="step-counter">1</div>
                      <div className="step-name">Personal Information</div>
                    </div>
                    <div className="stepper-item active">
                      <div className="step-counter">2</div>
                      <div className="step-name">Job Preferences</div>
                    </div>
                    {/* 
                    <div className="stepper-item">
                      <div className="step-counter">3</div>
                      <div className="step-name">Professional Details</div>
                    </div>
                    */}
                    <div className="stepper-item">
                      <div className="step-counter">3</div>
                      <div className="step-name">Work History</div>
                    </div>
                    <div className="stepper-item">
                      <div className="step-counter">4</div>
                      <div className="step-name">Educational Information</div>
                    </div>
                    {/*
                    <div className="stepper-item">
                      <div className="step-counter">5</div>
                      <div className="step-name">Skills</div>
                    </div>
                    */}
                  </div>
                </div>
              </div>
              {/*Job progress bar end*/}
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <form
                        onSubmit={(e) => handleSubmit(e, "/" + urlSlug + "/add-candidate-step3")}
                      >
                        <div className="row">
                          <div className="form-group mb-3 col-md-4">
                            <label htmlFor="profession_id" className="form-label">
                              Profession *
                            </label>
                            <select
                              id="profession_id"
                              className="form-control"
                              name="profession_id"
                              value={formData.profession_id !== null ? formData.profession_id : ""}
                              onChange={handleChange}
                            >
                              <option value="" key="">
                                Please Select
                              </option>
                              {/* Render the professions */}
                              {professions.map((profession) => (
                                <option key={profession.id} value={profession.id}>
                                  {profession.profession}
                                </option>
                              ))}
                            </select>
                            {errors.profession_id && (
                              <span className="error">{errors.profession_id}</span>
                            )}
                          </div>
                          <div className="form-group mb-3 col-md-4">
                            <label htmlFor="specialty_id" className="form-label">
                              Specialty *
                            </label>
                            <select
                              id="specialty_id"
                              className="form-control"
                              name="specialty_id"
                              value={formData.specialty_id !== null ? formData.specialty_id : ""}
                              onChange={handleChange}
                            >
                              <option value="" key="">
                                Please Select
                              </option>

                              {/* Render the specialties */}
                              {specialties.map((specialty) => (
                                <option key={specialty.id} value={specialty.id}>
                                  {specialty.specialty}
                                </option>
                              ))}
                            </select>
                            {errors.specialty_id && (
                              <span className="error">{errors.specialty_id}</span>
                            )}
                          </div>

                          <div className="col-md-4">
                            <div className="mb-0">
                              <label htmlFor="available_start_date" className="form-label">
                                Available Start Date
                              </label>
                              <input
                                className="form-control"
                                type="date"
                                name="available_start_date"
                                id="available_start_date"
                                defaultValue={
                                  formData.available_start_date !== null
                                    ? formData.available_start_date
                                    : ""
                                }
                                onChange={handleChange}
                              />
                              {errors.available_start_date && (
                                <span className="error">{errors.available_start_date}</span>
                              )}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-0">
                              <label htmlFor="employment_type_id" className="form-label">
                                Employment Type
                              </label>
                              <br />

                              {/* Render the employment types */}
                              {employmentTypes &&
                                employmentTypes.length > 0 &&
                                employmentTypes.map((etype) => (
                                  <label
                                    key={etype.id}
                                    htmlFor={`et_${etype.id}`}
                                    className="me-2 d-block"
                                  >
                                    <input
                                      type="checkbox"
                                      name="employement_types"
                                      value={etype.id}
                                      id={`et_${etype.id}`}
                                      onChange={handleEtCheckboxChange}
                                      checked={formData.employement_types.includes(etype.id)}
                                    />{" "}
                                    {etype.title}
                                  </label>
                                ))}
                              {errors.employement_types && (
                                <span className="error">{errors.employement_types}</span>
                              )}
                            </div>
                          </div>

                          <div className="col-md-4">
                            <div className="mb-0">
                              <label htmlFor="shift" className="form-label">
                                Shift
                              </label>
                              <br />

                              {/* Render the employment types */}
                              {jobShifts &&
                                jobShifts.length > 0 &&
                                jobShifts.map((shift) => (
                                  <label
                                    key={shift.id}
                                    htmlFor={`shift_${shift.id}`}
                                    className="me-2 d-block"
                                  >
                                    <input
                                      type="checkbox"
                                      name="shifts[]"
                                      value={shift.id}
                                      id={`shift_${shift.id}`}
                                      onChange={handleShiftCheckboxChange}
                                      checked={formData.shifts.includes(shift.id)}
                                    />{" "}
                                    {shift.title}
                                  </label>
                                ))}
                              {errors.shifts && <span className="error">{errors.shifts}</span>}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <div className="mb-2">
                              <label htmlFor="desired_state_ids" className="form-label w-100">
                                Desired States{" "}
                                <small className="text-muted">
                                  (Ctrl+Click to Select Multiple)
                                </small>
                                <input
                                  type="checkbox"
                                  checked={selectAll}
                                  onChange={handleSelectAllChange}
                                  className="float-end mt-1"
                                  data-tooltip-id="mt-3"
                                  data-tooltip-content={selectAll ? "Unselect All" : "Select All"}
                                />
                                <Tooltip id="mt-3"></Tooltip>
                              </label>

                              <select
                                className="form-control"
                                name="desired_state_ids[]"
                                id="desired_state_ids"
                                multiple
                                value={
                                  formData.desired_state_ids !== null
                                    ? formData.desired_state_ids
                                    : []
                                }
                                onChange={handleSelectChange}
                                defaultValue={formData.desired_state_ids}
                              >
                                {/* Render the states */}
                                {states &&
                                  states.length > 0 &&
                                  states.map((state) => (
                                    <option key={state.id} value={state.id}>
                                      {state.name}
                                    </option>
                                  ))}
                              </select>
                              {errors.desired_state_ids && (
                                <span className="error">{errors.desired_state_ids}</span>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <Link
                              to={
                                `/` +
                                urlSlug +
                                `/add-candidate-step1/` +
                                localStorage.getItem("candidate_unique_id")
                              }
                              className="btn btn-secondary"
                              type="submit"
                            >
                              <i className="fas fa-angle-left" /> Go Back
                            </Link>
                          </div>
                          <div className="col-md-6">
                            <div className="text-lg-end">
                              <button className="btn btn-primary" type="submit">
                                Save and Continue <i className="fas fa-angle-right" />
                              </button>

                              <button
                                className="btn btn-info ms-1"
                                type="button"
                                onClick={(e) => handleSubmit(e, "/" + urlSlug + "/candidates")}
                              >
                                Save & Exit <i className="fas fa-check" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ThemeSettings />
    </>
  );
}

export default AddCandidateStep2;
