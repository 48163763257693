import { useMemo } from "react";
import useSubscription from "./useSubscription";

const useFilteredMenu = (menuConfig) => {
  const { isModuleEnabled, isFeatureEnabledForModule, subscription } = useSubscription();

  console.log(subscription);
  
  const filteredMenu = useMemo(() => {
    const filterItems = (items) =>
      items
        .map((item) => {
          // Check if the item is always visible
          if (item.alwaysVisible) return item;

          // Check module and feature permissions
          if (item.module && !isModuleEnabled(item.module)) return null;
          if (item.feature && !isFeatureEnabledForModule(item.module, item.feature)) return null;

          // If the item has children, filter them recursively
          if (item.children) {
            const filteredChildren = filterItems(item.children);
            return filteredChildren.length > 0 ? { ...item, children: filteredChildren } : null;
          }

          return item; // Return the item if it passes all checks
        })
        .filter(Boolean); // Remove null items

    return menuConfig.map((menu) => ({
      ...menu,
      items: filterItems(menu.items),
    }));
  }, [menuConfig, isModuleEnabled, isFeatureEnabledForModule]);

  return filteredMenu;
};

export default useFilteredMenu;