import React from "react";
import { formatAmount } from "../../../../utils/AmountFormatter";

function BasicInfo({ jobRow, attachments, loading, noRecordsFound, formatDate, formatTime }) {
  return (
    <>
      {jobRow.description != null ? (
        <>
          <h4 className="font-13  text-capitalize fw-bold">Job Description</h4>
          <p className="mb-3">{jobRow.description}</p>
        </>
      ) : (
        ""
      )}

      {jobRow.qualification != null ? (
        <>
          <h4 className="font-13  text-capitalize fw-bold">Job Qualification</h4>
          <p className=" mb-3">{jobRow.qualification}</p>
        </>
      ) : (
        ""
      )}

      {jobRow.responsibilities != null ? (
        <>
          <h4 className="font-13  text-capitalize fw-bold">Job Responsibilities</h4>
          <p className=" mb-3">{jobRow.responsibilities}</p>
        </>
      ) : (
        ""
      )}

      <div className="row">
        <div className="col-md-4">
          <div className="mb-2">
            <h4 className="font-13  text-capitalize fw-bold mb-1">Job ID</h4>
            <p>#{jobRow.unique_id}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-2">
            <h4 className="font-13  text-capitalize fw-bold mb-1">Location</h4>
            <p>
              {jobRow.state_code !== null && jobRow.city_name !== null
                ? jobRow.city_name + ", " + jobRow.state_code
                : jobRow.state_code !== null
                ? jobRow.state_code
                : jobRow.city_name !== null
                ? jobRow.city_name
                : ""}
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-2">
            <h4 className="font-13  text-capitalize fw-bold mb-1">Specialties</h4>
            <p>{jobRow.specialty !== null ? jobRow.specialty : "-"}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-2">
            <h4 className="font-13  text-capitalize fw-bold mb-1">Shifts</h4>
            <p>{jobRow.shift_title ? jobRow.shift_title : "-"}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-2">
            <h4 className="font-13  text-capitalize fw-bold mb-1">Date Posted</h4>
            <p>
              {formatDate(jobRow.created_at)}{" "}
              <small className="">{formatTime(jobRow.created_at)}</small>
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-2">
            <h4 className="font-13  text-capitalize fw-bold mb-1">Expiration date</h4>
            <p>
              {jobRow.end_date != null
                ? `${formatDate(jobRow.end_date)} ${formatTime(jobRow.end_date)}`
                : "-"}
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-2">
            <h4 className="font-13  text-capitalize fw-bold mb-1">Salary</h4>
            <p>
              $
              {jobRow.salary_start_range
                ? formatAmount(jobRow.salary_start_range) + " " + jobRow.salary_type
                : "0"}
            </p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-2">
            <h4 className="font-13  text-capitalize fw-bold mb-1">Total Opening</h4>
            <p>{jobRow.total_opening}</p>
          </div>
        </div>
        <div className="col-md-4">
          <div className="mb-2">
            <h4 className="font-13  text-capitalize fw-bold mb-1">Type</h4>
            <p>{jobRow.employment_type_title ? jobRow.employment_type_title : "-"}</p>
          </div>
        </div>
      </div>

      {!noRecordsFound && (
        <div className="">
          <h4 className="font-13  text-capitalize fw-bold mb-1">Attatchments</h4>
          {Object.entries(attachments).map(([key, value]) => (
            <div className="card mb-0 mt-2 shadow-none border" key={value.id}>
              <div className="p-2">
                <div className="row align-items-center">
                  <div className="col-auto">
                    <div className="avatar-sm">
                      <span className="avatar-title badge-soft-primary text-primary rounded">
                        {value.file_type}
                      </span>
                    </div>
                  </div>
                  <div className="col ps-0">
                    <span className="text-muted fw-bold">{value.title}</span>
                    <p className="mb-0 font-12">{value.file_size}</p>
                  </div>
                  <div className="col-auto">
                    <a
                      href={value.dir_path}
                      download
                      target="_blank"
                      rel="noreferrer"
                      className="btn btn-link font-16 text-muted"
                    >
                      <i className="dripicons-download" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
}

export default BasicInfo;
