import React from 'react'

const Job_Request_details = () => {
  return<>
    <div>
        <div>
            <strong>Profession</strong> : profession
        </div>
        <div>
            <strong>Desired Shift</strong> : Day
        </div>
        <div>
            <strong>Start Date</strong> : Flexiable
        </div>
    </div>
  </>
}

export default Job_Request_details
