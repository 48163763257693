import useSubscriptionStore from "../store/useSubscriptionStore";

const useSubscription = () => {
  // Access Zustand store states and methods
  const { subscription, fetchSubscription, loading, error } = useSubscriptionStore();

  // Module Guard: Check if a module is enabled
  const isModuleEnabled = (moduleName) => {
    if (!subscription || !subscription.data) return false;
    return subscription.data.some((module) => module.module_name === moduleName);
  };

  // Feature Guard: Check if a feature is enabled within a module
  const isFeatureEnabledForModule = (moduleName, featureName) => {
    if (!subscription || !subscription.data) return false;

    const module = subscription.data.find((mod) => mod.module_name === moduleName);
    if (!module) return false;

    const feature = module.features.find((feat) => feat.name === featureName && feat.is_enabled === 1);
    return !!feature;
  };

  return { subscription, isModuleEnabled, isFeatureEnabledForModule, fetchSubscription, loading, error };
};

export default useSubscription;