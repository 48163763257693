import React, { useState, useEffect } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Sidebar from "../Layout/Sidebar";
import ThemeSettings from "../Layout/ThemeSettings";
import * as dashboard from "../../services/DashboardService";
import * as api from "../../services/ApiService";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../utils/tokenUtils";
import { Link, useLocation } from "react-router-dom";
import { formatPhoneNumber } from "../../utils/phoneNumberFormatter";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import Spinner from "../Loader/Spinner";
import Announcement from "../UI/Announcement";

function Dashboard() {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  let fullName = user.name;
  const [isLoading, setIsLoading] = useState(false);

  const location = useLocation();
  //const isStaff = location.pathname.includes("/staff");
  const isStaff = user.role_id == 6 ? true : false;
  /*
  var today = new Date();
  var curHr = today.getHours();

  let strVal = "";
  if (curHr < 12) {
    strVal = "Good Morning";
  } else if (curHr < 18) {
    strVal = "Good Afternoon";
  } else {
    strVal = "Good Evening";
  }
  */

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    file_name: null,
  });

  const [greetingText, setGreetingText] = useState("");
  const [totalJobs, setTotalJobs] = useState(0);
  const [totalCandidates, setTotalCandidates] = useState(0);
  const [totalEmployees, setTotalEmployees] = useState(0);
  const [totalApplicants, setTotalApplicants] = useState(0);

  const [recentApplications, setRecentApplications] = useState({});
  const [recentApplicationsLoading, setRecentApplicationsLoading] = useState(true);
  const [noRecentApplicationsRecordsFound, setNoRecentApplicationsRecordsFound] = useState(false);

  const [recentCandidates, setRecentCandidates] = useState({});
  const [recentCandidatesLoading, setRecentCandidatesLoading] = useState(true);
  const [noRecentCandidatesRecordsFound, setNoRecentCandidatesRecordsFound] = useState(false);

  const [recentEmployees, setRecentEmployees] = useState({});
  const [recentEmployeesLoading, setRecentEmployeesLoading] = useState(true);
  const [noRecentEmployeesRecordsFound, setNoRecentEmployeesRecordsFound] = useState(false);

  const fetchDashboardMeta = async () => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
      };
      const result = await dashboard.postMethod(param, "client/get-dashboard-data");
      if (result.status) {
        const apiData = result.data;

        setGreetingText(apiData.greetingText);
        setTotalJobs(apiData.totalJobs);
        setTotalCandidates(apiData.totalCandidates);
        setTotalEmployees(apiData.totalEmployees);
        setTotalApplicants(apiData.totalApplicants);

        const apiDataObj = apiData.jobApplicantions.reduce((acc, currentValue, index) => {
          acc[index] = currentValue;
          return acc;
        }, {});
        setRecentApplications(apiDataObj);

        setRecentApplicationsLoading(false);
        // Check if data array is empty
        if (apiData.jobApplicantions.length === 0) {
          setNoRecentApplicationsRecordsFound(true);
        }

        /* Recent Candidates */
        const apiRCDataObj = apiData.recentCandidates.reduce((acc, currentValue, index) => {
          acc[index] = currentValue;
          return acc;
        }, {});
        setRecentCandidates(apiRCDataObj);

        setRecentCandidatesLoading(false);
        // Check if data array is empty
        if (apiData.recentCandidates.length === 0) {
          setNoRecentCandidatesRecordsFound(true);
        }

        /* Recent Employees*/
        const apiREDataObj = apiData.recentEmployees.reduce((acc, currentValue, index) => {
          acc[index] = currentValue;
          return acc;
        }, {});
        setRecentEmployees(apiREDataObj);

        setRecentEmployeesLoading(false);
        // Check if data array is empty
        if (apiData.recentEmployees.length === 0) {
          setNoRecentEmployeesRecordsFound(true);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setRecentApplicationsLoading(false);
    }
  };

  useEffect(() => {
    fetchDashboardMeta();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return `${formattedDate}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formattedTime}`;
  };

  // Dragrabble Functionlity
  const initialColumns = [
    {
      id: "col-1",
      className: "col-xl-12",
      content: (
        <div className="card">
          <div className="card-body custom-box-shadow">
            <span className="dragula-handle float-end mt-n2" />
            <h4 className="header-title mb-3">Recent Application</h4>
            <div className="table-responsive">
              <table className="table  table-nowrap table-centered m-0">
                <thead className="table-light">
                  <tr>
                    <th>Job</th>
                    <th>Applicant</th>
                    <th>Applied On</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {recentApplicationsLoading ? (
                    <tr>
                      <td colSpan="4">Loading...</td>
                    </tr>
                  ) : (
                    <>
                      {noRecentApplicationsRecordsFound ? (
                        <tr>
                          <td colSpan="4">No resume found</td>
                        </tr>
                      ) : (
                        <>
                          {Object.entries(recentApplications).map(([key, value]) => (
                            <tr key={`job_` + value.id + `_` + value.user_unique_id}>
                              <td>
                                <h5 className="m-0 font-weight-normal">
                                  <Link to={`/client/job/` + value.unique_id} className="">
                                    {value.title}{" "}
                                  </Link>
                                  <span className="mb-0 text-muted">
                                    <small>(#{value.unique_id})</small>
                                  </span>
                                </h5>
                                <p className="mb-0 text-muted">
                                  <small>
                                    {value.state_code !== null && value.city_name !== null
                                      ? value.city_name + ", " + value.state_code
                                      : value.state_code !== null
                                      ? value.state_code
                                      : value.city_name !== null
                                      ? value.city_name
                                      : ""}
                                  </small>
                                </p>
                              </td>
                              <td>
                                <Link
                                  to={`/client/user-profile/` + value.user_unique_id}
                                  className=""
                                >
                                  {value.name}
                                </Link>
                              </td>
                              <td>
                                {formatDate(value.created_at)}{" "}
                                <small className="text-muted">{formatTime(value.created_at)}</small>
                              </td>
                              <td>
                                {value.status == 1 && (
                                  <label className="badge badge-soft-primary">Applied</label>
                                )}
                                {value.status == 2 && (
                                  <label className="badge badge-soft-info">Shortlisted</label>
                                )}
                                {value.status == 3 && (
                                  <label className="badge badge-soft-warning">Submitted</label>
                                )}
                                {value.status == 4 && (
                                  <label className="badge badge-soft-warning">Interview</label>
                                )}
                                {value.status == 5 && (
                                  <label className="badge badge-soft-secondary">Offered</label>
                                )}
                                {value.status == 6 && (
                                  <label className="badge badge-soft-success">Hired</label>
                                )}
                                {value.status == 7 && (
                                  <label className="badge badge-soft-danger">Rejected</label>
                                )}
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ),
    },
    {
      id: "col-2",
      className: isStaff ? "col-xl-12" : "col-xl-6",
      content: (
        <div className="card custom-box-shadow">
          <div className="card-body">
            <span className="dragula-handle float-end mt-n2" />
            <h4 className="header-title mb-3">Recent Candidates</h4>
            <div className="table-responsive">
              <table className="table table-centered table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Registered On</th>
                  </tr>
                </thead>
                <tbody>
                  {recentCandidatesLoading ? (
                    <tr>
                      <td colSpan="4">Loading...</td>
                    </tr>
                  ) : (
                    <>
                      {noRecentCandidatesRecordsFound ? (
                        <tr>
                          <td colSpan="4">No records found</td>
                        </tr>
                      ) : (
                        <>
                          {Object.entries(recentCandidates).map(([key, value]) => (
                            <tr key={`can_` + value.id}>
                              <td>
                                <Link to={`/client/user-profile/` + value.unique_id} className="">
                                  {value.profile_pic_path && (
                                    <img
                                      src={value.profile_pic_path}
                                      className="rounded-circle"
                                      alt="user-img"
                                      height={32}
                                    />
                                  )}{" "}
                                  {value.name}
                                </Link>
                              </td>
                              <td>{value.email}</td>
                              <td>{formatPhoneNumber(value.phone, "1")}</td>
                              <td>
                                {formatDate(value.created_at)}{" "}
                                <small className="text-muted">{formatTime(value.created_at)}</small>
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
          {/* end card-body*/}
        </div>
      ),
    },
    {
      id: "col-3",
      className: "col-xl-6",
      content: (
        <div className="card custom-box-shadow">
          <div className="card-body">
            <span className="dragula-handle float-end mt-n2" />
            <h4 className="header-title mb-3">Recent Employees</h4>
            <div className="table-responsive">
              <table className="table table-centered table-nowrap mb-0">
                <thead className="table-light">
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Phone Number</th>
                    <th>Registered On</th>
                  </tr>
                </thead>
                <tbody>
                  {recentEmployeesLoading ? (
                    <tr>
                      <td colSpan="4">Loading...</td>
                    </tr>
                  ) : (
                    <>
                      {noRecentEmployeesRecordsFound ? (
                        <tr>
                          <td colSpan="4">No records found</td>
                        </tr>
                      ) : (
                        <>
                          {Object.entries(recentEmployees).map(([key, value]) => (
                            <tr key={`emp_` + value.id}>
                              <td>
                                <Link to={`/client/user-profile/` + value.unique_id} className="">
                                  {value.profile_pic_path && (
                                    <img
                                      src={value.profile_pic_path}
                                      className="rounded-circle"
                                      alt="user-img"
                                      height={32}
                                    />
                                  )}{" "}
                                  {value.name}
                                </Link>
                              </td>
                              <td>{value.email}</td>
                              <td>{formatPhoneNumber(value.phone, "1")}</td>
                              <td>
                                {formatDate(value.created_at)}{" "}
                                <small className="text-muted">{formatTime(value.created_at)}</small>
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      ),
    },
  ];

  const updatedColumns = isStaff
    ? initialColumns.filter((column) => column.id !== "col-3")
    : initialColumns;

  const [columns, setColumns] = useState([]);
  useEffect(() => {
    setColumns(updatedColumns);
  }, [recentApplicationsLoading, recentCandidatesLoading, recentEmployeesLoading]);

  const onDragEnd = (result) => {
    const { destination, source } = result;

    // If no destination, or the item is dropped where it was dragged from, do nothing
    if (
      !destination ||
      (destination.droppableId === source.droppableId && destination.index === source.index)
    ) {
      return;
    }

    // Reorder columns
    const newColumns = Array.from(columns);
    const [moved] = newColumns.splice(source.index, 1);
    newColumns.splice(destination.index, 0, moved);

    setColumns(newColumns);
  };


  const announcementModule = user?.role_id === 2 ? "FACILITY_DASHBOARD" : user?.role_id === 3 ? "AGENCY_DASHBOARD" : undefined;

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            <Announcement module={announcementModule} />

            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <h4 className="page-title">
                      {greetingText && greetingText + ", " + fullName} &nbsp;
                    </h4>
                  </div>
                </div>
              </div>

              <>
                <div className="row">
                  <div className="col-md-6 col-xl-3">
                    <div className="widget-rounded-circle card">
                      <Link to={`/client/job-postings`}>
                        <div className="card-body custom-box-shadow">
                          <div className="row">
                            <div className="col-6">
                              <div className="avatar-lg rounded-circle bg-soft-primary border-primary border">
                                <i className="fe-briefcase font-22 avatar-title text-primary" />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="text-end">
                                <h3 className="mt-1">
                                  <span data-plugin="counterup">{totalJobs}</span>
                                </h3>
                                <p className="text-info mb-1 text-truncate">Total Jobs</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                      {/* end row*/}
                    </div>
                    {/* end widget-rounded-circle*/}
                  </div>
                  <div className="col-md-6 col-xl-3">
                    <div className="widget-rounded-circle card">
                      <Link to={`/client/applicants`}>
                        <div className="card-body custom-box-shadow">
                          <div className="row">
                            <div className="col-6">
                              <div className="avatar-lg rounded-circle bg-soft-primary border-primary border">
                                <i className="fe-users font-22 avatar-title text-primary" />
                              </div>
                            </div>
                            <div className="col-6">
                              <div className="text-end">
                                <h3 className="text-dark mt-1">
                                  <span data-plugin="counterup">{totalApplicants}</span>
                                </h3>
                                <p className="text-info mb-1 text-truncate">Total Applicants</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                      {/* end row*/}
                    </div>
                    {/* end widget-rounded-circle*/}
                  </div>
                  {/* end col*/}
                  {/* end col*/}
                  <div className="col-md-6 col-xl-3">
                    <div className="widget-rounded-circle card">
                      <Link to={`/client/candidates`}>
                        <div className="card-body custom-box-shadow">
                          <div className="row">
                            <div className="col-6">
                              <div className="avatar-lg rounded-circle bg-soft-primary border-primary border">
                                <i className="fe-users font-22 avatar-title text-primary" />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="text-end">
                                <h3 className="text-dark mt-1">
                                  <span data-plugin="counterup">{totalCandidates}</span>
                                </h3>
                                <p className="text-info mb-1 text-truncate">Total Candidates</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Link>
                      {/* end row*/}
                    </div>
                    {/* end widget-rounded-circle*/}
                  </div>
                  {/* end col*/}

                  {!isStaff && (
                    <div className="col-md-6 col-xl-3">
                      <div className="widget-rounded-circle card">
                        <Link to={`/client/employees`}>
                          <div className="card-body custom-box-shadow">
                            <div className="row">
                              <div className="col-6">
                                <div className="avatar-lg rounded-circle bg-soft-primary border-primary border">
                                  <i className="fe-folder font-22 avatar-title text-primary" />
                                </div>
                              </div>
                              <div className="col-6">
                                <div className="text-end">
                                  <h3 className="text-dark mt-1">
                                    <span data-plugin="counterup">{totalEmployees}</span>
                                  </h3>
                                  <p className="text-info mb-1 text-truncate">Total Employees</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Link>
                        {/* end row*/}
                      </div>
                      {/* end widget-rounded-circle*/}
                    </div>
                  )}
                  {/* end col*/}
                </div>

                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="columns" direction="vertical">
                    {(provided) => (
                      <div className="row" {...provided.droppableProps} ref={provided.innerRef}>
                        {columns.map((column, index) => (
                          <Draggable key={column.id} draggableId={column.id} index={index}>
                            {(provided) => (
                              <div
                                className={column.className}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <div className="column-content">{column.content}</div>
                              </div>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </>
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      {isLoading === true && <Spinner />}
      <ThemeSettings />
    </>
  );
}

export default Dashboard;
