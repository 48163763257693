import React, { useEffect, useState, useRef } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Layout from "../../Layout/Index";
import PageTitle from "../../Layout/PageTitle";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as Yup from "yup";
import SchedulingCalendar from "./Tabs/SchedulingCalendar";
import SchedulingListings from "./Tabs/SchedulingListings";
import { toast } from "react-toastify";
import Spinner from "../../Loader/Spinner";
import Modal from "react-bootstrap/Modal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Tooltip } from "react-tooltip";
import { formatDate } from "../../../utils/dateFormat";
import { currentRole } from "../../Layout/HelmetComponent";

const Scheduling = () => {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const [isLoading, setIsLoading] = useState(false);

  const childRef = useRef();

  const [selectedTab, setSelectedTab] = useState("calendar_view");
  // Tab select event
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    title: null,
    startDate: null,
    endDate: null,
    startTime: null,
    endTime: null,
    assigneeId: null,
    importance: null,
    description: null,
    attatchmentIds: [],
  });

  const handleEdit = (record) => {
    fetchSchedulingAttachments(record.id);
    // Populate formData with the values of the selected record
    setformData({
      id: record.id,
      user_id: user.id,
      title: record.title,
      startDate: record.start_date,
      endDate: record.end_date,
      startTime: record.start_time,
      endTime: record.end_time,
      assigneeId: record.assignee_id,
      importance: record.importance,
      description: record.description,
    });

    setAttatchmentData({ ...attatchmentData, id: record.id });

    setAddModalOpen(true);
  };

  const [uploadAddOpen, setAddModalOpen] = useState(false);

  const toggleAddModal = () => {
    setAddModalOpen(!uploadAddOpen);

    if (!uploadAddOpen) {
      setformData({
        id: null,
        user_id: user.id,
        title: null,
        startDate: null,
        endDate: null,
        startTime: null,
        endTime: null,
        assigneeId: null,
        importance: null,
        description: null,
        attatchmentIds: [],
      });
      setAttachments({});
      // Reset validation errors
      setErrors({});
    }
  };

  //Define the validation schema
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is Required"),
    startDate: Yup.string().required("Start Date is Required"),
    endDate: Yup.string().required("End Date is Required"),
    startTime: Yup.string().required("Start Time is Required"),
    endTime: Yup.string().required("End Time is Required"),
    assigneeId: Yup.string().required("Assignee is Required"),
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      setIsLoading(true);
      const result = await api.postFileMethod(formData, "client/update-schedule");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);

        document.getElementById("file_name").value = "";
        setAddModalOpen(false);
        childRef.current.scheduleChildFunction();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleEditorChange = (event, editor) => {
    const data = editor.getData();
    setformData((prevState) => ({
      ...prevState,
      description: data,
    }));

    validateFields("description", data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const [assignedUsers, setAssignedUsers] = useState([]);
  const fetchAssignedUsers = async () => {
    try {
      const apiData = await api.getMethod("get-users/4");

      setAssignedUsers(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchAssignedUsers();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [attatchmentData, setAttatchmentData] = useState({
    id: null,
    user_id: user.id,
    token: user.token,
    file_name: null,
  });

  const handleFileChange = (e) => {
    setAttatchmentData((prevState) => ({
      ...prevState,
      file_name: e.target.files[0], // Update image field with the selected file
    }));
    uploadAttatchment();
  };

  const uploadAttatchment = async () => {
    try {
      const result = await api.postFileMethod(
        attatchmentData,
        "client/upload-schedule-attatchment"
      );
      if (result.status) {
        toast.success(result.message);
        //fetchSchedulingAttachments();
        setformData((prevState) => ({
          ...prevState,
          attatchmentIds: formData.attatchmentIds
            ? [...formData.attatchmentIds, result.attachmentId]
            : [result.attachmentId],
        }));
        document.getElementById("file_name").value = "";

        if (formData.id != null) {
          fetchSchedulingAttachments(formData.id);
        }
      }
    } catch (error) {
      console.error("Unexpected error structure:", error);
    }
  };

  const [attachments, setAttachments] = useState({});
  const [loading, setLoading] = useState(false);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchSchedulingAttachments = async (curScheduleID) => {
    setLoading(true);
    try {
      const param = {
        user_id: user.id,
        scheduleId: curScheduleID,
      };
      const result = await api.postMethod(param, "client/get-schedule-attatchments");
      setLoading(false);
      setNoRecordsFound(true);
      if (result.data.length) {
        setNoRecordsFound(false);
        setAttachments(result.data);
      } else {
        setAttachments({});
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Scheduling", link: null },
  ];

  return (
    <Layout>
      <PageTitle title="Scheduling" breadcrumbItems={breadcrumbItems} />

      {/* end page title */}
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <div className="card-body">
              <div className="row justify-content-between">
                <div className="col-auto ms-auto">
                  <div className="text-lg-end my-1 my-lg-0">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light mb-2"
                      onClick={toggleAddModal}
                    >
                      <i className="fa fa-plus mr-1" /> Add New Schedule
                    </button>
                  </div>
                </div>
                {/* end col*/}
              </div>

              <div className="row my-2">
                <div className="col">
                  <button
                    type="button"
                    className={
                      "btn  w-100 " +
                      (selectedTab === "calendar_view" ? "btn-primary" : "btn-light")
                    }
                    onClick={() => handleTabSelect("calendar_view")}
                  >
                    Calendar View
                  </button>
                </div>
                <div className="col">
                  <button
                    type="button"
                    className={
                      "btn  w-100 " + (selectedTab === "list_view" ? "btn-primary" : "btn-light")
                    }
                    onClick={() => handleTabSelect("list_view")}
                  >
                    List View
                  </button>
                </div>
              </div>

              {selectedTab == "calendar_view" && <SchedulingCalendar ref={childRef} />}

              {selectedTab == "list_view" && (
                <SchedulingListings ref={childRef} parentHandleEdit={handleEdit} />
              )}
            </div>
            {/* end card-body*/}
          </div>
          {/* end card*/}
        </div>
        {/* end col */}
      </div>

      <Modal show={uploadAddOpen} onHide={toggleAddModal} centered size="lg" backdrop="static">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Add Schedule</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-3">
                  <label className="form-label" htmlFor="title">
                    Title
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    onChange={handleChange}
                    defaultValue={formData.title}
                  />
                  {errors.title && <span className="error">{errors.title}</span>}
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-3">
                  <label className="form-label" htmlFor="startDate">
                    Start Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    name="startDate"
                    id="startDate"
                    onChange={handleChange}
                    value={formData.startDate !== null ? formData.startDate : ""}
                  />
                  {errors.startDate && <span className="error">{errors.startDate}</span>}
                </div>
              </div>

              <div className="col-md-3">
                <div className="mb-3">
                  <label className="form-label" htmlFor="endDate">
                    End Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="endDate"
                    name="endDate"
                    onChange={handleChange}
                    value={formData.endDate !== null ? formData.endDate : ""}
                  />
                  {errors.endDate && <span className="error">{errors.endDate}</span>}
                </div>
              </div>

              <div className="col-md-3 mb-2">
                <label className="form-label" htmlFor="startTime">
                  Start Time
                </label>
                <input
                  type="time"
                  className="form-control"
                  id="startTime"
                  name="startTime"
                  onChange={handleChange}
                  defaultValue={formData.startTime !== null ? formData.startTime : ""}
                />
                {errors.startTime && <span className="error">{errors.startTime}</span>}
              </div>

              <div className="col-md-3 mb-2">
                <label className="form-label" htmlFor="endTime">
                  End Time
                </label>
                <input
                  type="time"
                  className="form-control"
                  id="endTime"
                  name="endTime"
                  onChange={handleChange}
                  defaultValue={formData.endTime !== null ? formData.endTime : ""}
                />
                {errors.endTime && <span className="error">{errors.endTime}</span>}
              </div>

              <div className="mb-2 col-md-6">
                <label className="form-label" htmlFor="assigneeId">
                  Assignees
                </label>
                <select
                  className="form-control"
                  id="assigneeId"
                  name="assigneeId"
                  onChange={handleChange}
                  value={formData.assigneeId !== null ? formData.assigneeId : ""}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {/* Render the states */}
                  {assignedUsers.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </select>
                {errors.assigneeId && <span className="error">{errors.assigneeId}</span>}
              </div>

              <div className="mb-2 col-md-3">
                <label className="form-label" htmlFor="importance">
                  Importance
                </label>
                <select
                  className="form-control"
                  id="importance"
                  name="importance"
                  onChange={handleChange}
                  value={formData.importance !== null ? formData.importance : ""}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  <option value="low" key="low">
                    Low
                  </option>
                  <option value="medium" key="medium">
                    Medium
                  </option>
                  <option value="high" key="high">
                    High
                  </option>
                </select>
              </div>

              <div className="mb-2 col-md-3">
                <label className="form-label" htmlFor="status">
                  Status
                </label>
                <select
                  className="form-control"
                  id="sStatus"
                  name="status"
                  onChange={handleChange}
                  value={formData.status !== null ? formData.status : ""}
                >
                  <option value="1" key="1">
                    Active
                  </option>
                  <option value="0" key="0">
                    In-Active
                  </option>
                </select>
              </div>

              <div className="col-md-12">
                <div className="mb-2">
                  <label className="form-label" htmlFor="description">
                    Description
                  </label>
                  <CKEditor
                    editor={ClassicEditor}
                    onChange={handleEditorChange}
                    name="description"
                    id="description"
                    data={formData.description}
                  />
                </div>
              </div>

              <div className="col-md-12">
                <label className="form-label" htmlFor="description">
                  Attachments
                </label>
                <div
                  className="dropzone dz-clickable p-0 custom-file-upload-container position-relative"
                  id="resume"
                >
                  <div className="dz-message needsclick">
                    <i className="h3 text-muted dripicons-cloud-upload" />
                    <h4>Drop or Click to Upload New Resume</h4>
                  </div>

                  <input
                    type="file"
                    name="file_name"
                    id="file_name"
                    onChange={handleFileChange}
                    accept="*"
                  />
                </div>
                {/* Preview */}
                <div className="dropzone-previews mt-3" id="file-previews" />

                {loading ? (
                  <p>Loading...</p>
                ) : (
                  <>
                    {noRecordsFound ? (
                      <p>No attatchment uploaded yet</p>
                    ) : (
                      <>
                        {Object.entries(attachments).map(([key, value]) => (
                          <div className="card mb-0 mt-2 shadow-none border" key={value.id}>
                            <div className="p-2">
                              <div className="row align-items-center">
                                <div className="col-auto">
                                  <div className="avatar-sm">
                                    <span className="avatar-title badge-soft-primary text-primary rounded">
                                      {value.file_type}
                                    </span>
                                  </div>
                                </div>
                                <div className="col ps-0">
                                  <span className="text-muted fw-bold">{value.title}</span>
                                  <p className="mb-0 font-12">
                                    {value.file_size} |{" "}
                                    {formatDate(value.created_at, "MMM DD, YYYY")}
                                    {", "}
                                    {formatDate(value.created_at, "hh:mm A")}
                                  </p>
                                </div>
                                <div className="col-auto">
                                  <a
                                    href={value.dir_path}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn btn-link font-16 text-muted"
                                    data-tooltip-id="mt-4"
                                    data-tooltip-content="Download Attatchment"
                                  >
                                    <i className="dripicons-download" />
                                  </a>
                                  <Tooltip id="mt-4"></Tooltip>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleAddModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {isLoading === true && <Spinner />}
    </Layout>
  );
};

export default Scheduling;
