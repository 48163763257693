import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Layout from "../../../Layout/Index";
import PageTitle from "../../../Layout/PageTitle";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import * as api from "../../../../services/ApiService";
import * as tokenUtils from "../../../../utils/tokenUtils";
import Spinner from "../../../Loader/Spinner";

const SchedulingCalendar = forwardRef((props, ref) => {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const [isLoading, setIsLoading] = useState(false);
  const localizer = momentLocalizer(moment);

  useImperativeHandle(ref, () => ({
    scheduleChildFunction() {
      fetchSchedules();
    },
  }));

  const [myEventsList, setMyEventsList] = useState([]);

  // Function to convert date and time strings to JavaScript Date objects
  function parseEvent(response) {
    const [startYear, startMonth, startDay] = response.start_date.split("-").map(Number);
    const [startHour, startMinute, startSecond] = response.start_time.split(":").map(Number);
    const [endYear, endMonth, endDay] = response.end_date.split("-").map(Number);
    const [endHour, endMinute, endSecond] = response.end_time.split(":").map(Number);

    return {
      title: response.title,
      start: new Date(startYear, startMonth - 1, startDay, startHour, startMinute, startSecond),
      end: new Date(endYear, endMonth - 1, endDay, endHour, endMinute, endSecond),
    };
  }

  const fetchSchedules = async () => {
    setIsLoading(true);
    try {
      const param = {
        user_id: user.id,
      };
      const result = await api.postMethod(param, "admin/get-schedules");
      if (result.status) {
        if (result.data.length) {
          const newEvents = result.data.map(parseEvent);
          setMyEventsList(newEvents);
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchSchedules();
  }, []);

  const colors = ["#00B8FF"];

  const eventPropGetter = (event) => {
    const randomColor = colors[Math.floor(Math.random() * colors.length)];
    return { style: { backgroundColor: randomColor } };
  };

  return (
    <>
      <Calendar
        localizer={localizer}
        events={myEventsList}
        startAccessor="start"
        endAccessor="end"
        style={{ height: 500 }}
        defaultView="month"
        eventPropGetter={eventPropGetter}
      />
      {isLoading === true && <Spinner />}
    </>
  );
});

export default SchedulingCalendar;
