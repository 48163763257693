import { create } from 'zustand';
import { apiRequest } from "../services/ApiRequest";
import { toast } from 'react-toastify';

const initialSubscription = {
  plan_id: null,
  plan_type: null,
  status: null,
  permissions: [], // Ensure permissions is always an array
};

const useSubscriptionStore = create((set) => ({
  subscription: initialSubscription,
  loading: false,
  error: null,

  fetchSubscription: async () => {
    set({ loading: true, error: null }); // Start loading
    try {
      const response = await apiRequest("client/subscription", "GET");
      if (response.success) {
        set({ subscription: response.data, loading: false }); // Use `response.data` to update the state
      } else {
        if(response.status === 404){
          toast.info("Get a subscription");
          set({
            subscription: initialSubscription, // Reset subscription to the initial state
            loading: false,
          });
        } else {
          set({
            error: response.message || "Failed to fetch subscription",
            subscription: initialSubscription, // Reset subscription to the initial state
            loading: false,
          });
        }
      }
    } catch (err) {
      set({
        error: err.message || "Network error occurred. Please check your connection.",
        subscription: initialSubscription, // Reset subscription to the initial state
        loading: false,
      });
      console.error("Fetch Subscription Error:", err);
    }
  },

  // New method to trigger a fresh fetch
  refetchSubscription: () => {
    set({ subscription: initialSubscription, loading: true, error: null }); // Reset state and start loading
    // Call fetchSubscription to re-fetch data
    setTimeout(() => {
      useSubscriptionStore.getState().fetchSubscription(); // Delayed call to fetchSubscription
    }, 0); // Adding a slight delay to avoid immediate state clash
  },

  clearPermissions: () =>
    set({
      subscription: { ...initialSubscription, permissions: [] },
      error: null,
      loading: false,
    }),
}));

export default useSubscriptionStore;