import React, { useState, useEffect } from "react";
import Header from "../../../Layout/Header";
import Footer from "../../../Layout/Footer";
import Sidebar from "../../../Layout/Sidebar";
import ThemeSettings from "../../../Layout/ThemeSettings";

import { Link, useLocation } from "react-router-dom";
import * as tokenUtils from "../../../../utils/tokenUtils";
import Job_Request_details from "./Tabs/Job_Request_details";
import Job_Oportunities from "./Tabs/Job_Oportunities";
import Job_Post_Match from "./Tabs/Job_Post_Match";
function JobUserDetails({ refreshPage }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState("job_request");

  useEffect(() => {
    if (location.state?.tab) {
      setSelectedTab(location.state.tab);
    }
  }, [location.state]);

  const [skills, setSkills] = useState([]);
  const [states, setStates] = useState([]);
  const [professions, setProfessions] = useState([]);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [shifts, setShifts] = useState([]);

  //console.log("skills", skills);

  // Tab select event
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              {/* <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">All Subscription</li>
                      </ol>
                    </div>
                    <h4 className="page-title">All Subscription</h4>
                  </div>
                </div>
              </div> */}
              {/* end page title */}
              <div className="row">
                <div className="col-lg-12 col-xl-12">

                  <div className="card p-2">
                  <div>
                    <h4>
                        Name
                    </h4>
                    <p>
                        Posted on : date
                    </p>
                  </div>
                  </div>
                  <div className="card">
                    <div className="card-body">
                      <div className="row mb=3">
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "job_request"
                                ? "btn-primary"
                                : "btn-light")
                            }
                            onClick={() => handleTabSelect("job_request")}
                          >
                            Job Request Details
                          </button>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "job_opportunities"
                                ? "btn-primary"
                                : "btn-light")
                            }
                            onClick={() => handleTabSelect("job_opportunities")}
                          >
                           Job Opportunities Sent
                          </button>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "job_post_match"
                                ? "btn-primary"
                                : "btn-light")
                            }
                            onClick={() => handleTabSelect("job_post_match")}
                          >
                           Job Post Matches
                          </button>
                        </div>
                        {/* 
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "skills" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("skills")}
                          >
                            Skills
                          </button>
                        </div>
                        */}
                      </div>
                    </div>
                  </div>

                  <div className="card">
                
                    <div className="card-body">
                      {selectedTab === "job_request" &&
                        
                           <Job_Request_details /> 
                        }
                      {selectedTab === "job_opportunities" &&
                        <Job_Oportunities/>
                        }
                      {selectedTab === "job_post_match" &&
                        <Job_Post_Match/>
                        }

                    </div>
                  </div>

                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />
    </>
  );
}

export default JobUserDetails;
