import React from 'react'
import { flushSync } from 'react-dom'

const MoniterSubscription = () => {
  return (<>

 
    <div style={{width:"100%", display:"flex",justifyContent:"space-around"}}>
    
    <div class="card pt-2" style={{width:"25%",display:"flex",alignItems:"center", boxShadow:"1px 1px 1px 1px #00000073"}}>
    <i className="fas fa-podcast" style={{fontSize:"5rem"}}/>
    <strong className="card-title mt-2">Tier 1</strong>

  <div class="card-body">
  <div >
          
            <strong>Amount :  </strong>
          
         $90
        </div>
        <div >
          
            <strong>Total Users : </strong>
          
          45
        </div>
  </div>
</div>
    <div class="card pt-2" style={{width:"25%",display:"flex",alignItems:"center", boxShadow:"1px 1px 1px 1px #00000073"}}>
    <i className="fe-award" style={{fontSize:"5rem"}}/>
    <strong className="card-title mt-2">Tier 2</strong>

  <div class="card-body">
  <div >
          
            <strong>Amount :  </strong>
          
         $190
        </div>
        <div >
          
            <strong>Total Users : </strong>
          
          50
        </div>
  </div>
</div>
    </div>
    <div style={{width:"100%", display:"flex",justifyContent:"space-around"}}>
    
    <div class="card pt-2" style={{width:"25%",display:"flex",alignItems:"center", boxShadow:"1px 1px 1px 1px #00000073"}}>
    <i className="fe-aperture" style={{fontSize:"5rem"}}/>
    <strong className="card-title mt-2">Tier 3</strong>

  <div class="card-body">
  <div >
          
            <strong>Amount :  </strong>
          
         $400
        </div>
        <div >
          
            <strong>Total Users : </strong>
          
          450
        </div>
  </div>
</div>
    <div class="card pt-2" style={{width:"25%",display:"flex",alignItems:"center", boxShadow:"1px 1px 1px 1px #00000073"}}>
    <i className="fas fa-paper-plane" style={{fontSize:"5rem"}}/>
    <strong className="card-title mt-2">Tier 4</strong>

  <div class="card-body">
  <div >
          
            <strong>Amount :  </strong>
          
         $1900
        </div>
        <div >
          
            <strong>Total Users : </strong>
          
          400
        </div>
  </div>
</div>
    </div>
    </>
  )
}

export default MoniterSubscription
