import React, { useState, useEffect } from "react";
import * as api from "../../../../../services/ApiService";
import * as tokenUtils from "../../../../../utils/tokenUtils";
import { formatDate } from "../../../../../utils/dateFormat";

function PersonalInfo({ profileInfo }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  return (
    <>
      <div className="row">
        {profileInfo.bio && (
          <div className="col-md-12">
            <h4 className="font-13 text-capitalize mb-1 fw-bold">About {profileInfo.name}</h4>
            <p className="mb-3">{profileInfo.bio}</p>
          </div>
        )}

        <div className="col-md-4">
          <h4 className="font-13 text-capitalize mb-1 fw-bold">Email :</h4>
          <p className="mb-3">{profileInfo.email}</p>
        </div>

        <div className="col-md-4">
          <h4 className="font-13 text-capitalize mb-1 fw-bold">Phone :</h4>
          <p className="mb-3">{profileInfo.phone}</p>
        </div>

        <div className="col-md-4">
          <h4 className="font-13 text-capitalize mb-1 fw-bold">Address Line 1 :</h4>
          <p className="mb-3">{profileInfo.address_line1 ? profileInfo.address_line1 : "-"}</p>
        </div>

        <div className="col-md-4">
          <h4 className="font-13 text-capitalize mb-1 fw-bold">Address Line 2 :</h4>
          <p className="mb-3">{profileInfo.address_line2 ? profileInfo.address_line2 : "-"}</p>
        </div>

        <div className="col-md-4">
          <h4 className="font-13 text-capitalize mb-1 fw-bold">State :</h4>
          <p className="mb-3">{profileInfo.state_name ? profileInfo.state_name : "-"}</p>
        </div>

        <div className="col-md-4">
          <h4 className="font-13 text-capitalize mb-1 fw-bold">City :</h4>
          <p className="mb-3">{profileInfo.city_name ? profileInfo.city_name : "-"}</p>
        </div>

        {/* 
        <div className="col-md-4">
          <h4 className="font-13 text-capitalize mb-1 fw-bold">Date of Birth :</h4>
          <p className="mb-3">
            {profileInfo.dob ? formatDate(profileInfo.dob, "MMM DD, YYYY") : "-"}
          </p>
        </div>
        */}

        <div className="col-md-4">
          <h4 className="font-13 text-capitalize mb-1 fw-bold">Total Experience :</h4>
          <p className="mb-3">
            {profileInfo.total_experience ? profileInfo.total_experience : "-"}
          </p>
        </div>
      </div>
    </>
  );
}

export default PersonalInfo;
