import * as toast from "../utils/toastUtils";
import axios from "axios";
const token = localStorage.getItem("token");

/*  Get Method  */
export async function getMethod(endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  const response = await fetch(url);

  if (!response.ok) {
    //throw new Error("Failed to fetch data");
    toast.error("Something went wrong");
    return false;
  }

  const res = await response.json();
  return res;
  /*if (res.status) {
    return res.data;
  } else {
    toast.error(res.message);
    return false;
  }*/
}

/*  Post Method  */
export async function postFileMethod(data, endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  // Send form data to the server
  const response = await axios.post(url, data, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
  });

  let res = response.data;
  return res;
  /*(if (res.status) {
    return true;
  } else {
    toast.error(res.message);
    return false;
  }*/
}

export async function postMethod(data, endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    //throw new Error("Failed to fetch data");
    toast.error("Something went wrong");
    return false;
  }

  const res = await response.json();
  return res;

  /*if (res.status) {
    return (res?.data) ? res?.data : true;
  } else {
    toast.error(res.message);
    return false;
  }*/
}

/*  Request Method  */
export async function requestMethod(data, endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    //throw new Error("Failed to fetch data");
    toast.error("Something went wrong");
    return false;
  }

  const res = await response.json();
  return res;
}

export async function getPlanMethod( endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  const response = await fetch(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    //throw new Error("Failed to fetch data");
    toast.error("Something went wrong");
    return false;
  }

  const res = await response.json();
  return res;

  /*if (res.status) {
    return (res?.data) ? res?.data : true;
  } else {
    toast.error(res.message);
    return false;
  }*/
}