import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);

const usePrompt = () => {
  const confirm = async (options) => {
    const result = await MySwal.fire({
      title: options?.title || "Are you sure you want to perform this action?",
      text: options?.text || "",
      icon: options?.icon || "warning",
      showCancelButton: true,
      confirmButtonColor: options?.confirmButtonColor || "#3085d6",
      cancelButtonColor: options?.cancelButtonColor || "#d33",
      confirmButtonText: options?.confirmButtonText || "Yes, do it!",
      cancelButtonText: options?.cancelButtonText || "No, cancel!",
    });

    return result;
  };

  return { confirm };
};

export default usePrompt;
