import React, { useEffect, useState } from "react";
import Layout from "../Layout/Index";
import ThemeSettings from "../Layout/ThemeSettings";
import { Link } from "react-router-dom";
import PageTitle from "../Layout/PageTitle";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { currentRole } from "../Layout/HelmetComponent";
import { Modal } from "react-bootstrap";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import DateRangePickerComponent from "../../utils/DateRangePickerComponent";

import * as api from "../../services/ApiService";
import * as tokenUtils from "../../utils/tokenUtils";
import { toast } from "react-toastify";
import Spinner from "../Loader/Spinner";
import ScheduleInterviewModal from "./Interviews/ScheduleInterviewModal";
import CreateTaskModal from "./Tasks/CreateTaskModal";
import useConfirm from "../../hooks/useConfirm";

const localizer = momentLocalizer(moment);

function Calender() {
  let user = tokenUtils.getTokenData();
  const [showModal, setShowModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [apiLoad, setApiLoad] = useState(false);
  const [state, setState] = useState({
    startTime: "",
    endTime: "",
    startDate: new Date(),
    endDate: new Date(),
    title: "",
    type: "call",
    assignee_id: "",
    importance: "",
    description: "",
    status: 1,
  });
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [assignedUsers, setAssignedUsers] = useState([]);
  const [errors, setErrors] = useState({});
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [detailModal, setDetailsModal] = useState(false);
  const toggleDetailsModal = () => setDetailsModal(!detailModal);

  const [interviewModal, setInterviewModal] = useState(false);
  const toggleInterviewModal = () => {
    setInterviewModal(!interviewModal);
    interviewModal && fetchEventsList();
  };

  const [taskModal, setTaskModal] = useState(false);
  const toggleTaskModal = () => {
    setTaskModal(!taskModal);
    fetchEventsList();
  };

  const fetchAssignedUsers = async () => {
    try {
      const apiData = await api.getMethod("get-users/4");

      setAssignedUsers(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchEventsList = async () => {
    setApiLoad(true);
    try {
      const apiData = await api.postMethod({ user_id: user.id }, "client/get-events");
      let newData = [];
      let i = 0;
      while (i < apiData.data.length) {
        let ele = apiData.data[i];
        ele = {
          ...ele,
          start: new Date(`${ele.start_date}T${ele.start_time}`),
          end: new Date(`${ele.end_date}T${ele.end_time}`),
        };
        newData.push(ele);
        i++;
      }

      setMyEventsList(newData);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setApiLoad(false);
    }
  };

  useEffect(() => {
    fetchAssignedUsers();
    fetchEventsList();
  }, []);
  const [myEventsList, setMyEventsList] = useState([]);

  const eventColors = {
    call: "#00B8FF",
    meeting: "#1ABC9C",
    task: "#ff5712",
    email: "#F7B94B",
    interview: "#8E44AD",
  };

  const eventPropGetter = (event) => {
    const backgroundColor = eventColors[event.type] || "#FFFFFF";
    return { style: { backgroundColor } };
  };

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Calendar", link: null },
  ];

  const generateTimeOptions = () => {
    const times = [];
    const startOfDay = moment().startOf("day");

    for (let i = 0; i < 24 * 4; i++) {
      times.push(
        startOfDay
          .clone()
          .add(i * 15, "minutes")
          .format("HH:mm")
      );
    }

    return times;
  };

  const timeOptions = generateTimeOptions();

  const handleStartTimeChange = (e) => {
    setErrors({ ...errors, startTime: "" });
    setState({ ...state, startTime: e.target.value, endTime: "" });
  };

  const handleEndTimeChange = (e) => {
    setErrors({ ...errors, endTime: "" });
    setState({ ...state, endTime: e.target.value });
  };

  const filteredEndTimeOptions = timeOptions.filter(
    (time) => !state.startTime || moment(time, "HH:mm").isAfter(moment(state.startTime, "HH:mm"))
  );

  const handleDateChange = (ranges) => {
    setState({ ...state, startDate: ranges.startDate, endDate: ranges.endDate });
    if (
      moment(ranges.startDate).format("YYYY-MM-DD") !== moment(ranges.endDate).format("YYYY-MM-DD")
    ) {
      setShowDatePicker(false);
    }
  };

  const onInputChange = (e) => {
    const { name, value } = e.target;
    if (name === "type") {
      if (value === "interview") {
        setShowModal(false);
        setInterviewModal(true);
      } else if (value === "task") {
        setShowModal(false);
        setTaskModal(true);
      }
    }
    setErrors({ ...errors, [name]: "" });
    setState({ ...state, [name]: value });
  };

  const validate = () => {
    let tempErrors = {};

    if (!state.startTime) tempErrors.startTime = "Start time is required.";
    if (!state.endTime) tempErrors.endTime = "End time is required.";
    if (!state.title) tempErrors.title = "Title is required.";
    if (!state.type) tempErrors.type = "Type is required.";
    if (!state.assignee_id) tempErrors.assignee_id = "Assigned to is required.";
    if (!state.importance) tempErrors.importance = "Importance is required.";
    if (!state.description) tempErrors.description = "Description is required.";

    setErrors(tempErrors);

    return Object.keys(tempErrors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      setIsLoading(true);
      try {
        let params = {
          user_id: user.id,
          title: state.title,
          type: state.type,
          start_date: moment(state.startDate).format("YYYY-MM-DD"),
          end_date: moment(state.endDate).format("YYYY-MM-DD"),
          start_time: state.startTime,
          end_time: state.endTime,
          assignee_id: state.assignee_id,
          importance: state.importance,
          description: state.description,
          status: state.status,
        };
        let result = await api.postMethod(params, "client/update-event");
        if (result.status) {
          fetchEventsList();
          toast.success(result.message);
          setShowModal(false);
          setState({
            startTime: "",
            endTime: "",
            startDate: new Date(),
            endDate: new Date(),
            title: "",
            type: "call",
            assignee_id: "",
            importance: "",
            description: "",
            status: 1,
          });
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const closeModel = () => {
    setShowModal(false);
    setState({
      startTime: "",
      endTime: "",
      startDate: new Date(),
      endDate: new Date(),
      title: "",
      type: "call",
      assignee_id: "",
      importance: "",
      description: "",
      status: 1,
    });
  };

  const { confirm } = useConfirm();
  const deleteEvent = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      toggleDetailsModal();
      setApiLoad(true);
      record.user_id = user.id;
      const result = await api.postMethod(record, "client/delete-event");
      if (result.status) {
        fetchEventsList();
        toast.success(result.message);
      }
    }
  };

  return (
    <Layout>
      <div className="container-fluid">
        {/* start page title */}
        <PageTitle title="Calendar" breadcrumbItems={breadcrumbItems} />
        {/* end page title */}
        <div className="row">
          <div className="col-12">
            <div className="card custom-box-shadow">
              <div className="card-header border-bottom">
                <span
                  className="btn btn-defalt btn-sm text-white me-1"
                  style={{ backgroundColor: "#00B8FF", pointerEvents: "none" }}
                ></span>
                : Call
                <span
                  className="btn btn-defalt btn-sm text-white me-1 ms-2"
                  style={{ backgroundColor: "#1ABC9C", pointerEvents: "none" }}
                ></span>
                : Meeting
                <span
                  className="btn btn-defalt btn-sm text-white me-1 ms-2"
                  style={{ backgroundColor: "#ff5712", pointerEvents: "none" }}
                ></span>
                : Task
                <span
                  className="btn btn-defalt btn-sm text-white me-1 ms-2"
                  style={{ backgroundColor: "#F7B94B", pointerEvents: "none" }}
                ></span>
                : Email
                <span
                  className="btn btn-defalt btn-sm text-white me-1 ms-2"
                  style={{ backgroundColor: "#8E44AD", pointerEvents: "none" }}
                ></span>
                : Follow Up
                <button
                  onClick={() => setShowModal(true)}
                  className="btn font-16 btn-info btn-sm float-end"
                  id="btn-new-event"
                >
                  <i className="mdi mdi-plus-circle-outline" /> Create New Event
                </button>
              </div>
              <div className="card-body">
                <div className="row">
                  {/* end col*/}
                  <div className="col-lg-12 mt-1">
                    <Calendar
                      localizer={localizer}
                      events={myEventsList}
                      startAccessor="start"
                      endAccessor="end"
                      style={{ height: 500 }}
                      defaultView="month"
                      eventPropGetter={eventPropGetter}
                      selectable={true}
                      onSelectEvent={(e) => {
                        setSelectedEvent(e);
                        toggleDetailsModal();
                      }}
                    />
                  </div>
                  {/* end col */}
                </div>
                {/* end row */}
              </div>
              {/* end card body*/}
            </div>

            <Modal
              show={detailModal}
              size="md"
              onHide={() => toggleDetailsModal()}
              centered
              backdrop="static"
            >
              <Modal.Header closeButton>
                <Modal.Title className="my-0">View Event Details</Modal.Title>
              </Modal.Header>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-6 mb-2">
                    <strong>Event Type</strong>
                  </div>
                  <div className="col-md-6 mb-2">
                    {selectedEvent?.type == "interview"
                      ? "Follow Up"
                      : selectedEvent?.type.charAt(0).toUpperCase() + selectedEvent?.type.slice(1)}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-2">
                    <strong>Title</strong>
                  </div>
                  <div className="col-md-6 mb-2">{selectedEvent?.title}</div>
                </div>

                {selectedEvent?.type == "interview" && (
                  <>
                    {/* 
                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <strong>Scheduled By </strong>
                      </div>
                      <div className="col-md-6 mb-2">{selectedEvent?.creator_name}</div>
                    </div>
                    */}

                    <div className="row">
                      <div className="col-md-6 mb-2">
                        <strong>Assignee </strong>
                      </div>
                      <div className="col-md-6 mb-2">
                        <Link
                          to={`/client/user-profile/` + selectedEvent.assignee_unique_id}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {selectedEvent.assignee_name}
                        </Link>
                      </div>
                    </div>
                  </>
                )}

                <div className="row">
                  <div className="col-md-6 mb-2">
                    <strong>Start Date</strong>
                  </div>
                  <div className="col-md-6 mb-2">{selectedEvent?.start_date}</div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-2">
                    <strong>End Date</strong>
                  </div>
                  <div className="col-md-6 mb-2">{selectedEvent?.end_date}</div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-2">
                    <strong>Start Time</strong>
                  </div>
                  <div className="col-md-6 mb-2">{selectedEvent?.start_time}</div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-2">
                    <strong>End Time</strong>
                  </div>
                  <div className="col-md-6 mb-2">{selectedEvent?.end_time}</div>
                </div>
              </div>
              <div className="modal-footer">
                <button className="btn btn-danger" onClick={() => deleteEvent(selectedEvent)}>
                  Delete Event
                </button>
              </div>
            </Modal>
            {/* end card */}
            {/* Add New Event MODAL */}
            <Modal show={showModal} size="lg" onHide={() => closeModel()} centered>
              <Modal.Header closeButton>
                <Modal.Title>Add Event</Modal.Title>
              </Modal.Header>
              <div className="modal-body">
                <div className="row">
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label className="control-label">Title</label>
                      <input
                        type="text"
                        name="title"
                        value={state.title}
                        onChange={onInputChange}
                        className="form-control"
                      />
                      {errors?.title && <div className="error text-danger">{errors?.title}</div>}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <div
                        className="btn-group"
                        role="group"
                        aria-label="Basic radio toggle button group"
                      >
                        <input
                          type="radio"
                          className="btn-check"
                          name="type"
                          value={"call"}
                          defaultValue={state.type === "call"}
                          onChange={onInputChange}
                          id="btnradio1"
                          autoComplete="off"
                          defaultChecked
                        />
                        <label className="btn btn-outline-secondary" htmlFor="btnradio1">
                          <i className="fas fa-phone-alt" /> Call
                        </label>
                        <input
                          type="radio"
                          className="btn-check"
                          name="type"
                          value={"meeting"}
                          defaultValue={state.type === "meeting"}
                          onChange={onInputChange}
                          id="btnradio2"
                          autoComplete="off"
                        />
                        <label className="btn btn-outline-secondary" htmlFor="btnradio2">
                          <i className="fas fa-building" /> Meeting
                        </label>
                        <input
                          type="radio"
                          className="btn-check"
                          name="type"
                          value={"task"}
                          defaultValue={state.type === "task"}
                          onChange={onInputChange}
                          id="btnradio3"
                          autoComplete="off"
                        />
                        <label className="btn btn-outline-secondary" htmlFor="btnradio3">
                          <i className="fas fa-clipboard-list" /> Task
                        </label>
                        <input
                          type="radio"
                          className="btn-check"
                          name="type"
                          value={"email"}
                          defaultValue={state.type === "email"}
                          onChange={onInputChange}
                          id="btnradio4"
                          autoComplete="off"
                        />
                        <label className="btn btn-outline-secondary" htmlFor="btnradio4">
                          <i className="fas fa-envelope" /> Email
                        </label>
                        <input
                          type="radio"
                          className="btn-check"
                          name="type"
                          value={"interview"}
                          defaultValue={state.type === "interview"}
                          onChange={onInputChange}
                          id="btnradio5"
                          autoComplete="off"
                        />
                        <label className="btn btn-outline-secondary" htmlFor="btnradio5">
                          <i className="fas fa-podcast" /> Follow Up
                        </label>
                      </div>
                      {errors?.type && <div className="error text-danger">{errors?.type}</div>}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="field-7" className="control-label">
                        Date
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        value={
                          moment(state.startDate).format("MM-DD-YYYY") +
                          " to " +
                          moment(state.endDate).format("MM-DD-YYYY")
                        }
                        onFocus={() => setShowDatePicker(true)}
                      />
                      {showDatePicker && (
                        <DateRangePickerComponent
                          startDate={state.startDate}
                          endDate={state.endDate}
                          onDateChange={handleDateChange}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="start-time" className="control-label">
                        Start Time
                      </label>
                      <select
                        className="form-control"
                        id="start-time"
                        value={state.startTime}
                        onChange={handleStartTimeChange}
                      >
                        <option value="">-</option>
                        {timeOptions.map((time, index) => (
                          <option key={index} value={time}>
                            {time}
                          </option>
                        ))}
                      </select>
                      {errors?.startTime && (
                        <div className="error text-danger">{errors?.startTime}</div>
                      )}
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="mb-3">
                      <label htmlFor="end-time" className="control-label">
                        End Time
                      </label>
                      <select
                        className="form-control"
                        id="end-time"
                        value={state.endTime}
                        onChange={handleEndTimeChange}
                      >
                        <option value="">-</option>
                        {filteredEndTimeOptions.map((time, index) => (
                          <option key={index} value={time}>
                            {time}
                          </option>
                        ))}
                      </select>
                      {errors?.endTime && (
                        <div className="error text-danger">{errors?.endTime}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label htmlFor="field-7" className="control-label">
                        {" "}
                        Assigned To
                      </label>
                      <select
                        className="form-control select2-multiple"
                        value={state.assignee_id}
                        name="assignee_id"
                        onChange={onInputChange}
                        data-toggle="select2"
                        data-width="100%"
                        data-placeholder="Choose ..."
                      >
                        <option value>-</option>
                        {assignedUsers.map((user) => (
                          <option key={user.id} value={user.id}>
                            {user.name}
                          </option>
                        ))}
                      </select>
                      {errors?.assignee_id && (
                        <div className="error text-danger">{errors?.assignee_id}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label htmlFor="field-7" className="control-label">
                        {" "}
                        Importance
                      </label>
                      <select
                        className="form-control"
                        value={state.importance}
                        name="importance"
                        onChange={onInputChange}
                      >
                        <option value>-</option>
                        <option value="low">Low</option>
                        <option value="medium">Medium</option>
                        <option value="high">High</option>
                      </select>
                      {errors?.importance && (
                        <div className="error text-danger">{errors?.importance}</div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="mb-3">
                      <label htmlFor="field-7" className="control-label">
                        {" "}
                        Description
                      </label>
                      <CKEditor
                        editor={ClassicEditor}
                        onChange={(event, editor) => {
                          const data = editor.getData();

                          setErrors({ ...errors, description: "" });
                          setState({ ...state, description: data });
                        }}
                      />
                      {errors?.description && (
                        <div className="error text-danger">{errors?.description}</div>
                      )}
                    </div>
                  </div>
                  {/* 
                  <div className="col-md-12">
                    <div className="mb-0">
                      <label htmlFor="field-7" className="control-label">
                        {" "}
                        Attachments
                      </label>
                      <form
                        action="/"
                        method="post"
                        className="dropzone dz-clickable"
                        id="myAwesomeDropzone"
                        data-plugin="dropzone"
                        data-previews-container="#file-previews"
                        data-upload-preview-template="#uploadPreviewTemplate"
                      >
                        <div className="dz-message needsclick">
                          <i className="h1 text-muted dripicons-cloud-upload" />
                          <h3>Drop files here or click to upload.</h3>
                        </div>
                      </form>
                    </div>
                  </div>
                  */}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-secondary waves-effect"
                  onClick={() => closeModel()}
                >
                  Close
                </button>
                <button
                  disabled={isLoading}
                  onClick={(e) => handleSubmit(e)}
                  type="button"
                  className="btn btn-success waves-effect waves-light"
                >
                  Save <i className="fa fa-check" />
                </button>
              </div>
            </Modal>
            {/* end modal*/}
          </div>
          <ScheduleInterviewModal
            editInterViewData={null}
            uploadAddOpen={interviewModal}
            toggleAddModal={toggleInterviewModal}
          />
          <CreateTaskModal
            editData={null}
            uploadAddOpen={taskModal}
            toggleAddModal={toggleTaskModal}
          />

          {/* end col */}
        </div>
        {apiLoad && <Spinner />}
        {/* end row */}
      </div>
    </Layout>
  );
}

export default Calender;
