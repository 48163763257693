import React, { useState } from 'react'
import { Button, Dropdown } from 'react-bootstrap'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import { Tooltip } from 'react-tooltip'

const Job_Post_Match = () => {
  const navigate = useNavigate()
    const [loading,setLoading] = useState(false)
    const [noRecordsFound,setNoRecordsFound]= useState(false)
  return (
    <div>
    <div className='d-flex justify-content-end mb-2 btn btn-submit'>
      <Button onClick={()=>navigate("/client/post_job_request")}>Create Job Opportunity</Button>
    </div>
      <div className="table-responsive " style={{height:"20vw"}}>
                        <table className="table table-centered mb-0"  style={{height:"5vw"}}>
                          {/* table-nowrap */}
                          <thead className="table-light">
                            <tr>
                         
                              <th>&nbsp;</th>
                              <th width="10%">Job Title</th>
                              <th>Specialty</th>
                              <th>Status</th>
                              {/* 
                              <th>Boost</th>
*/}
                              <th>Date Posted</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr>
                                <td colSpan="8">Loading...</td>
                              </tr>
                            ) : (
                              <>
                                {noRecordsFound ? (
                                  <tr>
                                    <td colSpan="8">No records found</td>
                                  </tr>
                                ) : (
                                  <>
                                    {/* {jobs &&
                                      Object.entries(jobs).map(([key, value]) => ( */}
                                        <tr
                                        //  key={value.id}
                                         >
                                   
                                          <td>
                                            <span
                                            //   className={
                                            //     value.is_starred == 1
                                            //       ? "star-toggle fas fa-star text-warning"
                                            //       : "star-toggle far fa-star"
                                            //   }
                                            //   onClick={() =>
                                            //     toggleStarredJob(value.id, value.is_starred)
                                            //   }
                                            ></span>
                                          </td>
                                          <td>
                                            <Link
                                            //   to={`/` + urlSlug + `/job/` + value.unique_id}
                                              className="job-title"
                                            //   data-tooltip-id={`mt-` + value.unique_id}
                                            //   data-tooltip-content={value.title}
                                            >
                                              {/* {value.title} */} Title
                                            </Link>
                                            <Tooltip 
                                            // id={`mt-` + value.unique_id}
                                            ></Tooltip>

                                            <p className="mb-0 text-muted">
                                              {/* <small>(#{value.unique_id})</small> */}
                                            </p>
                                            {/* {(value.state_code !== null ||
                                              value.city_name !== null) && (
                                              <p className="mb-0 ">
                                                <small>
                                                  <i className="fa fa-map-marker-alt me-1"></i>
                                                  {value.state_code !== null &&
                                                  value.city_name !== null
                                                    ? value.city_name + ", " + value.state_code
                                                    : value.state_code !== null
                                                    ? value.state_code
                                                    : value.city_name !== null
                                                    ? value.city_name
                                                    : ""}
                                                </small>
                                              </p>
                                            )} */}
                                          </td>
                                      
                                          <td>
                                            {/* {value.specialty !== null ? value.specialty : "-"}
                                             */}
                                             Specialities
                                          </td>
                                          <td>
                                            <h5>
                                              {/* {value.status == 1 && (
                                                <label className="badge badge-soft-success">
                                                  Active
                                                </label>
                                              )}
                                              {value.status == 2 && (
                                                <label className="badge badge-soft-danger">
                                                  In-Active
                                                </label>
                                              )}
                                              {value.status == 0 && (
                                                <label className="badge badge-soft-secondary">
                                                  Draft
                                                </label>
                                              )}
                                              {value.status == 6 && (
                                                <label className="badge badge-soft-danger">
                                                  Expired
                                                </label>
                                              )} */}
                                              Active
                                            </h5>
                                          </td>

                                          {/* 
                                          <td>
                                            <button
                                              type="button"
                                              onClick={() => handleEdit(value)}
                                              className={
                                                value.is_boosted == 1
                                                  ? "btn btn-primary btn-sm waves-effect waves-light mb-2 mr-2"
                                                  : "btn btn-secondary btn-sm waves-effect waves-light mb-2 mr-2"
                                              }
                                            >
                                              Boost
                                            </button>
                                          </td>
                                          */}

                                          <td>
                                            {/* {formatDate(value.created_at, "MMM DD, YYYY")}{" "} */}
                                            <small className="text-muted">
                                              {/* {formatDate(value.created_at, "hh:mm A")} */}
                                            </small>
                                          </td>
                                          <td>
                                          <Dropdown className="">
                                      <Dropdown.Toggle
                                        variant="default"
                                        // id={`dropdown-basic-` + value.job_id}
                                        // key={`dropdown-basic-` + value.job_id}
                                        className="p-0"
                                      >
                                        <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                                      </Dropdown.Toggle>

                                      <Dropdown.Menu>
                                       
                                          <Dropdown.Item >
                                            Mark as Active
                                          </Dropdown.Item>
                                        
                                        
                                          <Dropdown.Item >
                                            Mark as In-Active
                                          </Dropdown.Item>
                                       

                                        <Dropdown.Item
                                        >
                                          Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          Delete
                                        </Dropdown.Item>
                                      </Dropdown.Menu>
                                    </Dropdown>
                                          </td>
                                        </tr>
                                      {/* ))} */}
                                  </>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
    </div>
  )
}

export default Job_Post_Match
