import { React, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import * as auth from "../../../services/AuthService";
import PasswordToggle from "../../../hooks/PasswordToggle";
import Logo from "./Logo";

function ResetPassword() {
  let navigate = useNavigate();

  // Form button state
  const [formBtn, setformBtn] = useState({
    label: "Reset Password",
    disabled: false,
  });

  // Use useState hook to initialize FormData variables with empty values
  const [formData, setformData] = useState({
    user_id: localStorage.getItem("userId"),
    code: "",
    new_pass: "",
  });

  // Password toggle state
  const [showPassword, togglePasswordVisibility] = PasswordToggle(formData, setformData);

  // Define Error state
  const [errors, setErrors] = useState({});

  // Define the validation schema
  let validationSchema = Yup.object({
    code: Yup.string().required("Verification code is required"),
    new_pass: Yup.string()
      .min(8, "Password must be at least 8 characters")
      .required("Password is required"),
  });

  // Form submit function
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // without abortEarly, it will return back on 1st field's validation occured, won't check other fields
      await validationSchema.validate(formData, { abortEarly: false });

      // Disable the btn
      setformBtn({
        label: "Please wait...",
        disabled: true,
      });

      // Call function with formData and API endpoint
      let res = await auth.resetPassword(formData, "admin/reset-password");

      // Reset the btn
      setformBtn({
        label: "Reset Password",
        disabled: false,
      });

      if (res) {
        setTimeout(() => {
          navigate("/admin/login", { replace: true });
        }, 500);
      }
    } catch (error) {
      const newErrors = {};

      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((err) => {
          newErrors[err.path] = err.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }

      setErrors(newErrors);
    }
  };

  // OnChange event on each form field, because form fields are disabled
  const handleChange = (e) => {
    //name attritube & value from the field
    const { name, value } = e.target;

    // Update the formData state
    setformData({
      ...formData,
      [name]: value,
    });

    // Validate the changed field and update errors state
    validateField(name, value);
  };

  // Function to validate form validate
  const validateField = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });
      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      // Set the error message for the current field if validation fails
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };
  document.body.classList.add("auth-fluid-pages");
  return (
    <div
      className="auth-fluid"
      style={{ backgroundPosition: "center", backgroundSize: "cover", background: "none" }}
    >
      <div className="container">
        <div className="row justify-content-center w-100 align-items-center h-100">
          <div className="bg-white col-md-8 col-lg-6 col-xl-4 pt-3 rounded shadow-lg">
            <div className="p-3">
              {/* Logo */}
              <Logo />

              {/* title*/}
              <h4 className="mt-0">Reset Password</h4>
              <p className="mb-4">Enter the reset password code and create your new password.</p>
              {/* form */}
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label htmlFor="emailaddress" className="form-label">
                    Reset Code
                  </label>
                  <input
                    className="form-control"
                    type="text"
                    id="code"
                    name="code"
                    placeholder="Enter your reset code"
                    value={formData.code}
                    onChange={handleChange}
                  />
                  {/* Validation message */}
                  {errors.code && <span className="error">{errors.code}</span>}
                </div>
                <div className="mb-3">
                  <label htmlFor="password" className="form-label">
                    New Password
                  </label>
                  <div className="input-group input-group-merge">
                    <input
                      type={showPassword ? "text" : "password"}
                      id="new_pass"
                      name="new_pass"
                      className="form-control"
                      placeholder="Enter your new password"
                      value={formData.password}
                      onChange={handleChange}
                    />
                    <div
                      className={
                        showPassword ? "show-password input-group-append" : "input-group-append"
                      }
                      data-password="false"
                    >
                      <div className="input-group-text">
                        <span className="password-eye" onClick={togglePasswordVisibility} />
                      </div>
                    </div>
                  </div>
                  {/* Validation message */}
                  {errors.new_pass && <span className="error">{errors.new_pass}</span>}
                </div>
                <div className="mb-0 text-center d-grid">
                  <button
                    className="btn btn-info btn-block"
                    type="submit"
                    disabled={formBtn.disabled}
                  >
                    {formBtn.label}
                  </button>
                </div>
              </form>
              {/* end form*/}
              {/* Footer*/}
            </div>
          </div>
        </div>
        {/* end .align-items-center.d-flex.h-100*/}
      </div>
      {/* end auth-fluid-form-box*/}
    </div>
  );
}

export default ResetPassword;
