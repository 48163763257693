import React from "react";
import { Link } from "react-router-dom";
import UserMenus from "./Menus/User-Menus";
import ClientMenus from "./Menus/Client-Menus";
import * as tokenUtils from "../../utils/tokenUtils";
import AdminMenus from "./Menus/Admin-Menus";

function Sidebar() {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  let fullName = user?.name || "";

  return (
    <div className="app-menu">
      <div className="logo-box">
        {/* Brand Logo Light */}
        <Link
          to={
            user?.role_id == 1 || user?.role_id == 7
              ? "/admin/dashboard"
              : user?.role_id == 3 || user?.role_id == 6
              ? "/client/dashboard"
              : "/dashboard"
          }
          className="logo-light"
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
            alt="logo"
            className="logo-lg"
          />
          <img
            src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
            alt="small logo"
            className="logo-sm"
          />
        </Link>
        {/* Brand Logo Dark */}
        <Link
          to={
            user?.role_id == 1 || user?.role_id == 7
              ? "/admin/dashboard"
              : user?.role_id == 3 || user?.role_id == 6
              ? "/client/dashboard"
              : "/dashboard"
          }
          className="logo-dark"
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
            alt="dark logo"
            className="logo-lg"
          />
          <img
            src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
            alt="small logo"
            className="logo-sm"
          />
        </Link>
      </div>
      <div className="scrollbar h-100">
        <div className="user-box text-center">
          <img
            src={process.env.PUBLIC_URL + "/assets/images/users/user-1.jpg"}
            alt="user-img"
            title="Mat Helme"
            className="rounded-circle avatar-md"
          />
          <div className="dropdown">
            <Link
              to="#"
              onClick={(e) => e.preventDefault()}
              className="dropdown-toggle h5 mb-1 d-block"
              data-bs-toggle="dropdown"
            >
              Geneva Kennedy
            </Link>
            <div className="dropdown-menu user-pro-dropdown">
              {/* item*/}
              <Link
                to="#"
                onClick={(e) => e.preventDefault()}
                className="dropdown-item notify-item"
              >
                <i className="fe-user me-1" />
                <span>My Account</span>
              </Link>
              {/* item*/}
              <Link
                to="#"
                onClick={(e) => e.preventDefault()}
                className="dropdown-item notify-item"
              >
                <i className="fe-settings me-1" />
                <span>Settings</span>
              </Link>
              {/* item*/}
              <Link
                to="#"
                onClick={(e) => e.preventDefault()}
                className="dropdown-item notify-item"
              >
                <i className="fe-lock me-1" />
                <span>Lock Screen</span>
              </Link>
              {/* item*/}
              <Link
                to="#"
                onClick={(e) => e.preventDefault()}
                className="dropdown-item notify-item"
              >
                <i className="fe-log-out me-1" />
                <span>Logout</span>
              </Link>
            </div>
          </div>
          <p className="text-muted mb-0">Admin</p>
        </div>
        {/*- Sidemenu */}
        {(user?.role_id == 1 || user?.role_id == 7) && <AdminMenus user={user} />}
        {(user?.role_id == 2 || user?.role_id == 3 || user?.role_id == 6) && (
          <ClientMenus user={user} />
        )}
        {(user?.role_id == 4 || user?.role_id == 5 || user?.role_id == 9) && (
          <UserMenus user={user} />
        )}

        {/* End Sidebar */}
        <div className="clearfix" />
      </div>
      {/* Sidebar -left */}
    </div>
  );
}

export default Sidebar;
