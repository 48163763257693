import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Index";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import * as api from "../../../services/ApiService";
import * as Yup from "yup";
import * as tokenUtils from "../../../utils/tokenUtils";
import PageTitle from "../../Layout/PageTitle";
import { formatDate } from "../../../utils/dateFormat";
import Spinner from "../../Loader/Spinner";

const ComplianceDocument = () => {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const [isLoading, setIsLoading] = useState(false);
  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchDocuments();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [docTypes, setDocTypes] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchDocTypes = async () => {
      try {
        const apiData = await api.getMethod("get-doc-types");
        setDocTypes(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchDocTypes();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    token: user.token,
    title: null,
    doc_type_id: null,
    file_name: null,
    file_type: null,
    expiry_date: null,
  });

  /*
  const handleDelete = async (record) => {
    record.user_id = user.id;
    record.token = user.token;

    var result = await document.deleteDocument(record, "user/delete-document");
    if (result) fetchDocuments();
  };
  */

  const [documents, setDocuments] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchDocuments = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "admin/get-documents");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);

      setDocuments(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchDocuments();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [shareModalOpen, setShareModalOpen] = useState(false);

  const [documentShareData, setDocumentShareData] = useState({});

  const toggleShareModal = () => {
    setShareModalOpen(!shareModalOpen);

    if (!shareModalOpen) {
      setDocumentShareData({
        title: null,
        doc_id: null,
        user_id: user.id,
        token: user.token,
        share_to: null,
      });

      // Reset validation errors
      setShareErrors({});
    }
  };

  const handleShareHistory = (record) => {
    // Populate formData with the values of the selected record
    setDocumentShareData({
      user_id: user.id,
      token: user.token,
      doc_id: record.id,
      title: record.title,
      share_to: null,
    });

    setShareModalOpen(true);
  };

  const [shareErrors, setShareErrors] = useState({});

  //Define the validation schema
  const validationSchemaShareHistory = Yup.object({
    title: Yup.string().required("Document is Required"),
    share_to: Yup.string().required("Share to is Required"),
  });

  //
  const submitShareHistory = async (e) => {
    e.preventDefault();

    try {
      await validationSchemaShareHistory.validate(documentShareData, { abortEarly: false });
      const result = await document.shareDocument(documentShareData, "admin/share-document");
      if (result) {
        setShareModalOpen(false);
      }
    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setShareErrors(newErrors);
    }
  };

  const handleShareChange = (e) => {
    const { name, value } = e.target;
    setDocumentShareData({ ...documentShareData, [name]: value });

    validateShareFields(name, value);
  };

  const validateShareFields = (name, value) => {
    try {
      validationSchemaShareHistory.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setShareErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setShareErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: "Compliance Files", link: "/admin/compliance-files" },
    { text: "Documents", link: null },
  ];

  return (
    <Layout>
      <PageTitle title="Documents" breadcrumbItems={breadcrumbItems} />

      {/* end page title */}
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <div className="card-body">
              <div className="row justify-content-between">
                <div className="col-auto">
                  <div className="dropdown" style={{ width: 300 }}>
                    <button
                      type="button"
                      className="btn btn-outline-light dropdown-toggle w-100 text-start"
                      onClick={handleToggleFilterDropDown}
                    >
                      Filters
                      <i className="mdi mdi-chevron-down float-end" />
                    </button>
                    <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                      <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                        <div className="mb-2">
                          <label htmlFor="keyword" className="form-label">
                            Keyword
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="keyword"
                            name="keyword"
                            value={filters.keyword}
                            onChange={handleFilterInputChange}
                          />
                        </div>

                        <div className="text-end mt-1">
                          <button
                            type="button"
                            className="btn btn-light float-start"
                            onClick={handleReset}
                          >
                            Reset Filter
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Apply Filter
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="text-lg-end my-1 my-lg-0">
                    <Link
                      to="/admin/document-share-history"
                      className="btn btn-primary waves-effect waves-light mb-2"
                    >
                      <i className="fa fa-history mr-1" /> Shared History
                    </Link>

                    <Link
                      to="/admin/document-types"
                      className="btn btn-info waves-effect waves-light mb-2 ms-1"
                    >
                      <i className="fa fa-cog mr-1" /> Document Types
                    </Link>
                  </div>
                </div>
                {/* end col*/}
              </div>
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Title</th>
                      <th>Type</th>
                      <th>Uploaded By</th>
                      <th>Uploaded On</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="5">Loading...</td>
                      </tr>
                    ) : (
                      <>
                        {noRecordsFound ? (
                          <tr>
                            <td colSpan="5">No records found</td>
                          </tr>
                        ) : (
                          <>
                            {Object.entries(documents).map(([key, value]) => (
                              <tr key={value.id}>
                                <td>{value.title}</td>
                                <td>{value.doc_type_name}</td>
                                <td>
                                  <Link
                                    to={`/admin/user-profile/` + value.uploaded_by_unique_id}
                                    className=""
                                  >
                                    {value.profile_pic_path && (
                                      <img
                                        src={value.profile_pic_path}
                                        className="rounded-circle"
                                        alt="user-img"
                                        height={32}
                                      />
                                    )}{" "}
                                    {value.uploaded_by}
                                  </Link>
                                </td>
                                <td>
                                  {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                                  <small className="text-muted">
                                    {formatDate(value.created_at, "hh:mm A")}
                                  </small>
                                </td>

                                <td>
                                  <a
                                    href={value.dir_path}
                                    download
                                    target="_blank"
                                    rel="noreferrer"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-4"
                                    data-tooltip-content="Download Document"
                                  >
                                    <i className="mdi mdi-download" />
                                  </a>
                                  <Tooltip id="mt-4"></Tooltip>

                                  {/*
                                          <button
                                            type="button"
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-1"
                                            data-tooltip-content="Share Document"
                                            onClick={() => handleShareHistory(value)}
                                          >
                                            <i className="mdi mdi-share" />
                                          </button>
                                          <Tooltip id="mt-1"></Tooltip>

                                          <button
                                            type="button"
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-3"
                                            data-tooltip-content="Edit Document"
                                            onClick={() => handleEdit(value)}
                                          >
                                            <i className="mdi mdi-pencil" />
                                          </button>
                                          <Tooltip id="mt-3"></Tooltip>
                                          <button
                                            type="button"
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-2"
                                            data-tooltip-content="Remove Document"
                                            onClick={() => handleDelete(value)}
                                          >
                                            <i className="mdi mdi-delete" />
                                          </button>
                                          <Tooltip id="mt-2"></Tooltip>
                                           */}
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
              {/* 
                      <ul className="pagination pagination-rounded justify-content-end my-2">
                        <li className="page-item">
                          <span className="page-link" aria-label="Previous">
                            <span aria-hidden="true">«</span>
                            <span className="sr-only">Previous</span>
                          </span>
                        </li>
                        <li className="page-item active">
                          <span className="page-link">1</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">2</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">3</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">4</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">5</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link" aria-label="Next">
                            <span aria-hidden="true">»</span>
                            <span className="sr-only">Next</span>
                          </span>
                        </li>
                      </ul>
                      */}
            </div>
            {/* end card-body*/}
          </div>
          {/* end card*/}
        </div>
        {/* end col */}
      </div>

      <Modal show={shareModalOpen} onHide={toggleShareModal} centered>
        <form onSubmit={submitShareHistory}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Share Document</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-2">
                  <label htmlFor="title" className="form-label">
                    Document
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="title"
                    name="title"
                    readOnly
                    defaultValue={documentShareData.title !== null && documentShareData.title}
                  />
                </div>
              </div>
              <div className="col-md-12">
                <div className="mb-0">
                  <label htmlFor="share_to" className="form-label">
                    Share To
                  </label>
                  <textarea
                    className="form-control"
                    rows="5"
                    id="share_to"
                    name="share_to"
                    placeholder="Multiple email address must be seperted by comma (,)"
                    onChange={handleShareChange}
                  ></textarea>
                  {shareErrors.share_to && <span className="error">{shareErrors.share_to}</span>}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleShareModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Share
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      {isLoading === true && <Spinner />}
    </Layout>
  );
};

export default ComplianceDocument;
