import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Index";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Link, useLocation, useNavigate } from "react-router-dom";
import PageTitle from "../../Layout/PageTitle";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { currentRole } from "../../Layout/HelmetComponent";
import { Button, Modal } from "react-bootstrap";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import { toast } from "react-toastify";
import Spinner from "../../Loader/Spinner";
import { format } from "date-fns";
import moment from "moment";

function Checkout() {
  const navigate = useNavigate();
  const authToken = localStorage.getItem("token")
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const token = queryParams.get('token');
  const payerId = queryParams.get('PayerID');
  const subscriptionId = queryParams.get('subscription_id');
  const planId = Number(queryParams.get('plan_id'));
  const id = Number(location?.state?.id);
  const planType = id === 2 
  ? "pay_as_you_go" 
  : planId === 2 
      ? "pay_as_you_go" 
      : "subscription";
  const productType = planType === "pay_as_you_go" ? 'Order' : 'Subscription';
  console.log("state planId", location?.state?.id);
  console.log("planId", planId);
  console.log("planType", planType);

  const [yearPlan, setYearPlan] = useState(0)
  const [planDate, setPlanDate] = useState()
  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Checkout", link: null },
  ];
  
  useEffect(() => { 
    function getTodayAndNextYear() {
      const today = new Date();
      const nextDate = new Date();
      if (yearPlan == 1) {
        nextDate.setFullYear(today.getFullYear() + 1);
  
        // Format the dates as YYYY-MM-DD
        const formatDate = (date) => date.toISOString().split("T")[0];
        setPlanDate({
          today: formatDate(today),
          nextDate: formatDate(nextDate),
        })
      }
      else {
        nextDate.setMonth(today.getMonth() + 1);
  
        // Format the dates as YYYY-MM-DD
        const formatDate = (date) => date.toISOString().split("T")[0];
  
        setPlanDate({
          today: formatDate(today),
          nextDate: formatDate(nextDate),
        })
      }
    }
    getTodayAndNextYear() 
  }, [yearPlan])

  const [plans, setPlans] = useState(undefined);
  const [selectedPlan, setSelectedPlan] = useState();
  // console.log(planDetails)
  useEffect(() => {
    const getPlans= async()=>{
      try{
        const result = await api.getPlanMethod("client/plans",{
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        })
        if(result){
          // console.log(result)
          setPlans(result.plans);
        }
      }
      catch(error){
        console.log(error)
      }
    }
    if(!plans){
      getPlans();
    }
  },[authToken, plans, setPlans]);

  useEffect(() => {
    console.log(planType, plans);
    if(planType && plans){
      const matchedPlan = plans.find(plan => plan.type === planType);
      console.log("matchedPlan", matchedPlan);
      if(matchedPlan){
        setSelectedPlan(matchedPlan);
      }
    }
  },[planType, plans]);

  useEffect(() => {
    const handleResponse = async(planType, token, payerId, subscriptionId) => {
      try {
        const path = planType === 'pay_as_you_go'
            ? "client/order/response"
            : "client/subscription/response";
    
        const payload = {
            token: token,
            ...(payerId ? { payerId: payerId } : {}),
            ...(planType === "subscription" && subscriptionId ? { subscriptionId: subscriptionId } : {})
        };
    
        const result = await api.postMethod(payload, path);
        console.log(result);
        if(result?.message){
          toast.info(result?.message);
          if(result.message === ''){
            setTimeout(() => {
              navigate('/client/my-subscription'); 
            }, 3000); // 3000 milliseconds = 3 seconds
          }
        }
      } catch (error) {
        console.error("An error occurred during checkout:", error);
      }
    }
    if(token && planType){
      console.log(planType, token, payerId, subscriptionId);
      handleResponse(planType, token, payerId, subscriptionId);
    }
  }, [token, payerId, planType, subscriptionId, navigate]);

  console.log(yearPlan)

  function convertDate(inputDate) {
    let dob = inputDate && moment(inputDate).format("MMMM-DD-YYYY");
    return dob
  }
  // console.log("year",yearPlan)

  const handleCheckOut = async () => {
    try {
      const path =
          planType === 'pay_as_you_go'
          ? "client/order/create"
          : "client/subscription/create";
  
      const payload = {
        plan_id: selectedPlan?.id,
      };
  
      const result = await api.postMethod(payload, path);
      
      if(result?.message) {
        toast.info(result?.message)
      }

      if (result?.approval_url) {
        window.location.href = result.approval_url; // Redirect the user
      } else {
        console.error("Approval URL not found in the response.");
      }
    } catch (error) {
      console.error("An error occurred during checkout:", error);
    }
  };
  return (
    <Layout>
      <div className="container-fluid">
        {/* start page title */}
        <PageTitle title="Checkout" breadcrumbItems={breadcrumbItems} />
        {/* end page title */}
        <div className="row mb-3" style={{ justifyContent: "space-evenly" }}>
          <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title mb-3">{productType}</h4>

                <div className="pb-2">
                  <label className="form-label">Package</label>
                  <select className="form-control">
                    <option value="">{selectedPlan?.name} (${selectedPlan?.price} Per Month)</option>
                  </select>
                </div>

                <div className="pb-2">
                  <label className="form-label">Type</label>
                  <select className="form-control"
                    onChange={(e) => setYearPlan(e.target.value)}
                  >
                    <option value="0">Monthly</option>
                    <option value="1">Yearly</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          {planType && planType === "subscription" && (
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <h4 className="header-title mb-3">Subscription Validity</h4>

                  <div className="pb-2">
                    <label className="form-label">Subscription Start Date</label>
                    <input type="text" className="form-control" disabled value={convertDate(planDate?.today)} />
                  </div>

                  <div className="pb-2">
                    <label className="form-label">Subscription End Date</label>
                    <input type="text" className="form-control" disabled value={convertDate(planDate?.nextDate)} />
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* <div className="col-lg-4">
            <div className="card">
              <div className="card-body">
                <h4 className="header-title mb-3">Add-Ons</h4>

                <div className="mb-1">
                  <label>
                    <input type="checkbox" className="custom-control-input" /> Recruitment
                    Coordinator ($20 per hour)
                  </label>
                </div>

                <div className="mb-1">
                  <label>
                    <input type="checkbox" className="custom-control-input" /> On-Call Scheduling
                    Coordinator (after hrs) ($100 per shift (depending on call volume))
                  </label>
                </div>

                <div className="mb-1">
                  <label>
                    <input type="checkbox" className="custom-control-input" /> Phone Operator ($100
                    per shift (depending on call volume))
                  </label>
                </div>

                <div className="mb-1">
                  <label>
                    <input type="checkbox" className="custom-control-input" /> Account Manager ($20
                    per hour )
                  </label>
                </div>

                <div className="mb-1">
                  <label>
                    <input type="checkbox" className="custom-control-input" /> Compliance
                    Coordinator ($20 per hour )
                  </label>
                </div>

                <div className="mb-1">
                  <label>
                    <input type="checkbox" className="custom-control-input" /> Scheduling
                    Coordinator ($20 per hour )
                  </label>
                </div>

                <div className="mb-1">
                  <label>
                    <input type="checkbox" className="custom-control-input" /> Payroll Coordinator
                    ($20 per hour )
                  </label>
                </div>

                <div className="mb-1">
                  <label>
                    <input type="checkbox" className="custom-control-input" /> Billing Coordinator
                    ($20 per hour )
                  </label>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className="card">
          <div className="card-body">
            <h4 className="header-title mb-3">{productType} Payment Details</h4>
            <div className="table-responsive">
              <table className="table table-bordered table-centered mb-0">
                <thead className="thead-light">
                  <tr>
                    <th>Package</th>
                    <th>Quantity</th>
                    <th>Price</th>
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">{selectedPlan?.name}</th>
                    <td>1</td>
                    <td>${yearPlan === 1 ? 12 * +(selectedPlan?.price) : selectedPlan?.price}</td>
                    <td>${yearPlan === 1 ? 12 * +(selectedPlan?.price) : selectedPlan?.price}</td>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={3} className="text-right">
                      Sub Total :
                    </th>
                    <td>
                      <div className="font-weight-bold">${yearPlan === 1 ? 12 * +(selectedPlan?.price) : selectedPlan?.price}</div>
                    </td>
                  </tr>
                  <tr>
                    <th scope="row" colSpan={3} className="text-right">
                      Total :
                    </th>
                    <td>
                      <div className="font-weight-bold">${yearPlan === 1 ? 12 * +(selectedPlan?.price) : selectedPlan?.price}</div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="text-end mb-3">
          <Button onClick={() => handleCheckOut()} class="btn btn-primary">
            <i class="mdi mdi-cart-plus mr-1"></i> Proceed to Checkout
          </Button>
        </div>
      </div>
    </Layout>
  );
}

export default Checkout;
