import React, { useState, useEffect } from "react";
import * as api from "../../../../services/ApiService";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../../../utils/tokenUtils";
import { Link, useLocation } from "react-router-dom";
import Dropdown from "react-bootstrap/Dropdown";
import Spinner from "../../../Loader/Spinner";
import { toast } from "react-toastify";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import AssignmentModal from "../../AccountManagement/AssignmentModal";

function JobApplicatins({ formatDate, jobRow }) {
  const [isLoading, setIsLoading] = useState(false);

  // Get data from token util
  let user = tokenUtils.getTokenData();
  const location = useLocation();
  const isStaff = location.pathname.includes("/staff");
  const urlSlug = isStaff ? "staff" : "client";
  const [interviewerList, setInterviewerList] = useState([]);
  /*
  const fetchInterviewers = async () => {
    try {
      const apiData = await api.getMethod("get-users/6");

      setInterviewerList(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchInterviewers();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts
  */

  useEffect(() => {
    if (jobRow.id) {
      const fetchJobEmployees = async () => {
        try {
          const param = {
            user_id: user.id,
            token: user.token,
            job_id: jobRow.id,
          };
          const jobEmployeesData = await api.postMethod(param, "client/get-interviwers");
          setInterviewerList(jobEmployeesData.data);
        } catch (error) {
          console.error("Error fetching job employees:", error);
        }
      };

      fetchJobEmployees();
    }
  }, [jobRow.id]);

  const [recentApplications, setRecentApplications] = useState({});
  const [recentAppLoading, setrecentAppLoading] = useState(true);
  const [noRecentAppRecordsFound, setnoRecentAppRecordsFound] = useState(false);

  const [shortlistApplications, setShortApplications] = useState({});
  const [shortlistAppLoading, setShortlistAppLoading] = useState(true);
  const [noShortlistAppRecordsFound, setNoShortlistAppRecordsFound] = useState(false);

  const [submittedApplications, setSubmittedApplications] = useState({});
  const [submittedAppLoading, setSubmittedAppLoading] = useState(true);
  const [noSubmittedAppRecordsFound, setNoSubmittedAppRecordsFound] = useState(false);

  /*
  const [interviewApplications, setInterviewApplications] = useState({});
  const [interviewAppLoading, setInterviewAppLoading] = useState(true);
  const [noInterviewAppRecordsFound, setNoInterviewAppRecordsFound] = useState(false);
  */

  const [offeredApplications, setOfferedApplications] = useState({});
  const [offeredAppLoading, setOfferedAppLoading] = useState(true);
  const [noOfferedAppRecordsFound, setNoOfferedAppRecordsFound] = useState(false);

  const [hiredApplications, setHiredApplications] = useState({});
  const [hiredAppLoading, setHiredAppLoading] = useState(true);
  const [noHiredAppRecordsFound, setNoHiredAppRecordsFound] = useState(false);

  const fetchAppliedJobs = async () => {
    try {
      const param = {
        user_id: user.id,
        job_id: jobRow.id,
      };
      const result = await api.postMethod(param, "client/get-user-job-applications");
      if (result.status) {
        //recent applications
        setRecentApplications(result.data.newApplications);
        setrecentAppLoading(false);
        if (result.data.newApplications.length === 0) {
          setnoRecentAppRecordsFound(true);
        } else setnoRecentAppRecordsFound(false);

        //shortlisted applications
        setShortApplications(result.data.shortlisted);
        setShortlistAppLoading(false);
        if (result.data.shortlisted.length === 0) {
          setNoShortlistAppRecordsFound(true);
        } else setNoShortlistAppRecordsFound(false);

        //submitted applications
        setSubmittedApplications(result.data.submitted);
        setSubmittedAppLoading(false);
        if (result.data.submitted.length === 0) {
          setNoSubmittedAppRecordsFound(true);
        } else setNoSubmittedAppRecordsFound(false);

        /*
        //interview applications
        setInterviewApplications(result.data.interviews);
        setInterviewAppLoading(false);
        if (result.data.interviews.length === 0) {
          setNoInterviewAppRecordsFound(true);
        } else setNoInterviewAppRecordsFound(false);
        */

        //offered applications
        setOfferedApplications(result.data.offered);
        setOfferedAppLoading(false);
        if (result.data.offered.length === 0) {
          setNoOfferedAppRecordsFound(true);
        } else setNoOfferedAppRecordsFound(false);

        //hired applications
        setHiredApplications(result.data.hired);
        setHiredAppLoading(false);
        if (result.data.hired.length === 0) {
          setNoHiredAppRecordsFound(true);
        } else setNoHiredAppRecordsFound(false);
      } else {
        setrecentAppLoading(false);
        setShortlistAppLoading(false);
        /*setInterviewAppLoading(false);*/
        setOfferedAppLoading(false);
        setHiredAppLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setrecentAppLoading(false);
      setShortlistAppLoading(false);
      /*setInterviewAppLoading(false);*/
      setOfferedAppLoading(false);
      setHiredAppLoading(false);
    }
  };

  useEffect(() => {
    fetchAppliedJobs();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [AddModalOpen, setAddModalOpen] = useState(false);

  const [formData, setformData] = useState({
    user_id: user.id,
    id: null,
    jobId: null,
    empId: null,
    interviewerId: null,
    interviewDate: null,
    interviewTime: null,
    status: null,
  });

  const toggleAddModal = () => {
    setAddModalOpen(!AddModalOpen);

    if (!AddModalOpen) {
      setformData({
        user_id: user.id,
        id: null,
        jobId: null,
        empId: null,
        interviewerId: null,
        interviewDate: null,
        interviewTime: null,
        status: null,
      });

      // Reset validation errors
      setErrors({});
    }
  };

  //Define the validation schema
  const validationSchema = Yup.object({
    interviewerId: Yup.string().required("Interviewer is Required"),
    interviewDate: Yup.string().required("Interview Date is Required"),
    interviewTime: Yup.string().required("Interview Time is Required"),
  });

  const [errors, setErrors] = useState({});

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });
      setIsLoading(true);
      const result = await api.postFileMethod(formData, "client/update-user-job-interview");

      if (result.status) {
        setAddModalOpen(false);
        const param = {
          user_id: user.id,
          job_id: formData.jobId,
          emp_id: formData.empId,
          status: formData.status,
        };
        const result2 = await api.postMethod(param, "client/update-user-job-application-status");

        if (result2.status) {
          toast.success(result.message);
          fetchAppliedJobs();
          setIsLoading(false);
        } else setIsLoading(false);
      } else {
        setIsLoading(false);
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  /* Rejection Modal Start */

  const [AddRejectionModalOpen, setAddRejectionModalOpen] = useState(false);

  const [rejectionFormData, setrejectionFormData] = useState({
    user_id: user.id,
    id: null,
    jobId: null,
    jobAppId: null,
    rejectionReason: null,
  });

  const toggleAddRejectionModal = () => {
    setAddRejectionModalOpen(!AddRejectionModalOpen);

    if (!AddRejectionModalOpen) {
      setrejectionFormData({
        user_id: user.id,
        id: null,
        jobId: null,
        jobAppId: null,
        rejectionReason: null,
      });

      // Reset validation errors
      //setErrors({});
    }
  };

  const handleRejectionChange = (e) => {
    const { name, value } = e.target;
    setrejectionFormData({ ...rejectionFormData, [name]: value });

    /*validateFields(name, value);*/
  };

  const handleRejectionSubmit = async (e) => {
    e.preventDefault();

    try {
      //await validationSchema.validate(formData, { abortEarly: false });
      setIsLoading(true);
      const result = await api.postFileMethod(rejectionFormData, "client/reject-job-application");

      if (result.status) {
        setAddRejectionModalOpen(false);
        toast.success(result.message);
        fetchAppliedJobs();
        setIsLoading(false);
      } else {
        setIsLoading(false);
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      /*
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
      */
    }
  };

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const toggleUploadModal = () => {
    setUploadModalOpen(!uploadModalOpen);
  };
  const [editAssignmentData, setEditAssignmentData] = useState({});

  const handleUserApplicationStatus = async (job_id, user_id, status, job_application_id) => {
    /*
    if (status === 4) {
      setformData({
        user_id: user.id,
        jobId: job_id,
        empId: user_id,
        status: status,
      });
      setAddModalOpen(true);
    } else
    */
    if (status === 7) {
      setrejectionFormData({
        user_id: user.id,
        jobId: job_id,
        empId: user_id,
        jobAppId: job_application_id,
        rejectionReason: null,
      });

      setAddRejectionModalOpen(true);
    } else {
      try {
        setIsLoading(true);
        const param = {
          user_id: user.id,
          job_id: job_id,
          emp_id: user_id,
          status: status,
        };
        const result = await api.postMethod(param, "client/update-user-job-application-status");

        if (result.status) {
          fetchAppliedJobs();
          setIsLoading(false);

          if (status === 6) {
            // Populate formData with the values of the selected record
            setEditAssignmentData({
              user_id: user.id,
              assigned_user_id: user_id,
            });
            setUploadModalOpen(true);
          }
        } else setIsLoading(false);
      } catch (error) {
        console.error("Error fetching data:", error);
        setIsLoading(false);
      }
    }
  };

  return (
    <>
      <div className="row row-cols-5">
        <div className="col pe-0">
          <div
            className="card rounded-0 border border-end-0"
            style={{ backgroundColor: "#f5f6f8" }}
          >
            <div className="card-header border-bottom">
              <h4 className="header-title mb-0">New Applications</h4>
            </div>
            <div className="card-body tasklist pb-0 p-2">
              {recentAppLoading ? (
                <h5 className="m-0">Loading...</h5>
              ) : (
                <>
                  {noRecentAppRecordsFound ? (
                    <h5 className="m-0">No application initiated</h5>
                  ) : (
                    <>
                      {Object.entries(recentApplications).map(([key, value]) => (
                        <div
                          className="card project-box rounded-0 border mb-2 ribbon-box"
                          key={jobRow.id + "_new_app_" + value.user_id}
                        >
                          <div className="card-body">
                            <div
                              className={
                                value.match_percentage > 75
                                  ? "float-end mb-0 ribbon ribbon-success"
                                  : value.match_percentage > 60
                                  ? "float-end mb-0 ribbon ribbon-warning"
                                  : value.match_percentage > 45
                                  ? "float-end mb-0 ribbon ribbon-danger"
                                  : "float-end mb-0 ribbon ribbon-danger"
                              }
                            >
                              {value.match_percentage > 99
                                ? 100
                                : Math.round(value.match_percentage)}
                              %
                            </div>

                            {/* end dropdown */}
                            {/* Title*/}
                            <h4 className="mt-1 font-15">
                              <Link
                                to={`/` + urlSlug + `/user-profile/` + value.user_unique_id}
                                className=""
                              >
                                {value.name}
                              </Link>
                            </h4>
                            <div className="ribbon-content">
                              {/* Task info*/}
                              <p className="mb-0 d-inline">
                                <span className="pe-2 text-nowrap mb-0 d-inline-block">
                                  <i className="mdi mdi-clock text-muted" />{" "}
                                  {formatDate(value.created_at)}
                                </span>
                              </p>

                              <Dropdown className="float-end">
                                <Dropdown.Toggle
                                  variant="default"
                                  id="dropdown-basic"
                                  className="p-0"
                                >
                                  <i className="mdi mdi-dots-vertical m-0 text-muted h3" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        2,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move to Shortlisted
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        3,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move to Submitted
                                  </Dropdown.Item>
                                  {/*
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        4,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move to Interview
                                  </Dropdown.Item>
                                   */}
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        5,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move to Offered
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        6,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move to Hired
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        7,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Rejected
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col px-0">
          <div
            className="card rounded-0 border border-end-0"
            style={{ backgroundColor: "#f5f6f8" }}
          >
            <div className="card-header border-bottom">
              <h4 className="header-title mb-0">Shortlisted</h4>
            </div>
            <div className="card-body tasklist pb-0 p-2">
              {shortlistAppLoading ? (
                <h5 className="m-0">Loading...</h5>
              ) : (
                <>
                  {noShortlistAppRecordsFound ? (
                    <h5 className="m-0">No shortlisted initiated</h5>
                  ) : (
                    <>
                      {Object.entries(shortlistApplications).map(([key, value]) => (
                        <div
                          className="card project-box rounded-0 border mb-2 ribbon-box"
                          key={jobRow.id + "_new_app_" + value.user_id}
                        >
                          <div className="card-body">
                            <div
                              className={
                                value.match_percentage > 75
                                  ? "float-end mb-0 ribbon ribbon-success"
                                  : value.match_percentage > 60
                                  ? "float-end mb-0 ribbon ribbon-warning"
                                  : value.match_percentage > 45
                                  ? "float-end mb-0 ribbon ribbon-danger"
                                  : "float-end mb-0 ribbon ribbon-danger"
                              }
                            >
                              {value.match_percentage > 99
                                ? 100
                                : Math.round(value.match_percentage)}
                              %
                            </div>

                            {/* end dropdown */}
                            {/* Title*/}
                            <h4 className="mt-1 font-15">
                              <Link
                                to={`/` + urlSlug + `/user-profile/` + value.user_unique_id}
                                className=""
                              >
                                {value.name}
                              </Link>
                            </h4>
                            <div className="ribbon-content">
                              {/* Task info*/}
                              <p className="mb-0 d-inline">
                                <span className="pe-2 text-nowrap mb-0 d-inline-block">
                                  <i className="mdi mdi-clock text-muted" />{" "}
                                  {formatDate(value.created_at)}
                                </span>
                              </p>

                              <Dropdown className="float-end">
                                <Dropdown.Toggle
                                  variant="default"
                                  id="dropdown-basic"
                                  className="p-0"
                                >
                                  <i className="mdi mdi-dots-vertical m-0 text-muted h3" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        1,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move Back to New Application
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        3,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move to Submitted
                                  </Dropdown.Item>
                                  {/*
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        4,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move to Interview
                                  </Dropdown.Item>
                                   */}
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        5,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move to Offered
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        6,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move to Hired
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        7,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Rejected
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col px-0">
          <div
            className="card rounded-0 border border-end-0"
            style={{ backgroundColor: "#f5f6f8" }}
          >
            <div className="card-header border-bottom">
              <h4 className="header-title mb-0">Submitted</h4>
            </div>
            <div className="card-body tasklist pb-0 p-2">
              {submittedAppLoading ? (
                <h5 className="m-0">Loading...</h5>
              ) : (
                <>
                  {noSubmittedAppRecordsFound ? (
                    <h5 className="m-0">No submitted initiated</h5>
                  ) : (
                    <>
                      {Object.entries(submittedApplications).map(([key, value]) => (
                        <div
                          className="card project-box rounded-0 border mb-2 ribbon-box"
                          key={jobRow.id + "_new_app_" + value.user_id}
                        >
                          <div className="card-body">
                            <div
                              className={
                                value.match_percentage > 75
                                  ? "float-end mb-0 ribbon ribbon-success"
                                  : value.match_percentage > 60
                                  ? "float-end mb-0 ribbon ribbon-warning"
                                  : value.match_percentage > 45
                                  ? "float-end mb-0 ribbon ribbon-danger"
                                  : "float-end mb-0 ribbon ribbon-danger"
                              }
                            >
                              {value.match_percentage > 99
                                ? 100
                                : Math.round(value.match_percentage)}
                              %
                            </div>

                            {/* end dropdown */}
                            {/* Title*/}
                            <h4 className="mt-1 font-15">
                              <Link
                                to={`/` + urlSlug + `/user-profile/` + value.user_unique_id}
                                className=""
                              >
                                {value.name}
                              </Link>
                            </h4>
                            <div className="ribbon-content">
                              {/* Task info*/}
                              <p className="mb-0 d-inline">
                                <span className="pe-2 text-nowrap mb-0 d-inline-block">
                                  <i className="mdi mdi-clock text-muted" />{" "}
                                  {formatDate(value.created_at)}
                                </span>
                              </p>

                              <Dropdown className="float-end">
                                <Dropdown.Toggle
                                  variant="default"
                                  id="dropdown-basic"
                                  className="p-0"
                                >
                                  <i className="mdi mdi-dots-vertical m-0 text-muted h3" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        1,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move Back to New Application
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        2,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move Back to Shortlisted
                                  </Dropdown.Item>
                                  {/*
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        4,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move to Interview
                                  </Dropdown.Item>
                                   */}
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        5,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move to Offered
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        6,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move to Hired
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        7,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Rejected
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>

        {/*
        <div className="col px-0">
          <div
            className="card rounded-0 border border-end-0"
            style={{ backgroundColor: "#f5f6f8" }}
          >
            <div className="card-header border-bottom">
              <h4 className="header-title mb-0">Interview</h4>
            </div>
            <div className="card-body tasklist pb-0 p-2">
              {interviewAppLoading ? (
                <h5 className="m-0">Loading...</h5>
              ) : (
                <>
                  {noInterviewAppRecordsFound ? (
                    <h5 className="m-0">No interviews initiated</h5>
                  ) : (
                    <>
                      {Object.entries(interviewApplications).map(([key, value]) => (
                        <div
                          className="card project-box rounded-0 border mb-2 ribbon-box"
                          key={jobRow.id + "_new_app_" + value.user_id}
                        >
                          <div className="card-body">
                            <div
                              className={
                                value.match_percentage > 75
                                  ? "float-end mb-0 ribbon ribbon-success"
                                  : value.match_percentage > 60
                                  ? "float-end mb-0 ribbon ribbon-warning"
                                  : value.match_percentage > 45
                                  ? "float-end mb-0 ribbon ribbon-danger"
                                  : "float-end mb-0 ribbon ribbon-danger"
                              }
                            >
                              {value.match_percentage > 99
                                ? 100
                                : Math.round(value.match_percentage)}
                              %
                            </div>

                            <h4 className="mt-1 font-15">
                              <Link
                                to={`/`+urlSlug+`/user-profile/` + value.user_unique_id}
                                className=""
                              >
                                {value.name}
                              </Link>
                            </h4>
                            <div className="ribbon-content">
                              <p className="mb-0 d-inline">
                                <span className="pe-2 text-nowrap mb-0 d-inline-block">
                                  <i className="mdi mdi-clock text-muted" />{" "}
                                  {formatDate(value.created_at)}
                                </span>
                              </p>

                              <Dropdown className="float-end">
                                <Dropdown.Toggle
                                  variant="default"
                                  id="dropdown-basic"
                                  className="p-0"
                                >
                                  <i className="mdi mdi-dots-vertical m-0 text-muted h3" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        1,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move Back to New Application
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        2,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move Back to Shortlisted
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        3,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move Back to Submitted
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        5,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move to Offered
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        7,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Rejected
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
         */}
        <div className="col px-0">
          <div
            className="card rounded-0 border border-end-0"
            style={{ backgroundColor: "#f5f6f8" }}
          >
            <div className="card-header border-bottom">
              <h4 className="header-title mb-0">Offered</h4>
            </div>
            <div className="card-body tasklist pb-0 p-2">
              {offeredAppLoading ? (
                <h5 className="m-0">Loading...</h5>
              ) : (
                <>
                  {noOfferedAppRecordsFound ? (
                    <h5 className="m-0">No offered initiated</h5>
                  ) : (
                    <>
                      {Object.entries(offeredApplications).map(([key, value]) => (
                        <div
                          className="card project-box rounded-0 border mb-2 ribbon-box"
                          key={jobRow.id + "_new_app_" + value.user_id}
                        >
                          <div className="card-body">
                            <div
                              className={
                                value.match_percentage > 75
                                  ? "float-end mb-0 ribbon ribbon-success"
                                  : value.match_percentage > 60
                                  ? "float-end mb-0 ribbon ribbon-warning"
                                  : value.match_percentage > 45
                                  ? "float-end mb-0 ribbon ribbon-danger"
                                  : "float-end mb-0 ribbon ribbon-danger"
                              }
                            >
                              {value.match_percentage > 99
                                ? 100
                                : Math.round(value.match_percentage)}
                              %
                            </div>

                            {/* end dropdown */}
                            {/* Title*/}
                            <h4 className="mt-1 font-15">
                              <Link
                                to={`/` + urlSlug + `/user-profile/` + value.user_unique_id}
                                className=""
                              >
                                {value.name}
                              </Link>
                            </h4>
                            <div className="ribbon-content">
                              {/* Task info*/}
                              <p className="mb-0 d-inline">
                                <span className="pe-2 text-nowrap mb-0 d-inline-block">
                                  <i className="mdi mdi-clock text-muted" />{" "}
                                  {formatDate(value.created_at)}
                                </span>
                              </p>

                              <Dropdown className="float-end">
                                <Dropdown.Toggle
                                  variant="default"
                                  id="dropdown-basic"
                                  className="p-0"
                                >
                                  <i className="mdi mdi-dots-vertical m-0 text-muted h3" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        1,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move Back to New Application
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        2,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move Back to Shortlisted
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        3,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move Back to Submitted
                                  </Dropdown.Item>
                                  {/* 
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        4,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move Back to Interview
                                  </Dropdown.Item>
                                  */}
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        6,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move to Hired
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        7,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Rejected
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col ps-0">
          <div className="card rounded-0 border" style={{ backgroundColor: "#f5f6f8" }}>
            <div className="card-header border-bottom">
              <h4 className="header-title mb-0">Hired</h4>
            </div>
            <div className="card-body tasklist pb-0 p-2">
              {hiredAppLoading ? (
                <h5 className="m-0">Loading...</h5>
              ) : (
                <>
                  {noHiredAppRecordsFound ? (
                    <h5 className="m-0">No one hired</h5>
                  ) : (
                    <>
                      {Object.entries(hiredApplications).map(([key, value]) => (
                        <div
                          className="card project-box rounded-0 border mb-2 ribbon-box"
                          key={jobRow.id + "_new_app_" + value.user_id}
                        >
                          <div className="card-body">
                            <div
                              className={
                                value.match_percentage > 75
                                  ? "float-end mb-0 ribbon ribbon-success"
                                  : value.match_percentage > 60
                                  ? "float-end mb-0 ribbon ribbon-warning"
                                  : value.match_percentage > 45
                                  ? "float-end mb-0 ribbon ribbon-danger"
                                  : "float-end mb-0 ribbon ribbon-danger"
                              }
                            >
                              {value.match_percentage > 99
                                ? 100
                                : Math.round(value.match_percentage)}
                              %
                            </div>

                            {/* end dropdown */}
                            {/* Title*/}
                            <h4 className="mt-1 font-15">
                              <Link
                                to={`/` + urlSlug + `/user-profile/` + value.user_unique_id}
                                className=""
                              >
                                {value.name}
                              </Link>
                            </h4>
                            <div className="ribbon-content">
                              {/* Task info*/}
                              <p className="mb-0 d-inline">
                                <span className="pe-2 text-nowrap mb-0 d-inline-block">
                                  <i className="mdi mdi-clock text-muted" />{" "}
                                  {formatDate(value.created_at)}
                                </span>
                              </p>

                              <Dropdown className="float-end">
                                <Dropdown.Toggle
                                  variant="default"
                                  id="dropdown-basic"
                                  className="p-0"
                                >
                                  <i className="mdi mdi-dots-vertical m-0 text-muted h3" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        1,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move Back to New Application
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        2,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move Back to Shortlisted
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        3,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move Back to Submitted
                                  </Dropdown.Item>
                                  {/* 
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        4,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move Back to Interview
                                  </Dropdown.Item>
                                  */}
                                  <Dropdown.Item
                                    onClick={() =>
                                      handleUserApplicationStatus(
                                        value.job_id,
                                        value.user_id,
                                        5,
                                        value.job_application_id
                                      )
                                    }
                                  >
                                    Move Back to Offered
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                        </div>
                      ))}
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <Modal show={AddModalOpen} onHide={toggleAddModal} centered size="md">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Add Interview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="mb-2 col-md-12">
                <label className="form-label" htmlFor="interviewerId">
                  Office Admins
                </label>
                <select
                  className="form-control"
                  id="interviewerId"
                  name="interviewerId"
                  onChange={handleChange}
                  value={formData.interviewerId !== null ? formData.interviewerId : ""}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {/* Render the states */}
                  {interviewerList.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </select>
                {errors.interviewerId && <span className="error">{errors.interviewerId}</span>}
              </div>

              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="interviewDate">
                  Interview Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="interviewDate"
                  name="interviewDate"
                  onChange={handleChange}
                  defaultValue={formData.interviewDate !== null ? formData.interviewDate : ""}
                />
                {errors.interviewDate && <span className="error">{errors.interviewDate}</span>}
              </div>

              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="interviewTime">
                  Interview Time
                </label>
                <input
                  type="time"
                  className="form-control"
                  id="interviewTime"
                  name="interviewTime"
                  onChange={handleChange}
                  defaultValue={formData.interviewTime !== null ? formData.interviewTime : ""}
                />
                {errors.interviewTime && <span className="error">{errors.interviewTime}</span>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleAddModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <Modal show={AddRejectionModalOpen} onHide={toggleAddRejectionModal} centered size="md">
        <form onSubmit={handleRejectionSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Rejection Reason</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 ">
                <label className="form-label" htmlFor="rejectionReason">
                  Reason for Job Application Rejection
                </label>
                <textarea
                  rows={5}
                  className="form-control"
                  id="rejectionReason"
                  name="rejectionReason"
                  onChange={handleRejectionChange}
                  defaultValue={formData.rejectionReason !== null ? formData.rejectionReason : ""}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleAddRejectionModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {/* editAssignmentData={editAssignmentData}*/}
      {/*fetchComplianceFiles={fetchComplianceFiles}
      resetFormDataAndErrorsRef={resetFormDataAndErrorsRef}
      */}

      <AssignmentModal
        editAssignmentData={editAssignmentData}
        uploadModalOpen={uploadModalOpen}
        toggleUploadModal={toggleUploadModal}
        setIsLoading={setIsLoading}
      />

      {isLoading === true && <Spinner />}
    </>
  );
}

export default JobApplicatins;
