import React, { useState, useEffect } from "react";
import * as tokenUtils from "../../../../utils/tokenUtils";
import * as api from "../../../../services/ApiService";
import useRestore from "../../../../hooks/useRestore";
import { Link, NavLink } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Spinner from "../../../Loader/Spinner";
import { toast } from "react-toastify";
import { Modal } from "react-bootstrap";

function RejectedApplications({ jobRow, formatDate, formatTime }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [isLoading, setIsLoading] = useState(false);

  const [appliedJobs, setAppliedJob] = useState({});
  const [appliedJobLoading, setAppliedJobLoading] = useState(true);
  const [noAppliedJobRecordsFound, setNoAppliedJobRecordsFound] = useState(false);

  const fetchAppliedJobs = async () => {
    try {
      const param = {
        user_id: user.id,
        job_id: jobRow.id,
      };

      const result = await api.postMethod(param, "admin/get-rejected-job-applications");
      if (result.status) {
        setAppliedJob(result.data);

        setAppliedJobLoading(false);
        // Check if data array is empty
        if (result.data.length === 0) {
          setNoAppliedJobRecordsFound(true);
        } else setNoAppliedJobRecordsFound(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setAppliedJobLoading(false);
    }
  };

  useEffect(() => {
    fetchAppliedJobs();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const { restore } = useRestore();
  const restoreJobApplication = async (record) => {
    const result = await restore();
    if (result.isConfirmed) {
      setIsLoading(true);
      record.user_id = user.id;
      const result = await api.postMethod(record, "admin/restore-job-application");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchAppliedJobs();
      }
    }
  };

  const [selectedMessage, setSelectedMessage] = useState(null);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = (message) => {
    setSelectedMessage(message);
    setShow(true);
  };

  return (
    <>
      <div className="table-responsive">
        <table className="table table-centered table-nowrap mb-0">
          <thead className="table-light">
            <tr>
              <th>Applicant</th>
              <th width="25%">Job Title</th>
              <th>Profile Match</th>
              <th>Applied On</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {appliedJobLoading ? (
              <tr key="0">
                <td colSpan="5">Loading...</td>
              </tr>
            ) : (
              <>
                {noAppliedJobRecordsFound ? (
                  <tr key="0">
                    <td colSpan="5">No job applicants found</td>
                  </tr>
                ) : (
                  <>
                    {Object.entries(appliedJobs).map(([key, value]) => (
                      <tr key={value.job_id}>
                        <td>
                          <Link to={`/admin/user-profile/` + value.user_unique_id} className="">
                            {value.name}
                          </Link>
                        </td>
                        <td>
                          <Link
                            to={`/admin/job/` + value.unique_id}
                            className="job-title"
                            data-tooltip-id={`mt-` + value.unique_id}
                            data-tooltip-content={value.title}
                          >
                            {value.title}
                          </Link>
                          <Tooltip id={`mt-` + value.unique_id}></Tooltip>
                          <p className="mb-0 text-muted">
                            <small>(#{value.unique_id})</small>
                          </p>
                          {(value.state_code !== null || value.city_name !== null) && (
                            <p className="mb-0">
                              <small>
                                <i className="fa fa-map-marker-alt me-1"></i>
                                {value.state_code !== null && value.city_name !== null
                                  ? value.city_name + ", " + value.state_code
                                  : value.state_code !== null
                                  ? value.state_code
                                  : value.city_name !== null
                                  ? value.city_name
                                  : ""}
                              </small>
                            </p>
                          )}
                        </td>
                        <td>
                          <div className="row align-items-center g-0">
                            <div className="col-auto">
                              <span className="me-2">
                                {value.match_percentage > 99
                                  ? 100
                                  : Math.round(value.match_percentage)}
                                %
                              </span>
                            </div>
                            <div className="col">
                              <div className="progress progress-sm">
                                <div
                                  className={
                                    value.match_percentage > 75
                                      ? "progress-bar bg-success"
                                      : value.match_percentage > 60
                                      ? "progress-bar bg-warning"
                                      : value.match_percentage > 45
                                      ? "progress-bar bg-danger"
                                      : "progress-bar bg-danger"
                                  }
                                  role="progressbar"
                                  style={{
                                    width:
                                      value.match_percentage > 99
                                        ? "100%"
                                        : Math.round(value.match_percentage) + "%",
                                  }}
                                  aria-valuenow={
                                    value.match_percentage > 99
                                      ? 100
                                      : Math.round(value.match_percentage)
                                  }
                                  aria-valuemin={0}
                                  aria-valuemax={100}
                                />
                              </div>
                            </div>
                          </div>
                        </td>

                        <td>
                          {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                          <small className="text-muted">
                            {formatDate(value.created_at, "hh:mm A")}
                          </small>
                        </td>
                        <td>
                          <button
                            button="button"
                            className="btn btn-default action-icon"
                            onClick={() => handleShow(value)}
                            data-tooltip-id="mt-1"
                            data-tooltip-content="View Rejection Reason"
                          >
                            <i className="mdi mdi-eye" />
                          </button>
                          <Tooltip id="mt-1"></Tooltip>

                          <button
                            type="button"
                            className="btn btn-default action-icon"
                            data-tooltip-id="mt-2"
                            data-tooltip-content="Restore Application"
                            onClick={() => restoreJobApplication(value)}
                          >
                            <i className="mdi mdi-autorenew" />
                          </button>
                          <Tooltip id="mt-2"></Tooltip>
                        </td>
                      </tr>
                    ))}
                  </>
                )}
              </>
            )}
          </tbody>
        </table>
      </div>

      {selectedMessage && (
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Header closeButton>
            <Modal.Title className="m-0">Rejection Reason</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="mb-0">{selectedMessage.rejection_reason || "N/A"}</p>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary waves-effect"
              onClick={() => setShow(false)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}

      {isLoading === true && <Spinner />}
    </>
  );
}

export default RejectedApplications;
