import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Link } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import * as Yup from "yup";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as api from "../../../services/ApiService";

function AppliedJobs() {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [isLoading, setIsLoading] = useState(false);

  const [filterJobStatus, setFilterJobStatus] = useState(false);
  const handleStatusChange = (event) => {
    setFilterJobStatus(event.target.value);
    /*fetchAppliedJobs();*/
  };

  const [filterKeyword, setFilterKeyword] = useState("");
  const handleKeywordChange = (event) => {
    setFilterKeyword(event.target.value);
    /*fetchAppliedJobs();*/
  };

  const [appliedJobs, setAppliedJob] = useState({});
  const [appliedJobLoading, setAppliedJobLoading] = useState(true);
  const [noAppliedJobRecordsFound, setNoAppliedJobRecordsFound] = useState(false);

  const fetchAppliedJobs = async () => {
    setAppliedJobLoading(true);
    try {
      const param = {
        user_id: user.id,
        job_status: filterJobStatus,
        keyword: filterKeyword,
      };
      const result = await api.postMethod(param, "user/get-applied-jobs");
      if (result.status) {
        setAppliedJob(result.data);
        setNoAppliedJobRecordsFound(false);
        setAppliedJobLoading(false);
        // Check if data array is empty
        if (result.data.length === 0) {
          setNoAppliedJobRecordsFound(true);
        }
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setAppliedJobLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchAppliedJobs();
  }, [filterJobStatus, filterKeyword]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return `${formattedDate}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formattedTime}`;
  };

  const [jobAppStatus, setJobAppStatus] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchJobAppStatus = async () => {
      try {
        const apiData = await api.getMethod("get-job-application-status");
        setJobAppStatus(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchJobAppStatus();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const toggleBookmarkJob = async (job_id) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        token: user.token,
        job_id: job_id,
      };

      const apiData = await api.postMethod(param, "user/update-job-bookmark");
      if (apiData.status) {
        fetchAppliedJobs();
        setIsLoading(false);
        toast.success(apiData.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />
          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Applied Jobs</li>
                      </ol>
                    </div>
                    <h4 className="page-title">Applied Jobs</h4>
                  </div>
                </div>
              </div>
              {/* end page title */}
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <div className="row justify-content-between mb-3">
                        <div className="col-auto">
                          <form className="d-flex flex-wrap align-items-center">
                            <div className="me-3">
                              <label htmlFor="filterKeyword" className="sr-only">
                                Search
                              </label>
                              <input
                                type="search"
                                className="form-control"
                                id="filterKeyword"
                                placeholder="Search by keyword..."
                                onChange={handleKeywordChange}
                                value={filterKeyword}
                              />
                            </div>
                            <label htmlFor="filterJobStatus" className="me-2">
                              Job Application Status
                            </label>
                            <div className="me-sm-3">
                              <select
                                className="form-select my-1 my-lg-0"
                                id="filterJobStatus"
                                value={filterJobStatus}
                                onChange={handleStatusChange}
                              >
                                <option value="">Choose...</option>
                                {jobAppStatus.map(
                                  (status) =>
                                    status.id != 4 &&
                                    status.id != 7 && (
                                      <>
                                        <option value={status.id} key={status.id}>
                                          {status.title}
                                        </option>
                                      </>
                                    )
                                )}
                              </select>
                            </div>
                          </form>
                        </div>
                        <div className="col-auto" />
                        {/* end col*/}
                      </div>
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th>Title</th>
                              <th>Applied On</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {appliedJobLoading ? (
                              <tr>
                                <td colSpan="4">Loading...</td>
                              </tr>
                            ) : (
                              <>
                                {noAppliedJobRecordsFound ? (
                                  <tr>
                                    <td colSpan="4">No resume found</td>
                                  </tr>
                                ) : (
                                  <>
                                    {Object.entries(appliedJobs).map(([key, value]) => (
                                      <tr key={value.id}>
                                        <td>
                                          <h5 className="m-0 font-weight-normal">
                                            <Link to={`/user/job/` + value.unique_id} className="">
                                              {value.title}{" "}
                                            </Link>
                                            <span className="mb-0 text-muted">
                                              <small>({value.company_name})</small>
                                            </span>
                                          </h5>
                                          <p className="mb-0 text-muted">
                                            <small>
                                              {value.state_code !== null && value.city_name !== null
                                                ? value.city_name + ", " + value.state_code
                                                : value.state_code !== null
                                                ? value.state_code
                                                : value.city_name !== null
                                                ? value.city_name
                                                : ""}
                                            </small>
                                          </p>
                                        </td>
                                        <td>
                                          {formatDate(value.created_at)}{" "}
                                          <small className="text-muted">
                                            {formatTime(value.created_at)}
                                          </small>
                                        </td>
                                        <td>
                                          {value.status == 1 && (
                                            <label className="badge badge-soft-primary">
                                              Applied
                                            </label>
                                          )}
                                          {value.status == 2 && (
                                            <label className="badge badge-soft-info">
                                              Shortlisted
                                            </label>
                                          )}
                                          {value.status == 3 && (
                                            <label className="badge badge-soft-warning">
                                              Submitted
                                            </label>
                                          )}
                                          {value.status == 4 && (
                                            <label className="badge badge-soft-warning">
                                              Interviewing
                                            </label>
                                          )}
                                          {value.status == 5 && (
                                            <label className="badge badge-soft-secondary">
                                              Offered
                                            </label>
                                          )}
                                          {value.status == 6 && (
                                            <label className="badge badge-soft-success">
                                              Hired
                                            </label>
                                          )}
                                          {value.status == 7 && (
                                            <label className="badge badge-soft-danger">N/A</label>
                                          )}
                                        </td>
                                        <td>
                                          <Link
                                            to={`/user/job/` + value.unique_id}
                                            className="btn btn-secondary btn-xs me-1"
                                            data-tooltip-id="mt-2"
                                            data-tooltip-content="View Job"
                                          >
                                            <i className="mdi mdi-eye" />
                                          </Link>
                                          <Tooltip id="mt-2"></Tooltip>

                                          <button
                                            type="button"
                                            className={
                                              value.bookmark_exists == 1
                                                ? "btn btn-info btn-xs"
                                                : "btn btn-secondary btn-xs"
                                            }
                                            data-tooltip-id="mt-3"
                                            data-tooltip-content={
                                              value.bookmark_exists == 1
                                                ? "Saved Job"
                                                : "Mark as Saved Job"
                                            }
                                            onClick={() => toggleBookmarkJob(value.id)}
                                          >
                                            <i className="mdi mdi-bookmark" />
                                          </button>
                                          <Tooltip id="mt-3"></Tooltip>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </>
                            )}
                            {/*
                            <tr>
                              <td>
                                Travel Registered Nurse | ICU | (NIGHTS) | 36/48 Hours per week | Up
                                to $4,400 per week
                                <p className="mb-0 text-muted">
                                  <small>New Mexico, Santa Fe</small>
                                </p>
                              </td>
                              <td>
                                01 Jan 2024
                                <small className="text-muted"> 10:29 PM</small>
                              </td>
                              <td>
                                <span className="badge badge-soft-info">Applied</span>
                              </td>
                              <td>
                                <Link
                                  to="/user/job"
                                  className="action-icon "
                                  data-tooltip-id="mt-1"
                                  data-tooltip-content="View Job"
                                >
                                  <i className="mdi mdi-eye" />
                                </Link>
                                <Tooltip id="mt-1"></Tooltip>
                                <Link
                                  to="#"
                                  onClick={(e) => e.preventDefault()}
                                  className="action-icon"
                                  data-tooltip-id="mt-2"
                                  data-tooltip-content="Remove Job"
                                >
                                  <i className="mdi mdi-delete" />
                                </Link>
                                <Tooltip id="mt-2"></Tooltip>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Travel Registered Nurse | ICU | (NIGHTS) | 36/48 Hours per week | Up
                                to $4,400 per week
                                <p className="mb-0 text-muted">
                                  <small>New Mexico, Santa Fe</small>
                                </p>
                              </td>
                              <td>
                                20 Dec 2023
                                <small className="text-muted">10:29 PM</small>
                              </td>
                              <td>
                                <span className="badge badge-soft-secondary">Hold</span>
                              </td>
                              <td>
                                <Link
                                  to="/user/job"
                                  className="action-icon "
                                  data-tooltip-id="mt-3"
                                  data-tooltip-content="View Job"
                                >
                                  <i className="mdi mdi-eye" />
                                </Link>
                                <Tooltip id="mt-3"></Tooltip>
                                <Link
                                  to="#"
                                  onClick={(e) => e.preventDefault()}
                                  className="action-icon"
                                  data-tooltip-id="mt-4"
                                  data-tooltip-content="Remove Job"
                                >
                                  <i className="mdi mdi-delete" />
                                </Link>
                                <Tooltip id="mt-4"></Tooltip>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                Travel Registered Nurse | ICU | (NIGHTS) | 36/48 Hours per week | Up
                                to $4,400 per week
                                <p className="mb-0 text-muted">
                                  <small>New Mexico, Santa Fe</small>
                                </p>
                              </td>
                              <td>
                                01 Jan 2024
                                <small className="text-muted">10:29 PM</small>
                              </td>
                              <td>
                                <span className="badge badge-soft-danger">Rejected</span>
                              </td>
                              <td>
                                <Link
                                  to="/user/job"
                                  className="action-icon "
                                  data-tooltip-id="mt-5"
                                  data-tooltip-content="View Job"
                                >
                                  <i className="mdi mdi-eye" />
                                </Link>
                                <Tooltip id="mt-5"></Tooltip>
                                <Link
                                  to="#"
                                  onClick={(e) => e.preventDefault()}
                                  className="action-icon"
                                  data-tooltip-id="mt-6"
                                  data-tooltip-content="Remove Job"
                                >
                                  <i className="mdi mdi-delete" />
                                </Link>
                                <Tooltip id="mt-6"></Tooltip>
                              </td>
                            </tr>
                             */}
                          </tbody>
                        </table>
                      </div>
                      {/*
                      <ul className="pagination pagination-rounded justify-content-end my-2">
                        <li className="page-item">
                          <span className="page-link" aria-label="Previous">
                            <span aria-hidden="true">«</span>
                            <span className="sr-only">Previous</span>
                          </span>
                        </li>
                        <li className="page-item active">
                          <span className="page-link">1</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">2</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">3</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">4</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">5</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link" aria-label="Next">
                            <span aria-hidden="true">»</span>
                            <span className="sr-only">Next</span>
                          </span>
                        </li>
                      </ul>
                       */}
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>
          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default AppliedJobs;
