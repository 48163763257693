import * as toast from "../utils/toastUtils";

/*  Register  */
export async function register(data, endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
  const res = await response.json();

  if (res.status) {
    // Save the data in local storage
    localStorage.setItem("userId", res.data.user_id);

    toast.success(res.message);
    return true;
  } else {
    toast.error(res.message);
    return false;
  }
}

/* Email Verification */
export async function emailVerification(data, endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
  const res = await response.json();

  if (res.status) {
    // Remove userId from location storage
    localStorage.removeItem("userId");

    // Save the data in local storage
    localStorage.setItem("isLoggedIn", true);
    localStorage.setItem("token", res.data.token);

    toast.success(res.message);
    return res.data;
  } else {
    toast.error(res.message);
    return false;
  }
}

/* Resend Verification Code */
export async function resendVerificationCode(data, endpoint) {
  let url = process.env.REACT_APP_API_URL + endpoint;

  const response = await fetch(url, {
    method: "POST",
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify(data)
  });
  const res = await response.json();

  if (res.status) {
    toast.success(res.message);
    return res.data;
  } else {
    toast.error(res.message);
    return false;
  }
}

/* Login */
export async function login(data, endpoint) {
  try {
    let url = process.env.REACT_APP_API_URL + endpoint;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });

    const res = await response.json();
    if (res.status) {
      toast.success(res.message);

      // Email verification is pending
      if (res.data.verify_email) {
        // Save the data in local storage
        localStorage.setItem("userId", res.data.user_id);
        return 2;
      }
      // Redirect to dashboard
      else {
        // Remove userId from location storage
        localStorage.removeItem("userId");

        // Save the data in local storage
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("token", res.data.token);
        //localStorage.setItem("user", JSON.stringify(res.data));
        return 1;
      }
    } else {
      toast.error(res.message);
      console.log("No response");
      return false;
    }
  } catch (error) {
    console.error("Error during login:", error);
    toast.error("An error occurred during login.");
    return false;
  }
}

/* Logout */
export async function logout(data, endpoint) {
  try {
    let url = process.env.REACT_APP_API_URL + endpoint;
    const token = localStorage.getItem('token');
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`,
      },
      body: JSON.stringify(data)
    });

    const res = await response.json();
    if (res.status) {
      //toast.success(res.message);

      // Remove local storage data
      localStorage.clear();
      /*localStorage.removeItem("userId");
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("user");*/
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    console.error("Error during logout:", error);
    toast.error("An error occurred during logout.");
    return false;
  }
}

/* Forgot Password */
export async function forgotPassword(data, endpoint) {
  try {
    let url = process.env.REACT_APP_API_URL + endpoint;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });

    const res = await response.json();
    if (res.status) {
      // Save the data in local storage
      localStorage.setItem("userId", res.data.user_id);

      toast.success(res.message);
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    console.error("Error during logout:", error);
    toast.error("An error occurred during logout.");
    return false;
  }
}

/* Reset Password */
export async function resetPassword(data, endpoint) {
  try {
    let url = process.env.REACT_APP_API_URL + endpoint;

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(data)
    });

    const res = await response.json();
    if (res.status) {
      // Remove the data from local storage
      localStorage.removeItem("userId");

      toast.success(res.message);
      return true;
    } else {
      toast.error(res.message);
      return false;
    }
  } catch (error) {
    console.error("Error during logout:", error);
    toast.error("An error occurred during logout.");
    return false;
  }
}
