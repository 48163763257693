import React, { useState, useEffect, useCallback } from "react";
import { Link } from "react-router-dom";
import HeaderUser from "./Header/Header-User";
import HeaderClient from "./Header/Header-Client";
import HeaderAdmin from "./Header/Header-Admin";
import * as tokenUtils from "../../utils/tokenUtils";
import * as api from "../../services/ApiService";
import { Tooltip } from "react-tooltip";
import ThemeProvider from "./ThemeContext";
import ThemeToggleButton from "./ThemeToggleButton";
import SubmissionFileComponent from "./SubmissionFileComponent";

function Header() {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  let fullName = user?.name || "";
  let profilePic = user?.profile_pic? user?.profile_pic: localStorage.getItem("profile_Image");

  const [showProfileDropdown, setprofileDropdown] = useState(false);
  const handleToggleProfileDropDown = () => {
    setprofileDropdown(!showProfileDropdown);
  };

  document.documentElement.setAttribute("data-layout-menu-position", "fixed");

  /*
  const [showAnnouncement, setShowAnnouncement] = useState(true);
  const [announcement, setAnnouncement] = useState(null);
  const fetchAnnouncementData = async () => {
    try {
      const result = await api.getMethod("get-announcement/" + user?.role_id);
      if (result.status) {
        const apiData = result.data;
        setAnnouncement(apiData.description);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchAnnouncementData();

    const storedShowAnnouncement = localStorage.getItem(`showAnnouncement_${user?.role_id}`);
    if (storedShowAnnouncement !== null) {
      setShowAnnouncement(JSON.parse(storedShowAnnouncement));
    }
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const handleDismiss = () => {
    setShowAnnouncement(false);
    localStorage.setItem(`showAnnouncement_${user?.role_id}`, false);
  };

  const handleShow = () => {
    setShowAnnouncement(true);
    localStorage.setItem(`showAnnouncement_${user?.role_id}`, true);
  };
  */

  const toggleFullscreen = useCallback(() => {
    if (
      !document.fullscreenElement &&
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    }
    document.body.classList.toggle("fullscreen-enable");
  }, []);

  /* Sidebar Toggle */

  const [sidebarSize, setSidebarSize] = useState(() => {
    return localStorage.getItem("sidebarSize") || "default";
  });

  useEffect(() => {
    localStorage.setItem("sidebarSize", sidebarSize);
    window.dispatchEvent(new Event("storage"));
    document.documentElement.setAttribute("data-sidenav-size", sidebarSize);
  }, [sidebarSize]);

  const toggleSidebarSize = () => {
    setSidebarSize((prevTheme) => (prevTheme === "default" ? "compact" : "default"));
  };

  return (
    <>
      <div className="navbar-custom">
        <div className="topbar">
          {/* Topbar Brand Logo */}
          <div className="logo-box">
            {/* Brand Logo Light */}

            <Link
              to={
                user?.role_id == 3
                  ? "/client/dashboard"
                  : user?.role_id == 1 || user?.role_id == 7
                  ? "/admin/dashboard"
                  : "/dashboard"
              }
              className="logo-light"
            >
              <img
                src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
                alt="logo"
                className="logo-lg"
              />
              <img
                src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
                alt="small logo"
                className="logo-sm"
              />
            </Link>
            {/* Brand Logo Dark */}
            <Link
              to={user?.role_id == 3 ? "/client/dashboard" : "/dashboard"}
              className="logo-dark"
            >
              <img
                src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
                alt="dark logo"
                className="logo-lg"
              />
              <img
                src={process.env.PUBLIC_URL + "/assets/images/logo.png"}
                alt="small logo"
                className="logo-sm"
              />
            </Link>
          </div>
          {/* Sidebar Menu Toggle Button */}
          <button className="button-toggle-menu" onClick={toggleSidebarSize}>
            <i className="mdi mdi-menu" />
          </button>
          <ul className="topbar-menu d-flex align-items-center ms-auto">
            {user?.role_id != 4 && user?.role_id != 5 && user?.role_id != 9 && (
              <>
                <li
                  class="d-none d-md-inline-block"
                  data-toggle="fullscreen"
                  onClick={(e) => {
                    e.preventDefault();
                    toggleFullscreen();
                  }}
                >
                  <span className="nav-link">
                    <i class="fe-maximize font-22"></i>
                  </span>
                </li>

                <ThemeProvider>
                  <li class="d-none d-sm-inline-block">
                    <ThemeToggleButton></ThemeToggleButton>
                  </li>
                </ThemeProvider>
              </>
            )}

            {(user?.role_id === 4 || user?.role_id === 5 || user?.role_id === 9) && (
              <>
                <li class="d-md-inline-block">
                  <SubmissionFileComponent user={user} />
                </li>
              </>
            )}

            {/* User Dropdown */}
            <li className="dropdown">
              <span
                type="button"
                className="nav-link dropdown-toggle nav-user me-0 waves-effect waves-light"
                onClick={handleToggleProfileDropDown}
                role="button"
              >
                <img
                  src={
                    profilePic ? profilePic : process.env.PUBLIC_URL + "/assets/images/fav-icon.jpg"
                  }
                  alt={fullName}
                  className="rounded-circle"
                />
                <span className="ms-1 d-none d-md-inline-block">
                  {fullName} <i className="mdi mdi-chevron-down" />
                </span>
              </span>
              <div
                className={`dropdown-menu dropdown-menu-end profile-dropdown ${
                  showProfileDropdown ? "show" : ""
                }`}
              >
                {(user?.role_id == 1 || user?.role_id == 7) && <HeaderAdmin user={user} />}
                {user?.role_id == 2 && <HeaderClient user={user} />}
                {user?.role_id == 3 && <HeaderClient user={user} />}
                {user?.role_id == 6 && <HeaderClient user={user} />}
                {(user?.role_id == 4 || user?.role_id == 5 || user?.role_id == 9) && (
                  <HeaderUser user={user} />
                )}
              </div>
            </li>
            {/* Right Bar offcanvas button (Theme Customization Panel) */}
          </ul>
          <div className="clearfix" />
        </div>
      </div>

      {/* 
      <div className="d-inline-block  mx-2">
        {announcement && showAnnouncement && (
          <div className="alert alert-info mt-3 mb-0 alert-dismissible fade show" role="alert">
            <div dangerouslySetInnerHTML={{ __html: announcement }} />
            <button type="button" className="btn-close" onClick={handleDismiss}></button>
          </div>
        )}
        {!showAnnouncement && (
          <>
            <button
              className="btn btn-info mt-3 float-end btn-rounded"
              onClick={handleShow}
              data-tooltip-id="mt-33"
              data-tooltip-content="Show Announcement"
            >
              <i class="fas fa-info"></i>
            </button>
            <Tooltip id="mt-33"></Tooltip>
          </>
        )}
      </div>
      */}
    </>
  );
}

export default Header;
