import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import { Tooltip } from "react-tooltip";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import * as Yup from "yup";
import BasicInfo from "./Tabs/BasicInfo";
import JobApplications from "./Tabs/JobApplications";
import RejectedApplications from "./Tabs/RejectedApplications";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";

function JobDetail() {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { jobId } = useParams();
  const location = useLocation();
  const isStaff = location.pathname.includes("/staff");
  const urlSlug = isStaff ? "staff" : "client";
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [selectedTab, setSelectedTab] = useState("basic_info");
  // Tab select event
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  const [isBookmarkedJob, setIsBookmarkedJob] = useState(false);

  const [jobRow, setJobRow] = useState({});

  const toggleBookmarkJob = async () => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        token: user.token,
        job_id: jobRow.id,
      };
      const apiData = await api.postMethod(param, "user/update-job-bookmark");
      if (apiData.status) {
        setIsBookmarkedJob(false);
        if (apiData.data == 1) setIsBookmarkedJob(true);
        setIsLoading(false);
        toast.success(apiData.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const [isAppliedJob, setIsAppliedJob] = useState(false);

  const toggleAppliedJob = async () => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        token: user.token,
        job_id: jobRow.id,
      };
      const apiData = await api.postMethod(param, "user/apply-job");
      if (apiData.status) {
        setIsAppliedJob(false);
        if (apiData.data == 1) setIsAppliedJob(true);
        setIsLoading(false);
        toast.success(apiData.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const [attachments, setAttatchments] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchJobData = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        jobId: jobId,
      };
      const apiData = await api.postMethod(param, "user/get-job-detail");

      if (apiData.status) {
        setJobRow(apiData.data);
        if (location.state?.tab) {
          setSelectedTab(location.state?.tab);
        }
        setAttatchments(apiData.data.attachments);

        setLoading(false);
        // Check if data array is empty
        if (apiData.data.attachments.length === 0) {
          setNoRecordsFound(true);
        }
      } else {
        navigate("/" + urlSlug + "/job-postings");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchJobData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "2-digit",
      month: "short",
      year: "numeric",
    };
    const formattedDate = date.toLocaleDateString("en-US", options);
    return `${formattedDate}`;
  };

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    const formattedTime = date.toLocaleTimeString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return `${formattedTime}`;
  };

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/" + urlSlug + "/dashboard" },
    { text: "Jobs", link: "/" + urlSlug + "/job-postings" },
    { text: `Job Detail (#` + jobRow.unique_id + `)`, link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <PageTitle
                title={`Job Detail (#` + jobRow.unique_id + `)`}
                breadcrumbItems={breadcrumbItems}
              />

              {/* end page title */}
              <div className="row">
                <div className="col-xl-12 col-lg-12">
                  {/* project card */}
                  <div className="card d-block custom-box-shadow">
                    <div className="card-body">
                      {/* project title*/}
                      <h3 className="mt-0 font-20 mb-0">{jobRow.title}</h3>
                      <p className="text-muted">
                        <span className=" text-capitalize fw-bold text-dark">Posted On</span> :{" "}
                        {formatDate(jobRow.created_at)}{" "}
                        <small>{formatTime(jobRow.created_at)}</small>
                      </p>
                      {jobRow.status == 1 && (
                        <h4>
                          <div className="badge badge-outline-success badge-pill">Active</div>
                        </h4>
                      )}
                      {jobRow.status == 0 && (
                        <h4>
                          <div className="badge badge-outline-info badge-pill">Draft</div>
                        </h4>
                      )}

                      <div className="row my-3">
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "basic_info" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("basic_info")}
                          >
                            Position Details
                          </button>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "job_applications" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("job_applications")}
                          >
                            Job Applications
                          </button>
                        </div>
                        <div className="col">
                          <button
                            type="button"
                            className={
                              "btn  w-100 " +
                              (selectedTab === "rejected_applications"
                                ? "btn-primary"
                                : "btn-light")
                            }
                            onClick={() => handleTabSelect("rejected_applications")}
                          >
                            Rejected Applications
                          </button>
                        </div>
                      </div>

                      {selectedTab == "basic_info" && (
                        <BasicInfo
                          jobRow={jobRow}
                          formatDate={formatDate}
                          formatTime={formatTime}
                          attachments={attachments}
                          loading={loading}
                          noRecordsFound={noRecordsFound}
                        />
                      )}

                      {selectedTab == "job_applications" && (
                        <JobApplications
                          jobRow={jobRow}
                          formatDate={formatDate}
                          formatTime={formatTime}
                        />
                      )}

                      {selectedTab == "rejected_applications" && (
                        <RejectedApplications
                          jobRow={jobRow}
                          formatDate={formatDate}
                          formatTime={formatTime}
                        />
                      )}
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>

                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>
          {/* content */}

          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default JobDetail;
