import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Link, useLocation } from "react-router-dom";
import * as api from "../../../services/ApiService";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as Yup from "yup";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";
import { formatDate } from "../../../utils/dateFormat";
import useConfirm from "../../../hooks/useConfirm";
import Announcement from "../../UI/Announcement";

function AssignChecklist() {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const location = useLocation();
  const isStaff = location.pathname.includes("/staff");
  const urlSlug = isStaff ? "staff" : "client";
  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchComplianceAssignedChecklists();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [applicantLists, setapplicantLists] = useState([]);
  const [candidateLists, setcandidateLists] = useState([]);
  const [employeeLists, setemployeeLists] = useState([]);
  const [jobapplicantLists, setjobapplicantLists] = useState([]);
  const fetchUserLists = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        role_id: 4,
      };
      const apiData = await api.postMethod(param, "client/get-checklist-assigned-users");

      setapplicantLists(apiData.data.applicants);
      setcandidateLists(apiData.data.candidates);
      setemployeeLists(apiData.data.employees);
      setjobapplicantLists(apiData.data.job_applicants);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserLists();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [complianceChecklists, setComplianceChecklists] = useState([]);
  const fetchComplianceChecklists = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "client/get-compliance-checklists");

      setComplianceChecklists(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComplianceChecklists();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [isLoading, setIsLoading] = useState(false);
  const [documentTypes, setDocumentTypes] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchComplianceAssignedChecklists = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "client/get-compliance-assigned-checklist");

      setDocumentTypes(apiData.data);
      setLoading(false);
      setNoRecordsFound(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchComplianceAssignedChecklists();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    checklists: [], // Array to store selected options
    assigned_user_id: null,
  });

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const toggleUploadModal = () => {
    setUploadModalOpen(!uploadModalOpen);

    if (!uploadModalOpen) {
      setformData({
        id: null,
        user_id: user.id,
        checklists: [], // Array to store selected options
        assigned_user_id: null,
      });

      // Reset validation errors
      setErrors({});
    }
  };

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setformData({
      user_id: user.id,
      id: record.id,
      checklists: [record.checklist_id],
      assigned_user_id: record.user_id,
    });

    setUploadModalOpen(true);
  };

  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      record.user_id = user.id;
      setIsLoading(true);
      const result = await api.postMethod(record, "client/delete-compliance-assigned-checklist");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceAssignedChecklists();
      }
    }
  };

  //Define the validation schema
  const validationSchema = Yup.object({
    checklists: Yup.array()
      .min(1, "Please select at least one option")
      .required("Please select at least one option"),
    assigned_user_id: Yup.string().required("Assigned User ID is Required"),
  });

  const [errors, setErrors] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      setIsLoading(true);
      const result = await api.postMethod(
        formData,
        "client/update-compliance-checklist-assigned-users"
      );
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);

        //document.getElementById("file_name").value = "";
        setUploadModalOpen(false);
        fetchComplianceAssignedChecklists();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleSelectChange = (e) => {
    const { name, options } = e.target;
    const selectedOptions = Array.from(options)
      .filter((option) => option.selected)
      .map((option) => option.value);

    setformData({
      ...formData,
      [name]: selectedOptions, // Update selectedOptions in formData
    });

    validateFields(name, selectedOptions);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/" + urlSlug + "/dashboard" },
    { text: "Assigned Checklist", link: null },
  ];


  const announcementModule = user?.role_id === 2 ? "FACILITY_SKILL_CHECKLIST" : user?.role_id === 3 ? "AGENCY_SKILL_CHECKLIST" : undefined;

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />
          <div className="content">
            <Announcement module={announcementModule} />
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <PageTitle title="Assigned Checklist" breadcrumbItems={breadcrumbItems} />

              <div
                className={uploadModalOpen ? "collapse show" : "collapse"}
                id="collapseWidthExample"
              >
                <div className="card custom-box-shadow">
                  <form onSubmit={handleSubmit}>
                    <div className="card-header border-bottom">
                      <h5 className="card-title mb-0">Assign Checklist</h5>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        <div className="form-group mb-0 col-md-4">
                          <label className="form-label" htmlFor="checklists">
                            Checklist *
                          </label>
                          <select
                            className="form-control"
                            id="checklists"
                            name="checklists"
                            multiple={true} // Enable multiple selection
                            value={formData.checklists} // Set selected options
                            onChange={handleSelectChange}
                          >
                            {/* Render the states */}
                            {complianceChecklists.map((checklist) => (
                              <option key={checklist.id} value={checklist.id}>
                                {checklist.title}
                              </option>
                            ))}
                          </select>
                          {errors.checklists && <span className="error">{errors.checklists}</span>}
                        </div>
                        <div className="form-group mb-0 col-md-4">
                          <label className="form-label" htmlFor="assigned_user_id">
                            Select Assignee *
                          </label>
                          <select
                            className="form-control"
                            id="assigned_user_id"
                            name="assigned_user_id"
                            onChange={handleChange}
                            value={
                              formData.assigned_user_id !== null ? formData.assigned_user_id : ""
                            }
                          >
                            <option value="" key="">
                              Please Select
                            </option>
                            {applicantLists.length > 0 && 0 && (
                              <optgroup label="Applicants">
                                {applicantLists &&
                                  applicantLists.map((user) => (
                                    <option key={user.id} value={user.id}>
                                      {user.name}
                                    </option>
                                  ))}
                                applicantLists && (
                              </optgroup>
                            )}

                            {candidateLists.length > 0 && 0 && (
                              <optgroup label="Candidates">
                                {candidateLists &&
                                  candidateLists.map((user) => (
                                    <option key={user.id} value={user.id}>
                                      {user.name}
                                    </option>
                                  ))}
                                candidateLists && (
                              </optgroup>
                            )}

                            {employeeLists.length > 0 && 0 && (
                              <optgroup label="Employees">
                                {employeeLists &&
                                  employeeLists.map((user) => (
                                    <option key={user.id} value={user.id}>
                                      {user.name}
                                    </option>
                                  ))}
                                employeeLists && (
                              </optgroup>
                            )}

                            {jobapplicantLists.length > 0 && (
                              <optgroup label="Job Applicants">
                                {jobapplicantLists &&
                                  jobapplicantLists.map((user) => (
                                    <option key={user.id} value={user.id}>
                                      {user.name}
                                    </option>
                                  ))}
                                jobapplicantLists && (
                              </optgroup>
                            )}
                          </select>
                          {errors.assigned_user_id && (
                            <span className="error">{errors.assigned_user_id}</span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="card-footer border-top">
                      <input type="submit" value="Submit" className="btn btn-primary" />

                      <button
                        type="button"
                        className="btn btn-info waves-effect waves-light float-end"
                        onClick={toggleUploadModal}
                      >
                        Close
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <div className="row justify-content-between mb-2">
                        <div className="col-auto">
                          <div className="dropdown" style={{ width: 300 }}>
                            <button
                              type="button"
                              className="btn btn-outline-light dropdown-toggle w-100 text-start"
                              onClick={handleToggleFilterDropDown}
                            >
                              Filters
                              <i className="mdi mdi-chevron-down float-end" />
                            </button>
                            <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                              <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                                <div className="mb-2">
                                  <label htmlFor="keyword" className="form-label">
                                    Keyword
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="keyword"
                                    name="keyword"
                                    value={filters.keyword}
                                    onChange={handleFilterInputChange}
                                  />
                                </div>

                                <div className="text-end mt-1">
                                  <button
                                    type="button"
                                    className="btn btn-light float-start"
                                    onClick={handleReset}
                                  >
                                    Reset Filter
                                  </button>
                                  <button type="submit" className="btn btn-primary">
                                    Apply Filter
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className="text-lg-end my-1 my-lg-0">
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light mb-2 me-1"
                              onClick={toggleUploadModal}
                            >
                              <i className="fa fa-plus mr-1"></i> Assign Checklist
                            </button>

                            <Link
                              to={`/` + urlSlug + `/completed-checklists`}
                              className="btn btn-info waves-effect waves-light mb-2 me-1"
                            >
                              <i className="fa fa-check-square mr-1"></i> Completed Checklists
                            </Link>
                          </div>
                        </div>
                        {/* end col*/}
                      </div>
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th>Checklist Title</th>
                              <th>Assigned Candidate</th>
                              <th>Assigned Date</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr>
                                <td colSpan="4">Loading...</td>
                              </tr>
                            ) : (
                              <>
                                {noRecordsFound ? (
                                  <tr>
                                    <td colSpan="4">No records found</td>
                                  </tr>
                                ) : (
                                  <>
                                    {Object.entries(documentTypes).map(([key, value]) => (
                                      <tr key={value.id}>
                                        <td>{value.title}</td>
                                        <td>
                                          <Link
                                            to={
                                              `/` +
                                              urlSlug +
                                              `/user-profile/` +
                                              value.user_unique_id
                                            }
                                            className=""
                                          >
                                            {value.profile_pic_path && (
                                              <img
                                                src={value.profile_pic_path}
                                                className="rounded-circle"
                                                alt="user-img"
                                                height={32}
                                              />
                                            )}{" "}
                                            {value.user_name}
                                          </Link>
                                        </td>
                                        <td>
                                          {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                                          <small className="text-muted">
                                            {formatDate(value.created_at, "hh:mm A")}
                                          </small>
                                        </td>
                                        <td>
                                          <button
                                            type="button"
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-3"
                                            data-tooltip-content="Edit Assign Checklist"
                                            onClick={() => handleEdit(value)}
                                          >
                                            <i className="mdi mdi-pencil" />
                                          </button>
                                          <Tooltip id="mt-3"></Tooltip>
                                          <button
                                            type="button"
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-2"
                                            data-tooltip-content="Remove Assign Checklist"
                                            onClick={() => handleDelete(value)}
                                          >
                                            <i className="mdi mdi-delete" />
                                          </button>
                                          <Tooltip id="mt-2"></Tooltip>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                      {/* 
                      <ul className="pagination pagination-rounded justify-content-end my-2">
                        <li className="page-item">
                          <span className="page-link" aria-label="Previous">
                            <span aria-hidden="true">«</span>
                            <span className="sr-only">Previous</span>
                          </span>
                        </li>
                        <li className="page-item active">
                          <span className="page-link">1</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">2</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">3</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">4</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link">5</span>
                        </li>
                        <li className="page-item">
                          <span className="page-link" aria-label="Next">
                            <span aria-hidden="true">»</span>
                            <span className="sr-only">Next</span>
                          </span>
                        </li>
                      </ul>
                      */}
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default AssignChecklist;
