export const formatDate = (timestamp, format) => {
  const options = {};

  switch (format) {
    case "MM/DD/YYYY":
      options.month = "2-digit";
      options.day = "2-digit";
      options.year = "numeric";
      break;
    case "DD/MM/YYYY":
      options.day = "2-digit";
      options.month = "2-digit";
      options.year = "numeric";
      break;
    case "YYYY-MM-DD":
      options.year = "numeric";
      options.month = "2-digit";
      options.day = "2-digit";
      break;
    case "MMMM D, YYYY":
      options.month = "long";
      options.day = "numeric";
      options.year = "numeric";
      break;
    case "MMM D, YYYY":
      options.month = "short";
      options.day = "numeric";
      options.year = "numeric";
      break;
    case "MMMM D, YYYY, h:mm A":
      options.month = "long";
      options.day = "numeric";
      options.year = "numeric";
      options.hour = "2-digit";
      options.minute = "2-digit";
      options.hour12 = true;
      break;
    case "MMM D, YYYY, h:mm A":
      options.month = "short";
      options.day = "numeric";
      options.year = "numeric";
      options.hour = "2-digit";
      options.minute = "2-digit";
      options.hour12 = true;
      break;
    case "MMM DD, YYYY hh:mm A":
      options.month = "short";
      options.day = "2-digit";
      options.year = "numeric";
      options.hour = "2-digit";
      options.minute = "2-digit";
      options.hour12 = true;
      break;
    case "MMM DD, YYYY":
      options.month = "short";
      options.day = "2-digit";
      options.year = "numeric";
      break;
    case "hh:mm A":
      options.hour = "2-digit";
      options.minute = "2-digit";
      options.hour12 = true;
      break;
    case "DD MMM YYYY":
      options.day = "2-digit";
      options.month = "short";
      options.year = "numeric";
      break;
    case "date_time_difference":
      const date = new Date(timestamp);
      const now = new Date();

      const diffInSeconds = Math.floor((now - date) / 1000);
      const diffInMinutes = Math.floor(diffInSeconds / 60);
      const diffInHours = Math.floor(diffInMinutes / 60);
      const diffInDays = Math.floor(diffInHours / 24);
      const diffInWeeks = Math.floor(diffInDays / 7);

      if (diffInSeconds < 60) {
        return `${diffInSeconds} s ago`;
      }
      if (diffInMinutes < 60) {
        return `${diffInMinutes} m ago`;
      }
      if (diffInHours < 24) {
        return `${diffInHours} h ago`;
      }
      if (diffInDays === 1) {
        return "Yesterday";
      }
      if (diffInDays < 7) {
        return date.toLocaleDateString(undefined, { weekday: "short" }); // Returns abbreviated day of the week (e.g., "Mon")
      }
      if (diffInWeeks === 1) {
        return "Last week";
      }
      return `${diffInWeeks} weeks ago`;
    default:
      throw new Error("Invalid format");
  }

  return new Intl.DateTimeFormat("en-US", options).format(new Date(timestamp));
};

export const phoneRegExp = /^(\+\d{1,3}[- ]?)?\d{10}$/;
