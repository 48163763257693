import { useState } from "react";

const PasswordToggle = (formData, setFormData) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  return [showPassword, togglePasswordVisibility];
};

export default PasswordToggle;
