import React from "react";
import { Link } from "react-router-dom";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="footer">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 text-center">{currentYear} © Travel Nurse 911</div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
