import "jquery-ui-dist/jquery-ui";

import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from "react-router-dom";
import React, { useEffect, useState } from "react";



/* Client Auth Compnents */
import ClientLogin from "./component/Auth/Client/Login";
import ClientRegister from "./component/Auth/Client/Register";
import ClientEmailVerification from "./component/Auth/Client/EmailVerification";
import ClientForgotPassword from "./component/Auth/Client/ForgotPassword";
import ClientResetPassword from "./component/Auth/Client/ResetPassword";

/* Client Compnents */
import ClientDashboard from "./component/Client/Dashboard";
import ClientInbox from "./component/Client/Messages";
import ClientCalendar from "./component/Client/Calendar";

import ClientJobs from "./component/Client/Job/Jobs";
import ClientJobPostStep1 from "./component/Client/Job/JobPostStep1";
import ClientJobPostStep2 from "./component/Client/Job/JobPostStep2";
import ClientJobPostStep3 from "./component/Client/Job/JobPostStep3";
import ClientJobPostStep4 from "./component/Client/Job/JobPostStep4";
import ClientJobPostStep5 from "./component/Client/Job/JobPostStep5";
import ClientJobApplications from "./component/Client/Job/JobApplications";
import ClientJobDetail from "./component/Client/Job/JobDetail";
import ClientSearch from "./component/Client/Job/Search";

import ClientDocuments from "./component/Client/Document/Documents";
import ClientDocumentShareHistory from "./component/Client/Document/DocumentShareHistory";
import ClientDocumentTypes from "./component/Client/Document/DocumentTypes";

import ClientFacilityClients from "./component/Client/AccountManagement/ManageClients";
import ClientAssignments from "./component/Client/AccountManagement/ManageAssignments";
import ClientSubmissions from "./component/Client/AccountManagement/ManageSubmissions";
import ClientRedirects from "./component/Client/AccountManagement/ManageRedirects";

import ClientComplianceFiles from "./component/Client/ComplianceFiles/ComplianceFiles";
import ClientComplianceTypes from "./component/Client/ComplianceFiles/ComplianceTypes";
import ClientComplianceCategories from "./component/Client/ComplianceFiles/ClientComplianceCategories";

import ClientFacilityCompliances from "./component/Client/ComplianceFiles/ClientFacilityCompliances";
import ClientAssignFacilityCompliances from "./component/Client/ComplianceFiles/AssignFacilityCompliances";

import ClientComplianceChecklists from "./component/Client/ComplianceFiles/ComplianceChecklists";
import ClientComplianceAddChecklist from "./component/Client/ComplianceFiles/ComplianceAddChecklist";
import ClientAssignChecklist from "./component/Client/ComplianceFiles/AssignChecklist";
import ClientSubmittedChecklists from "./component/Client/ComplianceFiles/SubmittedChecklist";

import ClientProfile from "./component/Client/Profile/Profile";
import ClietScheduling from "./component/Client/Scheduling/Scheduling";
import ClientApplicants from "./component/Client/Applicants/ManageApplicants";

import ClientCandidates from "./component/Client/Candidates/ManageCandidates";
import ClientAddCandidateStep1 from "./component/Client/Candidates/AddCandidateStep1";
import ClientAddCandidateStep2 from "./component/Client/Candidates/AddCandidateStep2";
import ClientAddCandidateStep3 from "./component/Client/Candidates/AddCandidateStep3";
import ClientAddCandidateStep4 from "./component/Client/Candidates/AddCandidateStep4";
import ClientAddCandidateStep5 from "./component/Client/Candidates/AddCandidateStep5";
import ClientUserProfile from "./component/Client/Candidates/UserProfile";
import ClientEmployees from "./component/Client/Employees/ManageEmployees";
import ClientAddEmployeeStep1 from "./component/Client/Employees/AddEmployeeStep1";
import ClientAddEmployeeStep2 from "./component/Client/Employees/AddEmployeeStep2";
import ClientAddEmployeeStep3 from "./component/Client/Employees/AddEmployeeStep3";
import ClientAddEmployeeStep4 from "./component/Client/Employees/AddEmployeeStep4";
import ClientAddEmployeeStep5 from "./component/Client/Employees/AddEmployeeStep5";
import ClientOfficeAdmins from "./component/Client/OfficeAdmins/OfficeAdmins";
import ClientInterviews from "./component/Client/Interviews/ManageInterviews";
import ClientTasks from "./component/Client/Tasks/Tasks";
import ClientCheckout from "./component/Client/SubscriptionPlans/Checkout";
import ClientSMS from "./component/Client/OtherSection/SMS";
import ClientEmail from "./component/Client/OtherSection/Email";
import { getPageTitle, getRoleById } from "./component/Layout/HelmetComponent";
import { getTokenData } from "./utils/tokenUtils";
import SubscriptionsUi2 from "./component/Client/SubscriptionPlans/SubscriptionUi2";
import SubscriptionDetails from "./component/Client/SubscriptionPlans/SubscriptionDetails";
import jobRequest from "./component/Client/Job_Request/Job_Request";
import JobUserDetails from "./component/Client/Job_Request/UserDetails/UserDetails";
import Client_Post_Job_Request_Step1 from "./component/Client/Job_Request/UserDetails/Create Opportunity/Post_Job_Request_Step1";
import Client_Post_Job_Request_Step2 from "./component/Client/Job_Request/UserDetails/Create Opportunity/Post_Job_Request_Step2";
import useSubscription from "./hooks/useSubscription";

const FallbackComponent = () => {
  return (
    <>
      <div>
        <p>Opps you are not allowed to use this feature</p>
      </div>
    </>      
  )
}

function ClientApp() {
  const { isModuleEnabled, isFeatureEnabledForModule, loading, error, fetchSubscription } = useSubscription();
  useEffect(() => {
    fetchSubscription(); // Fetch subscription on component mount
  }, [fetchSubscription]);

  if (loading) return <div>Loading...</div>;
  // if (error) return <div>Error: {error}</div>;

  return (
    <Routes>
      {/* === Client Auth Routes === */}
      <Route exact path="/client/login" Component={ClientLogin}></Route>
      <Route exact path="/pilot-program/signup" Component={ClientRegister}></Route>
      <Route exact path="/client/email-verification" Component={ClientEmailVerification}></Route>
      <Route exact path="/client/forgot-password" Component={ClientForgotPassword}></Route>
      <Route exact path="/client/reset-password" Component={ClientResetPassword}></Route>
      <Route exact path="/client/dashboard" Component={ClientDashboard}></Route>

      {/* === Client Routes === */}
      <Route path="/client">
        {/* Module Guard: Check if 'Recruitment Tools' module is enabled */}
        {isModuleEnabled("Recruitment Tools") && (
          <>
            {/* Feature Guard: Check if 'Job Posting Slots' feature is enabled */}
            {isFeatureEnabledForModule("Recruitment Tools", "Job Posting Slots") && (
              <>
                <Route exact path="job-postings" Component={ClientJobs}></Route>
                <Route exact path="boosted-jobs" Component={ClientJobs}></Route>
                <Route exact path="job-post-step1" Component={ClientJobPostStep1}></Route>
                <Route exact path="job-post-step1/:jobID" Component={ClientJobPostStep1}></Route>
                <Route exact path="job-post-step2" Component={ClientJobPostStep2}></Route>
                <Route exact path="job-post-step2/:jobID" Component={ClientJobPostStep2}></Route>
                <Route exact path="job-post-step3" Component={ClientJobPostStep3}></Route>
                <Route exact path="job-post-step3/:jobID" Component={ClientJobPostStep3}></Route>
                <Route exact path="job-post-step4" Component={ClientJobPostStep4}></Route>
                <Route exact path="job-post-step4/:jobID" Component={ClientJobPostStep4}></Route>
                <Route exact path="job-post-step5" Component={ClientJobPostStep5}></Route>
                <Route exact path="job-post-step5/:jobID" Component={ClientJobPostStep5}></Route>
              </>
            )}

            {isFeatureEnabledForModule("Recruitment Tools", "Applicant Tracking") && (
              <>
                <Route exact path="job-applications" Component={ClientJobApplications}></Route>
                <Route exact path="applicants" Component={ClientApplicants}></Route>
              </>
            )}

            {isFeatureEnabledForModule("Recruitment Tools", "Follow Up Management") && (
              <>
                <Route exact path="follow-ups" Component={ClientInterviews}></Route>
              </>
            )}

            {isFeatureEnabledForModule("Recruitment Tools", "Job Posting Management") && (
              <>
                <Route exact path="follow-ups" Component={ClientInterviews}></Route>
              </>
            )}
          </>
        )}

        {/* Module Guard: Check if 'Compliance File Tools' module is enabled */}
        {isModuleEnabled("Compliance File Tools") && (
          <>
            {isFeatureEnabledForModule("Compliance File Tools", "Manage Employee Compliance Files") && (
              <>
                <Route exact path="compliance-files" Component={ClientComplianceFiles}></Route>
                <Route exact path="compliance-file-types" Component={ClientComplianceTypes}></Route>
                <Route
                  exact
                  path="compliance-file-categories"
                  Component={ClientComplianceCategories}
                ></Route>
              </>
            )}

            {isFeatureEnabledForModule("Compliance File Tools", "Skills Checklists") && (
              <>
                <Route exact path="compliance-checklists" Component={ClientComplianceChecklists}></Route>
                <Route exact path="completed-checklists" Component={ClientSubmittedChecklists}></Route>
                <Route exact path="add-checklist" Component={ClientComplianceAddChecklist}></Route>
                <Route
                  exact
                  path="add-checklist/:checklistID"
                  Component={ClientComplianceAddChecklist}
                ></Route>
                <Route exact path="assign-checklist" Component={ClientAssignChecklist}></Route>            
              </>
            )}

            {isFeatureEnabledForModule("Compliance File Tools", "Facility Compliance Lists") && (
              <>
                <Route exact path="facility-compliances" Component={ClientFacilityCompliances}></Route>
                <Route
                  exact
                  path="assign-facility-compliance"
                  Component={ClientAssignFacilityCompliances}
                ></Route>   
              </>
            )}
          </>
        )}

        {isModuleEnabled("Administration & Management Tools") && (
          <>
            {isFeatureEnabledForModule("Administration & Management Tools", "Task Management") && (
              <>
                  <Route exact path="tasks" Component={ClientTasks}></Route>
              </>
            )}
            {isFeatureEnabledForModule("Administration & Management Tools", "Calendar & Reminders") && (
              <>
                  <Route exact path="scheduling" Component={ClietScheduling}></Route>
                  <Route exact path="calendar" Component={ClientCalendar}></Route>
              </>
            )}
            {isFeatureEnabledForModule("Administration & Management Tools", "Messaging/Inbox") && (
              <>
                  <Route exact path="inbox" Component={ClientInbox}></Route>
                  <Route exact path="sms" Component={ClientSMS}></Route>
                  <Route exact path="email" Component={ClientEmail}></Route>
              </>
            )}
            {isFeatureEnabledForModule("Administration & Management Tools", "Task Management") && (
              <>
                <Route exact path="office-admins" Component={ClientOfficeAdmins}></Route>
              </>
            )}
          </>
        )}


        <Route exact path="search" Component={ClientSearch}></Route>
        <Route exact path="shared-documents" Component={ClientDocuments}></Route>
        <Route exact path="document-share-history" Component={ClientDocumentShareHistory}></Route>
        <Route exact path="document-types" Component={ClientDocumentTypes}></Route>
        <Route exact path="clients" Component={ClientFacilityClients}></Route>
        <Route exact path="assignments" Component={ClientAssignments}></Route>
        <Route exact path="submissions" Component={ClientSubmissions}></Route>
        <Route exact path="redirects" Component={ClientRedirects}></Route>
        <Route exact path="interviews" Component={ClientInterviews}></Route>

        {/** employees  */}
        <Route exact path="employees" Component={ClientEmployees}></Route>
        <Route exact path="add-employee-step1" Component={ClientAddEmployeeStep1}></Route>
        <Route exact path="add-employee-step1/:userID" Component={ClientAddEmployeeStep1}></Route>
        <Route exact path="add-employee-step2" Component={ClientAddEmployeeStep2}></Route>
        <Route exact path="add-employee-step3" Component={ClientAddEmployeeStep3}></Route>
        <Route exact path="add-employee-step4" Component={ClientAddEmployeeStep4}></Route>
        <Route exact path="add-employee-step5" Component={ClientAddEmployeeStep5}></Route>

        {/** candidate */}
        <Route exact path="candidates" Component={ClientCandidates}></Route>
        <Route exact path="add-candidate-step1" Component={ClientAddCandidateStep1}></Route>
        <Route
          exact
          path="add-candidate-step1/:userID"
          Component={ClientAddCandidateStep1}
        ></Route>
        <Route exact path="add-candidate-step2" Component={ClientAddCandidateStep2}></Route>
        <Route exact path="add-candidate-step3" Component={ClientAddCandidateStep3}></Route>
        <Route exact path="add-candidate-step4" Component={ClientAddCandidateStep4}></Route>
        <Route exact path="add-candidate-step5" Component={ClientAddCandidateStep5}></Route>

        {/** post job request */}
        <Route exact path="job-request" Component={jobRequest}></Route>
        <Route exact path="job-request/detail" Component={JobUserDetails}></Route>
        <Route exact path="job/:jobId" Component={ClientJobDetail}></Route>
        <Route exact path="post_job_request" Component={Client_Post_Job_Request_Step1}></Route>
        <Route exact path="post_job_request2" Component={Client_Post_Job_Request_Step2}></Route>
        

        {/** not restricted */}
        <Route exact path="company-profile" Component={ClientProfile}></Route>
        <Route exact path="subscription-plans" Component={SubscriptionsUi2}></Route>
        <Route exact path="my-subscription" Component={SubscriptionDetails}></Route>
        <Route exact path="checkout" Component={ClientCheckout}></Route>

         {/* Default Route if no module or feature is enabled */}
        <Route path="*" element={<FallbackComponent />} />
      </Route>

      {/* === Client Auth Routes === */}
      <Route exact path="/staff/login" Component={ClientLogin}></Route>
    </Routes>
  );
}

export default ClientApp;
