import React, { useState, useEffect } from "react";
import * as tokenUtils from "../../../../utils/tokenUtils";
import * as api from "../../../../services/ApiService";
import Modal from "react-bootstrap/Modal";
import * as Yup from "yup";
import { toast } from "react-toastify";

const ScheduleInterviewModal = ({
  uploadAddOpen,
  toggleAddModal,
  editInterViewData,
  fetchJobInterviews,
}) => {
  let user = tokenUtils.getTokenData();
  const [formData, setFormData] = useState({
    id: null,
    user_id: user.id,
    jobId: 0,
    title: null,
    empId: null,
    interviewerId: null,
    interviewDate: null,
    interviewTime: null,
    applicantId: null,
  });
  const [jobList, setJobList] = useState([]);

  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (editInterViewData) {
      // Merge `editInterViewData` values into `formData`
      setFormData((prevFormData) => ({
        ...prevFormData, // Preserve existing formData
        id: editInterViewData.id || null,
        jobId: editInterViewData.jobId || 0,
        title: editInterViewData.title || null,
        empId: editInterViewData.empId || null,
        interviewerId: editInterViewData.interviewerId || null,
        interviewDate: editInterViewData.interviewDate || null,
        interviewTime: editInterViewData.interviewTime || null,
      }));
    }
  }, [editInterViewData]); // Ensure this effect runs when `editInterViewData` changes

  /*
  useEffect(() => {
    if (uploadAddOpen) {
      const fetchData = async () => {
        try {
          const param = {
            user_id: user.id,
            token: user.token,
          };

          const [jobsData] = await Promise.all([api.postMethod(param, "admin/get-jobs")]);
          setJobList(jobsData.data);
          
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };

      fetchData();
    }
  }, [uploadAddOpen, editInterViewData]);
  

  const [interviewerList, setInterviewerList] = useState([]);

  useEffect(() => {
    if (uploadAddOpen && formData.jobId) {
      const fetchJobEmployees = async () => {
        try {
          const param = {
            user_id: user.id,
            token: user.token,
            job_id: formData.jobId,
          };
          const jobEmployeesData = await api.postMethod(param, "admin/get-interviwers");
          setInterviewerList(jobEmployeesData.data);
        } catch (error) {
          console.error("Error fetching job employees:", error);
        }
      };

      fetchJobEmployees();
    }
  }, [uploadAddOpen, formData.jobId]);

  
  const [jobEmployees, setJobEmployees] = useState([]);
  useEffect(() => {
    if (uploadAddOpen && formData.jobId) {
      const fetchJobEmployees = async () => {
        try {
          const param = {
            user_id: user.id,
            token: user.token,
            job_id: formData.jobId,
          };
          const jobEmployeesData = await api.postMethod(param, "admin/get-job-employees");
          setJobEmployees(jobEmployeesData.data);
        } catch (error) {
          console.error("Error fetching job employees:", error);
        }
      };

      fetchJobEmployees();
    }
  }, [uploadAddOpen, formData.jobId]);
  */

  const [interviewerList, setInterviewerList] = useState([]);

  useEffect(() => {
    const fetchJobEmployees = async () => {
      try {
        const jobEmployeesData = await api.getMethod("get-users/6");
        setInterviewerList(jobEmployeesData.data);
      } catch (error) {
        console.error("Error fetching job employees:", error);
      }
    };

    fetchJobEmployees();
  }, []);

  const [applicantLists, setapplicantLists] = useState([]);
  const [candidateLists, setcandidateLists] = useState([]);
  const [employeeLists, setemployeeLists] = useState([]);
  const fetchUserLists = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "admin/get-all-users-list");

      setapplicantLists(apiData.data.applicants);
      setcandidateLists(apiData.data.candidates);
      setemployeeLists(apiData.data.employees);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserLists();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const validationSchema = Yup.object({
    /*jobId: Yup.number().notOneOf([0], "Job is Required").required("Job is Required"),*/
    title: Yup.string().required("Follow Up Title is Required"),
    empId: Yup.string().required("Assignee is Required"),
    interviewerId: Yup.string().required("Office Admin is Required"),
    /*applicantId: Yup.string().required("Applicant is Required"),*/
    interviewDate: Yup.string().required("Follow Up Date is Required"),
    interviewTime: Yup.string().required("Follow Up Time is Required"),
  });

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      const result = await api.postFileMethod(formData, "admin/update-user-job-interview");
      if (result.status) {
        fetchJobInterviews();
        setFormData({
          id: null,
          user_id: user.id,
          jobId: 0,
          title: null,
          empId: null,
          interviewerId: null,
          interviewDate: null,
          interviewTime: null,
        });

        toast.success(result.message);
        toggleAddModal(false);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    validateFields(name, value);
  };
  const closeModal = () => {
    setFormData({
      id: null,
      user_id: user.id,
      jobId: 0,
      title: null,
      empId: null,
      interviewerId: null,
      interviewDate: null,
      interviewTime: null,
    });
    toggleAddModal();
  };
  return (
    <Modal show={uploadAddOpen} onHide={closeModal} centered size="md">
      <form onSubmit={handleSubmit}>
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Add Follow Up</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 mb-2">
              <label className="form-label" htmlFor="title">
                Follow Up Title
              </label>
              <input
                type="text"
                className="form-control"
                id="title"
                name="title"
                onChange={handleChange}
                defaultValue={formData.title !== null ? formData.title : ""}
              />
              {errors.title && <span className="error">{errors.title}</span>}
            </div>

            <div className="col-md-12 mb-2">
              <label className="form-label" htmlFor="empId">
                Choose Assignee *
              </label>
              <select
                className="form-control"
                id="empId"
                name="empId"
                onChange={handleChange}
                value={formData.empId !== null ? formData.empId : ""}
              >
                <option value="" key="">
                  Please Select
                </option>

                {applicantLists.length > 0 && (
                  <optgroup label="Applicants">
                    {applicantLists.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    ))}
                  </optgroup>
                )}

                {candidateLists.length > 0 && (
                  <optgroup label="Candidates">
                    {candidateLists.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    ))}
                  </optgroup>
                )}

                {employeeLists.length > 0 && (
                  <optgroup label="Employees">
                    {employeeLists.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    ))}
                  </optgroup>
                )}
              </select>
              {errors.empId && <span className="error">{errors.empId}</span>}
            </div>

            <div className="col-md-12 mb-2">
              <label className="form-label" htmlFor="interviewerId">
                Office Admins
              </label>
              <select
                className="form-control"
                name="interviewerId"
                onChange={handleChange}
                value={formData.interviewerId !== null ? formData.interviewerId : ""}
              >
                <option value="" key="">
                  Please Select
                </option>
                {interviewerList.map((int) => (
                  <option key={int.id} value={int.id}>
                    {int.name}
                  </option>
                ))}
              </select>
              {errors.interviewerId && <span className="error">{errors.interviewerId}</span>}
            </div>

            <div className="col-md-6 mb-2">
              <label className="form-label" htmlFor="interviewDate">
                Follow Up Date
              </label>
              <input
                type="date"
                className="form-control"
                id="interviewDate"
                name="interviewDate"
                onChange={handleChange}
                defaultValue={formData.interviewDate !== null ? formData.interviewDate : ""}
              />
              {errors.interviewDate && <span className="error">{errors.interviewDate}</span>}
            </div>

            <div className="col-md-6 mb-2">
              <label className="form-label" htmlFor="interviewTime">
                Follow Up Time
              </label>
              <input
                type="time"
                className="form-control"
                id="interviewTime"
                name="interviewTime"
                onChange={handleChange}
                defaultValue={formData.interviewTime !== null ? formData.interviewTime : ""}
              />
              {errors.interviewTime && <span className="error">{errors.interviewTime}</span>}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={closeModal}>
            Close
          </button>
          <button type="submit" className="btn btn-primary">
            Submit
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ScheduleInterviewModal;
