import React, { useEffect, useState } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import { Link, useNavigate } from "react-router-dom";
import { Button, Dropdown, Modal } from "react-bootstrap";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as api from "../../../services/ApiService";
import { toast } from "react-toastify";

const JobRequset = () => {
  const [loading, setLoading] = useState(false);
  const [noRecordsFound, setNoRecordsFound] = useState(false);
  const [cityState, setCityState] = useState();
  const user = tokenUtils.getTokenData();

  let navigate = useNavigate();

  const [jobrequest, setjobrequest] = useState(1);
  const [jobRequestData, setJobRequestData] = useState("");
  const [activeStateCount, setActiveStateCount] = useState();
  const getJobRequest = async () => {
    try {
      const payload = {
        user_id: user.id,
        status: jobrequest,
      };
      const apiData = await api.postMethod(
        payload,
        "user/job-requests/get-active-request"
      );
      if (apiData) {
        // console.log(apiData.data)
        setActiveStateCount(apiData?.data?.active_job_request);
        setJobRequestData(apiData.data.job_requests);
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  useEffect(() => {
    getJobRequest();
  }, [jobrequest]);
  const sendCreatePage = () => {
    if (activeStateCount >= 1) {
      toast.error("You Already have one Request one Active Job Request");
    } else {
      navigate("/user/job-request-step1");
    }
  };
  const handleInActive = async () => {
    try {
      const payload = {
        user_id: user.id,
        job_request_id: jobRequestData[0].id,
        status: 0,
      };
      const apiData = await api.postMethod(payload, "user/job-requests/store");
      if (apiData) {
        getJobRequest();
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const handleActive = async (id) => {
    if (activeStateCount >= 1) {
      toast.error("You Already have one Request one Active Job Request");
    } else {
      try {
        const payload = {
          user_id: user.id,
          job_request_id: id,
          status: 1,
        };
        const apiData = await api.postMethod(
          payload,
          "user/job-requests/store"
        );
        if (apiData) {
          getJobRequest();
        }
      } catch (error) {
        console.log("error", error);
      }
    }
  };

  const DisplayValues = (values, maxDisplay = 3) => {
    console.log(values);
    if (!values || values.length === 0) return <span>No Location Found</span>;
    const displayText =
      values.length <= maxDisplay
        ? values.join(", ")
        : values.slice(0, maxDisplay).join(", ") + ", ...";
    return displayText;
  };
  const [openStateModal, setOpenStateModal] = useState(false);

  const toggleStateModal = () => {
    setOpenStateModal(!openStateModal);

    if (!openStateModal) {
      // Reset validation errors
      // setErrors({});
    }
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Job Request</li>
                      </ol>
                    </div>
                    <h4 className="page-title">Jobs Request</h4>
                  </div>
                </div>
              </div>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                  <select
                    className="form-control"
                    onChange={(e) => setjobrequest(e.target.value)}
                  >
                    <option value="1">Active Job Request</option>
                    <option value="0">InActive Job Request</option>
                  </select>
                </div>
                <div>
                  <Button
                    onClick={() => sendCreatePage()}
                    className="btn btn-primary waves-effect waves-light mb-2"
                  >
                    <i className="fa fa-plus mr-1" /> New Job Request
                  </Button>
                </div>
              </div>

              {/* end row */}
            </div>
            {/* container */}
            <div className="container mt-4">
              {/* Card */}
              {jobrequest == 1 ? (
                <div className="card border-0 custom-box-shadow">
                  <div className="d-flex">
                    <div>
                      <h4
                        className="mb-0 mr-2 p-1 pt-2"
                        style={{ marginLeft: "1.5vw" }}
                      >
                        Active Job Request
                      </h4>
                    </div>
                  </div>
                  <div className="card-body">
                    {/* Title */}
                    <div className="d-flex align-items-baseline mb-2">
                      <h5 className="card-title" style={{ marginRight: 3 }}>
                        Current Job Request Details
                      </h5>
                      <span className="badge bg-success">Active</span>
                    </div>

                    {/* Job Details */}
                    <div className="row d-flex  justify-content-between">
                      <div className="col-md-4">
                        <p className="mb-1">
                          <strong>Profession:</strong>{" "}
                          {jobRequestData[0]?.profession}
                        </p>
                        <p className="mb-1">
                          <strong>Specialty:</strong>{" "}
                          {jobRequestData[0]?.specialty}
                        </p>
                      </div>
                      <div className="col-md-4">
                        <p className="mb-1">
                          <strong>Shift:</strong>{" "}
                          {jobRequestData[0]?.shift_title}
                        </p>
                        <p className="mb-2">
                          <div>
                            <strong>Locations:</strong>
                          </div>

                          <p>
                            {jobRequestData[0]?.state_city_data[0]?.state} |{" "}
                            {DisplayValues(
                              jobRequestData[0]?.state_city_data[0]?.cities
                            )}{" "}
                            {jobRequestData[0]?.state_city_data.length > 1 ? (
                              <span
                                onClick={() => {
                                  setCityState(jobRequestData[0]);
                                  toggleStateModal();
                                }}
                                style={{
                                  marginLeft: "2vw",
                                  background: "#43bfe5",
                                  padding: 3,
                                  fontSize: "12px",
                                  borderRadius: 50,
                                  color: "white",
                                  cursor: "pointer",
                                }}
                              >
                                +{jobRequestData[0]?.state_city_data.length - 1}
                              </span>
                            ) : (
                              ""
                            )}
                          </p>
                        </p>
                      </div>
                      <div className="col-md-3">
                        <p className="mb-1">
                          <strong>Salary:</strong> ${" "}
                          {jobRequestData[0]?.pay_rate}{" "}
                          {jobRequestData[0]?.pay_type}
                        </p>
                        <p className="mb-1">
                          <strong>Start Date:</strong>{" "}
                          {jobRequestData[0]?.start_date}
                        </p>
                      </div>
                      <div className="col-md-1">
                        <Dropdown className="">
                          <Dropdown.Toggle
                            variant="default"
                            // id={`dropdown-basic-` + value.job_id}
                            // key={`dropdown-basic-` + value.job_id}
                            className="p-0"
                          >
                            <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() =>
                                navigate("/user/job-request-step1", {
                                  state: {
                                    isUpdate: "update",
                                    id: jobRequestData[0].id,
                                  },
                                })
                              }
                            >
                              Update
                            </Dropdown.Item>
                            <Dropdown.Item onClick={() => handleInActive()}>
                              Mark as In-Active
                            </Dropdown.Item>
                            {/*                                         
                                        
                                          <Dropdown.Item >
                                            Mark as In-Active
                                          </Dropdown.Item>
                                       

                                        <Dropdown.Item
                                        >
                                          Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          Delete
                                        </Dropdown.Item> */}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </div>

                    {/* Ellipsis Menu */}
                  </div>
                </div>
              ) : (
                <div className="card border-0 custom-box-shadow">
                  <div className="d-flex ">
                    <div>
                      <h4 className="mb-0 mr-2 p-1 pt-2">
                        In-Active Job Request
                      </h4>
                    </div>
                  </div>
                  {jobRequestData &&
                    jobRequestData.map((data) => (
                      <div
                        className="card-body"
                        style={{ borderBottom: "1px solid black" }}
                      >
                        {/* Title */}
                        <div className="d-flex align-items-baseline mb-3">
                          <h5
                            className="card-title "
                            style={{ marginRight: 3 }}
                          >
                            In-Active Job Request Details
                          </h5>
                          <span className="badge bg-danger ml-1">
                            In-Active
                          </span>
                        </div>

                        {/* Job Details */}
                        <div className="row d-flex justify-content-between">
                          <div className="col-md-4">
                            <p className="mb-1">
                              <strong>Profession:</strong> {data?.profession}
                            </p>
                            <p className="mb-1">
                              <strong>Specialty:</strong> {data?.specialty}
                            </p>
                          </div>
                          <div className="col-md-4">
                            <p className="mb-1">
                              <strong>Shift:</strong> {data?.shift_title}
                            </p>
                            <p className="mb-1">
                              <strong>Locations:</strong>
                              <p>
                                {data?.state_city_data[0]?.state} |{" "}
                                {DisplayValues(
                                  data?.state_city_data[0]?.cities
                                )}{" "}
                                {data?.state_city_data.length > 1 ? (
                                  <span
                                    onClick={() => {
                                      setCityState(data);
                                      toggleStateModal();
                                    }}
                                    style={{
                                      marginLeft: "2vw",
                                      background: "#43bfe5",
                                      padding: 3,
                                      fontSize: "12px",
                                      borderRadius: 50,
                                      color: "white",
                                      cursor: "pointer",
                                    }}
                                  >
                                    +
                                    {data?.state_city_data.length -
                                      1}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </p>
                            </p>
                          </div>
                          <div className="col-md-3">
                            <p className="mb-1">
                              <strong>Salary:</strong> $ {data?.pay_rate}{" "}
                              {data?.pay_type}
                            </p>
                            <p className="mb-1">
                              <strong>Start Date:</strong> {data?.start_date}
                            </p>
                          </div>
                          <div className="col-md-1">
                            <Dropdown className="">
                              <Dropdown.Toggle
                                variant="default"
                                // id={`dropdown-basic-` + value.job_id}
                                // key={`dropdown-basic-` + value.job_id}
                                className="p-0"
                              >
                                <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                              </Dropdown.Toggle>

                              <Dropdown.Menu>
                                <Dropdown.Item
                                  onClick={() =>
                                    navigate("/user/job-request-step1", {
                                      state: {
                                        isUpdate: "update",
                                        id: data.id,
                                      },
                                    })
                                  }
                                >
                                  Update
                                </Dropdown.Item>
                                <Dropdown.Item
                                  onClick={() => handleActive(data.id)}
                                >
                                  Mark as Active
                                </Dropdown.Item>
                                {/*                                         
                                        
                                          <Dropdown.Item >
                                            Mark as In-Active
                                          </Dropdown.Item>
                                       

                                        <Dropdown.Item
                                        >
                                          Edit
                                        </Dropdown.Item>
                                        <Dropdown.Item>
                                          Delete
                                        </Dropdown.Item> */}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>

                        {/* Ellipsis Menu */}
                      </div>
                    ))}
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-12">
                {loading ? (
                  <div className="card mb-3 custom-box-shadow">
                    <div className="card-body">Loading...</div>
                  </div>
                ) : (
                  <>
                    {noRecordsFound ? (
                      <div className="card mb-3 custom-box-shadow">
                        <h4 style={{ fontWeight: "700", padding: "1vw" }}>
                          Jobs Received
                        </h4>
                        <div className="card-body">No record found</div>
                      </div>
                    ) : (
                      <>
                        <h4 style={{ fontWeight: "700", padding: "1vw" }}>
                          Jobs Received
                        </h4>

                        {/* {Object.entries(jobs).map(([key, value]) => ( */}
                        <div
                          className="card mb-3 custom-box-shadow  position-relative"
                          // key={value.id}
                        >
                          <div className="card-body">
                            <div className="row align-items-center">
                              <div className="col-sm-12">
                                <h5 className="mt-0 mb-2 font-16">
                                  <Link to={`/user/job/` + 1} className="">
                                    {/* {value.title}{" "} */}
                                    Title
                                  </Link>
                                  <span className=" text-muted font-14 mb-1">
                                    {/* ({value.company_name}) */} company_name
                                  </span>
                                  {/* 
                                      <span className="mb-0 text-muted">
                                        <small>(#{value.unique_id})</small>
                                      </span>
                                      */}
                                </h5>
                              </div>
                              <div className="col-sm-4">
                                <div className="d-flex align-items-start">
                                  <Link to={`/user/job/` + 1} className="">
                                    {/* {value.profile_pic_path && ( */}
                                    <img
                                      // src={value.profile_pic_path}
                                      className="d-flex align-self-center me-3 rounded-circle"
                                      alt="user-img"
                                      height={64}
                                    />
                                  </Link>

                                  <div className="w-100">
                                    <p className="mb-1">
                                      <b>Profession : </b>{" "}
                                      {/* {value.state_code !== null && value.city_name !== null
                                            ? value.city_name + ", " + value.state_code
                                            : value.state_code !== null
                                            ? value.state_code
                                            : value.city_name !== null
                                            ? value.city_name
                                            : "N/A"} */}
                                    </p>
                                    <p className="mb-0">
                                      <b>Specialty : </b>{" "}
                                      {/* {value.specialty !== null ? value.specialty : "-"} */}
                                    </p>
                                  </div>
                                </div>
                              </div>
                              <div className="col-sm-4">
                                <p className="mb-1 mt-0 mt-sm-0">
                                  <b>Shift : </b>
                                  {/* {value.shift_title || "-"} */}
                                </p>
                                <p className="mb-0">
                                  <b>Locations : </b>
                                  {/* {value.show_pay_rate == 0 ? (
                                        <>
                                          <span
                                            onClick={() => toggleModal(value.id)}
                                            className="cursor-pointer"
                                            style={{ cursor: "pointer" }}
                                          >
                                            Contact Us
                                          </span>
                                        </>
                                      ) : value.salary_start_range ? (
                                        <>
                                          ${formatAmount(value.salary_start_range)}{" "}
                                          {value.salary_type}
                                        </>
                                      ) : (
                                        "N/A"
                                      )} */}
                                </p>
                              </div>
                              <div className="col-sm-3">
                                <p className="mb-1 mt-0 mt-sm-0">
                                  <b>Salary : </b>
                                  {/* {value.total_opening || "N/A"} */}
                                </p>
                                <p className="mb-0">
                                  <b>Start Date : </b>
                                  {/* {formatDate(value.created_at)} */}
                                </p>
                              </div>
                              <div className="col-md-1">
                                <Dropdown className="">
                                  <Dropdown.Toggle
                                    variant="default"
                                    // id={`dropdown-basic-` + value.job_id}
                                    // key={`dropdown-basic-` + value.job_id}
                                    className="p-0"
                                  >
                                    <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    <Dropdown.Item>Update</Dropdown.Item>

                                    <Dropdown.Item>
                                      Mark as In-Active
                                    </Dropdown.Item>

                                    <Dropdown.Item>Edit</Dropdown.Item>
                                    <Dropdown.Item>Delete</Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            </div>
                          </div>
                          {/* {value.is_boosted == 1 && (
                                <div className="ribbon ribbon-triangle ribbon-top-end border-primary">
                                  <div class="ribbon-icon mt-n3 me-0">
                                    <i class="fas fa-star fs-2 text-white font-18"></i>
                                  </div>
                                </div>
                              )} */}
                        </div>

                        {/* ))} */}
                      </>
                    )}
                  </>
                )}

                {/* end card*/}
                {/* end card*/}
              </div>
              {/* end col */}
            </div>
          </div>

          <Footer />
        </div>
      </div>
      <Modal
        show={openStateModal}
        onHide={toggleStateModal}
        centered
        backdrop="static"
      >
        <Modal.Header closeButton>
          <Modal.Title className="my-0">State / Cities</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12 ">
              <p>
                <strong style={{ marginBottom: "2vw" }}>Locations</strong>
              </p>
              {cityState &&
                cityState?.state_city_data.map((data) => (
                  <>
                    <p>
                      <strong>{data.state}</strong> |{" "}
                      {data && data.cities.map((city) => <span> {city},</span>)}
                    </p>
                  </>
                ))}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn btn-secondary" onClick={toggleStateModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default JobRequset;
