import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { getTokenData } from "../../../../utils/tokenUtils";
import * as api from "../../../../services/ApiService";
import { toast } from "react-toastify";

const EmailAndSmsModal = ({ showModal, setShowModal, type }) => {
  const user = getTokenData();
  const [users, setUsers] = useState([]);
  const [roles, setRoles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState(null);
  const [show, setShow] = useState(false);

  const formik = useFormik({
    initialValues: {
      user: "",
      role: "",
      message: "",
    },
    validationSchema: Yup.object({
      user: Yup.string().required("User is required"),
      role: Yup.string().required("Role is required"),
      message: Yup.string().required(`${type === "email" ? "Email" : "SMS"} text is required`),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        const params = {
          from_id: user.id,
          to_id: values.user,
          type,
          message: values.message,
          role_id: values.role,
        };

        const result = await api.postMethod(params, "admin/send-message");
        if (result.status) {
          toast.success(result.message);
          resetForm();
          setShowModal(false);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const fetchUserRoles = async () => {
    try {
      const param = { user_id: user.id };
      const apiData = await api.postMethod(param, "admin/get-user-roles");
      setRoles(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchUsers = async (role_id) => {
    try {
      const param = { user_id: user.id, role_id: role_id };
      const apiData = await api.postMethod(param, "admin/get-users");

      if (apiData.status) {
        let usersList = apiData.data;
        if (usersList.length) {
          usersList.unshift({
            id: "all",
            name: `All Users`,
          });
        }
        setUsers(usersList);
      } else {
        let usersList = [];
        setUsers(usersList);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserRoles();
  }, []);

  return (
    <Modal show={showModal} size="xl" onHide={() => setShowModal(false)} centered>
      <Modal.Header closeButton>
        <Modal.Title className="my-0">{type === "email" ? "Email Text" : "SMS Text"}</Modal.Title>
      </Modal.Header>

      <form onSubmit={formik.handleSubmit}>
        <Modal.Body>
          <div className="row">
            <div className="col-md-12">
              <div className="form-group mb-3">
                <label htmlFor="role">Select Role</label>
                <select
                  id="role"
                  className="form-control"
                  value={formik.values.role}
                  onChange={(e) => {
                    formik.handleChange(e);
                    fetchUsers(e.target.value);
                  }}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Select a Role</option>
                  {roles.map(
                    (role) =>
                      role.id != 0 &&
                      role.id != 1 &&
                      role.id != 8 && (
                        <option key={role.id} value={role.id}>
                          {role.role}
                        </option>
                      )
                  )}
                </select>
                {formik.touched.role && formik.errors.role ? (
                  <div className="error text-danger">{formik.errors.role}</div>
                ) : null}
              </div>
              <div className="form-group mb-3">
                <label htmlFor="user">Select User</label>
                <select
                  id="user"
                  className="form-control"
                  value={formik.values.user}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                >
                  <option value="">Select a User</option>
                  {users.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </select>
                {formik.touched.user && formik.errors.user ? (
                  <div className="error text-danger">{formik.errors.user}</div>
                ) : null}
              </div>
              {type === "email" ? (
                <div className="form-group mb-0">
                  <label>Email Text</label>
                  <CKEditor
                    editor={ClassicEditor}
                    onChange={(event, editor) => {
                      const data = editor.getData();
                      formik.setFieldValue("message", data);
                    }}
                  />
                  {formik.touched.message && formik.errors.message ? (
                    <div className="error text-danger">{formik.errors.message}</div>
                  ) : null}
                </div>
              ) : (
                <div className="form-group mb-0">
                  <label htmlFor="message">SMS Text</label>
                  <textarea
                    id="message"
                    className="form-control"
                    rows="5"
                    placeholder="Enter SMS text"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.message && formik.errors.message ? (
                    <div className="error text-danger">{formik.errors.message}</div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button
            type="button"
            className="btn btn-secondary waves-effect"
            onClick={() => setShowModal(false)}
          >
            Cancel
          </button>
          <button
            type="submit"
            className="btn btn-success waves-effect waves-light"
            disabled={formik.isSubmitting}
          >
            Submit <i className="fa fa-check" />
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default EmailAndSmsModal;
