import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Index";
import { Link } from "react-router-dom";
import * as api from "../../../services/ApiService";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as Yup from "yup";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { formatDate } from "../../../utils/dateFormat";
import PageTitle from "../../Layout/PageTitle";
import useConfirm from "../../../hooks/useConfirm";

const Redirects = () => {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    speciality_id: "",
    state_id: "",
    status: "all",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      speciality_id: "",
      state_id: "",
      status: "all",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchComplianceFiles();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [states, setStates] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchStates = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchStates();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [UserLists, setUserLists] = useState([]);
  const fetchUserLists = async () => {
    try {
      const apiData = await api.getMethod("get-users/4");

      setUserLists(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUserLists();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [facilityLists, setFacilityLists] = useState([]);
  const fetchFacilities = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "admin/get-clients");

      setFacilityLists(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchFacilities();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [specilityLists, setspecilityLists] = useState([]);
  const fetchSpecilities = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "admin/get-specialities");

      setspecilityLists(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchSpecilities();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [isLoading, setIsLoading] = useState(false);
  const [complianceFiles, setComplianceFiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchComplianceFiles = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "admin/get-redirects");

      setComplianceFiles(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      } else setNoRecordsFound(false);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchComplianceFiles();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    candidate_id: null,
  });

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const toggleUploadModal = () => {
    setUploadModalOpen(!uploadModalOpen);

    if (!uploadModalOpen) {
      setformData({
        id: null,
        user_id: user.id,
        candidate_id: null,
      });

      // Reset validation errors
      setErrors({});
    }
  };

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setformData({
      user_id: user.id,
      id: record.id,
      entry_date: record.entry_date,
      candidate_id: record.candidate_id,
      client_id: record.client_id,
      specialty_id: record.specialty_id,
      agency_id: record.agency_id,
    });

    setUploadModalOpen(true);
  };
  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      record.user_id = user.id;
      setIsLoading(true);
      const result = await api.postMethod(record, "admin/delete-redirect");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      }
    }
  };

  //Define the validation schema
  const validationSchema = Yup.object({
    candidate_id: Yup.string().required("Candidate is Required"),
  });

  const [errors, setErrors] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      setIsLoading(true);
      const result = await api.postFileMethod(formData, "admin/update-redirect");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);

        //document.getElementById("file_name").value = "";
        setUploadModalOpen(false);
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const handleSelectChange = (id, field, value) => {
    // Call the function to update the particular field value
    updateFieldValue(id, field, value);
  };

  const updateFieldValue = async (id, field, value) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        field: field,
        value: value,
      };
      const result = await api.postMethod(param, "admin/update-redirect-field");
      // Update the state with the new data
      setComplianceFiles(
        complianceFiles.map((record) => (record.id === id ? { ...record, [field]: value } : record))
      );

      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  const [notesFormData, setNotesFormData] = useState({
    id: null,
    user_id: user.id,
    notes: null,
  });
  const [notesModalOpen, setNotesModalOpen] = useState(false);

  const toggleNotesModal = (redValue) => {
    setNotesModalOpen(!notesModalOpen);
    if (!notesModalOpen) {
      setNotesFormData({
        id: redValue ? redValue.id : null,
        user_id: user.id,
        notes: redValue ? redValue.notes : null,
      });

      /*
      setNotesFormData((prevState) => ({
        ...prevState,
        ["id"]: redValue.id,
      }));
      */
    } else {
      setNotesFormData({
        id: null,
        user_id: user.id,
        notes: null,
      });
    }
  };

  const handleNoteSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const param = {
        id: notesFormData.id,
        user_id: user.id,
        field: "notes",
        value: notesFormData.notes,
      };
      const result = await api.postFileMethod(param, "admin/update-redirect-field");
      setIsLoading(false);
      if (result.status) {
        setNotesModalOpen(false);
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const handleNotesChange = (field, value) => {
    // Update the state with the new data
    setComplianceFiles(
      complianceFiles.map((record) =>
        record.id === notesFormData.id ? { ...record, [field]: value } : record
      )
    );

    setNotesFormData((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: "Redirects", link: null },
  ];

  return (
    <Layout>
      <PageTitle title="Redirects" breadcrumbItems={breadcrumbItems} />

      {/* end page title */}
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <div className="card-body">
              <div className="row justify-content-between mb-2">
                <div className="col-auto">
                  <div className="dropdown" style={{ width: 300 }}>
                    <button
                      type="button"
                      className="btn btn-outline-light dropdown-toggle w-100 text-start"
                      onClick={handleToggleFilterDropDown}
                    >
                      Filters
                      <i className="mdi mdi-chevron-down float-end" />
                    </button>
                    <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                      <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                        <div className="mb-2">
                          <label htmlFor="keyword" className="form-label">
                            Keyword
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="keyword"
                            name="keyword"
                            value={filters.keyword}
                            onChange={handleFilterInputChange}
                          />
                        </div>

                        <div className="mb-2">
                          <label htmlFor="speciality_id" className="form-label">
                            Specialities
                          </label>
                          <select
                            className="form-select"
                            id="speciality_id"
                            name="speciality_id"
                            value={filters.speciality_id}
                            onChange={handleFilterInputChange}
                          >
                            <option value="">Please Select</option>
                            {specilityLists.map((facility) => (
                              <option key={facility.id} value={facility.id}>
                                {facility.specialty}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="mb-2">
                          <label htmlFor="state_id" className="form-label">
                            Desired State
                          </label>
                          <select
                            className="form-select"
                            id="state_id"
                            name="state_id"
                            value={filters.state_id}
                            onChange={handleFilterInputChange}
                          >
                            <option value="">Please Select</option>
                            {states.map((state) => (
                              <option key={state.id} value={state.id}>
                                {state.name}
                              </option>
                            ))}
                          </select>
                        </div>

                        <div className="mb-2">
                          <label htmlFor="status" className="form-label">
                            Status
                          </label>
                          <select
                            className="form-select"
                            id="cstatus"
                            name="status"
                            value={filters.status}
                            onChange={handleFilterInputChange}
                          >
                            <option value={"all"}>Please Select</option>
                            <option value="active">Active</option>
                            <option value="orangezone">Orange Zone</option>
                          </select>
                        </div>

                        <div className="text-end mt-1">
                          <button
                            type="button"
                            className="btn btn-light float-start"
                            onClick={handleReset}
                          >
                            Reset Filter
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Apply Filter
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="text-lg-end my-1 my-lg-0">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light mb-2"
                      onClick={toggleUploadModal}
                    >
                      <i className="fa fa-plus mr-1" /> Add New
                    </button>
                  </div>
                </div>
              </div>

              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Candidate</th>
                      <th>Specialty</th>
                      <th>Desired State</th>
                      <th>Shift</th>
                      <th>Notes</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="6">Loading...</td>
                      </tr>
                    ) : (
                      <>
                        {noRecordsFound ? (
                          <tr>
                            <td colSpan="6">No records found</td>
                          </tr>
                        ) : (
                          <>
                            {Object.entries(complianceFiles).map(([key, value]) => (
                              <tr key={value.id}>
                                <td>
                                  <Link
                                    to={`/admin/user-profile/` + value.user_unique_id}
                                    className=""
                                  >
                                    {value.name}
                                  </Link>
                                </td>
                                <td>{value.specialty || "-"}</td>
                                <td>{value.state_names || "-"}</td>
                                <td>{value.desired_shifts || "-"}</td>
                                <td>
                                  <button
                                    type="button"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-1"
                                    data-tooltip-content="Update Note"
                                    onClick={() => toggleNotesModal(value)}
                                  >
                                    <i className="mdi mdi-pencil" />
                                  </button>
                                  <Tooltip id="mt-1"></Tooltip>
                                </td>
                                <td>
                                  <select
                                    className="form-select my-1 my-lg-0"
                                    onChange={(e) =>
                                      handleSelectChange(value.id, "status", e.target.value)
                                    }
                                    value={value.red_status}
                                  >
                                    <option value="">-</option>
                                    <option value="active">Active</option>
                                    <option value="orangezone">Orange Zone</option>
                                  </select>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* end card-body*/}
          </div>
          {/* end card*/}
        </div>
        {/* end col */}

        <Modal
          show={uploadModalOpen}
          onHide={toggleUploadModal}
          centered
          backdrop="static"
          size="md"
        >
          <form onSubmit={handleSubmit}>
            <Modal.Header closeButton>
              <Modal.Title className="my-0">Add Redirect</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="mb-2 col-md-12">
                  <label className="form-label" htmlFor="candidate_id">
                    Candidate *
                  </label>
                  <select
                    className="form-control"
                    id="candidate_id"
                    name="candidate_id"
                    onChange={handleChange}
                    value={formData.candidate_id !== null ? formData.candidate_id : ""}
                  >
                    <option value="" key="">
                      Please Select
                    </option>
                    {/* Render the states */}
                    {UserLists.map((user) => (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    ))}
                  </select>
                  {errors.candidate_id && <span className="error">{errors.candidate_id}</span>}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-secondary" onClick={toggleUploadModal}>
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </Modal.Footer>
          </form>
        </Modal>

        <Modal show={notesModalOpen} onHide={toggleNotesModal} centered backdrop="static" size="md">
          <form onSubmit={handleNoteSubmit}>
            <Modal.Header closeButton>
              <Modal.Title className="my-0">Add Notes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="row">
                <div className="mb-0 col-md-12">
                  <textarea
                    className="form-control"
                    id="notes"
                    name="notes"
                    onChange={(e) => handleNotesChange("notes", e.target.value)}
                    value={notesFormData.notes !== null ? notesFormData.notes : ""}
                    rows={5}
                  ></textarea>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button type="button" className="btn btn-secondary" onClick={toggleNotesModal}>
                Close
              </button>
              <button type="submit" className="btn btn-primary">
                Submit
              </button>
            </Modal.Footer>
          </form>
        </Modal>
      </div>

      {isLoading === true && <Spinner />}
    </Layout>
  );
};

export default Redirects;
