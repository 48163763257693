import React from "react";
import Sidebar from "./Sidebar";
import Header from "./Header";
import Footer from "./Footer";
import ThemeSettings from "./ThemeSettings";

const Layout = ({ children, refreshPage }) => {
  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">{children}</div>
          </div>

          <Footer />
        </div>
      </div>
      <ThemeSettings />
    </>
  );
};

export default Layout;
